import React, { useCallback } from 'react'
import Dropzone, { DropzoneState } from 'react-dropzone'
import styled from 'styled-components'
import { useTranslation } from 'i18n'
import convertUnit from 'lib/unit'
import { Button } from 'common/components'
import { Icon } from '../Icon'
import { DragDropProps } from './DragDropProps'
import { Paragraph } from '../Paragraph'

interface StyledContainerProps {
  isDragActive: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({ theme, isDragActive }) => ({
    color: isDragActive ? theme.gray_5 : theme.gray_3,
  })}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: ${convertUnit('200px')};
  cursor: pointer;
  border-radius: 8px;
  transition: border 0.1s ease-out;
  line-height: 1.5;
  outline: none;
`

const StyledLabel = styled(Paragraph)`
  transition: all 0.1s ease-out;
`

const StyledDesc = styled(Paragraph)`
  margin-bottom: 8px;
  transition: all 0.1s ease-out;
`

const StyledButton = styled(Button)`
  border-radius: ${convertUnit('30px')};
`

export default function DragDrop({
  accept = ['image/jpeg', 'image/png'],
  dragActiveText,
  dragNotActiveText,
  ...props
}: DragDropProps) {
  const { translate } = useTranslation()

  const handleRenderChildren = useCallback(
    ({
      isDragActive,
      getRootProps,
      getInputProps,
    }: DropzoneState) => (
      <StyledContainer
        {...getRootProps()}
        isDragActive={isDragActive}
      >
        <input {...getInputProps()} />
        <Icon
          type="image"
          color={isDragActive ? 'gray_5' : 'gray_3'}
          size={convertUnit(isDragActive ? 50 : 40)}
        />

        <StyledLabel
          fontSize="l"
          fontWeight="medium"
          color={isDragActive ? 'gray_5' : 'gray_3'}
        >
          {isDragActive ? dragActiveText : dragNotActiveText}
        </StyledLabel>

        {!isDragActive && (
          <>
            <StyledDesc fontSize="s" color="gray_3">
              {translate('global:uploadCriteria')}
            </StyledDesc>
            <StyledButton
              label={translate('global:uploadAnImage')}
              fontSize="s"
              backgroundColor="primary_5"
              backgroundHoverColor="info_4"
            />
          </>
        )}
      </StyledContainer>
    ),
    [dragActiveText, dragNotActiveText, translate],
  )

  return (
    <Dropzone {...props} accept={accept}>
      {handleRenderChildren}
    </Dropzone>
  )
}
