import React, { ReactNode, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { ThemeType } from './Theme'
import { ThemeContextState } from './ThemeContext'
import { THEME_MAP } from './ThemeMap'

const StyledContainer = styled.div`
  ${({ theme }) => ({
    backgroundColor: theme.white_1,
    color: theme.black,
  })}
`

export interface ThemeProviderProps {
  children: ReactNode
}

export default function ThemeProvider({
  children,
}: ThemeProviderProps) {
  const stateTheme = useState<ThemeType>('light')
  const theme = stateTheme[0]

  return (
    <ThemeContextState.Provider value={stateTheme}>
      <ThemeContext.Provider value={THEME_MAP[theme]}>
        <StyledContainer>{children}</StyledContainer>
      </ThemeContext.Provider>
    </ThemeContextState.Provider>
  )
}
