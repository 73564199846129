import React from 'react'
import styled from 'styled-components'
import { Button, Paragraph } from 'common/components'
import { useTranslation } from 'i18n'
import convertUnit from 'lib/unit'
import { ConfirmationModalProps } from './ConfirmationModalProps'
import { Modal } from '../Modal'

const StyledConfirmationModal = styled.div<React.CSSProperties>`
  width: ${({ width = '275px' }) => convertUnit(width)};
  padding: ${convertUnit('25px')};
  background-color: ${({ theme }) => theme.white_1};
  border-radius: ${convertUnit('8px')};
  position: relative;
  transition: all 5s ease-in-out;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
`

const StyledModalText = styled.div`
  text-align: left;
  margin: ${convertUnit('25px')} 0;
`

const StyledModalButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export default function ConfirmationModal({
  width,
  text,
  confirmText,
  cancelText,
  onConfirm,
  toggleModal,
  title,
  ...props
}: ConfirmationModalProps) {
  const { translate } = useTranslation()

  return (
    <Modal {...props}>
      <StyledConfirmationModal width={width}>
        <Paragraph fontSize="xl" fontWeight="bold">
          {title}
        </Paragraph>
        <StyledModalText>
          <Paragraph fontWeight="regular" fontSize="l">
            {text}
          </Paragraph>
        </StyledModalText>
        <StyledModalButtonContainer>
          <Paragraph
            color="primary_5"
            fontSize="m"
            fontWeight="medium"
            onClick={toggleModal}
            style={{ marginRight: convertUnit('25px') }}
          >
            {cancelText || translate('global:no')}{' '}
          </Paragraph>
          <Button
            backgroundColor="primary_5"
            color="white_1"
            label={confirmText || translate('global:yes')}
            onClick={() => {
              toggleModal()
              onConfirm()
            }}
            fontWeight="medium"
            fontSize="m"
          />
        </StyledModalButtonContainer>
      </StyledConfirmationModal>
    </Modal>
  )
}
