import React, { useMemo } from 'react'
import styled from 'styled-components'
import { FontColor } from 'types'
import convertUnit from 'lib/unit'
import { Icon } from '../Icon'
import { Paragraph } from '../Paragraph'
import { TextValidationProps } from './TextValidationProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledParagraph = styled(Paragraph)`
  margin-left: ${convertUnit('5px')};
`

export default function TextValidation({
  text,
  validation,
  color: colorBase = 'gray_3',
  ...props
}: TextValidationProps) {
  const isValid = useMemo(
    () =>
      text
        ? typeof validation === 'function'
          ? validation(text)
          : validation.test(text)
        : undefined,
    [text, validation],
  )
  const color = useMemo<FontColor>(
    () =>
      isValid !== undefined
        ? isValid
          ? 'success_5'
          : 'danger_5'
        : colorBase,
    [colorBase, isValid],
  )

  return (
    <StyledContainer>
      <Icon
        type={isValid === false ? 'close' : 'check'}
        color={color}
        size={16}
      />
      <StyledParagraph {...props} color={color} fontSize="m" />
    </StyledContainer>
  )
}
