import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {getBorder} from 'utils'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(4)};
  text-align: left;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  border-radius: ${convertUnit(8)};
  border: ${({theme}) => getBorder(convertUnit(1), 'solid', theme.gray_1)};
  background-color: ${({theme}) => theme.white_3};
  box-sizing: border-box;
`

const StyledListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: ${convertUnit(4)};
`

const StyledListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(5)};
  justify-content: flex-start;
  align-items: flex-start;
  line-height: ${convertUnit(21)};
`

const StyledSupportContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const StyledIconContainer = styled.div`
  aspect-ratio: 1;
  padding: ${convertUnit(5)};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLink = styled.a`
  text-decoration: none;
`

export default function PaymentCheckoutStatusSuccessWaitingHiresInfo() {
  const {translate} = useTranslation()

  const handleRenderTitle = useMemo(
    () => (
      <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
        {translate('payment:checkoutWaitForHiresInfoTitle')}
      </Paragraph>
    ),
    [translate],
  )

  const handleRenderItems = useMemo(
    () => (
      <StyledListContainer>
        <StyledListItem>
          <Paragraph fontSize="s" color="gray_6" fontWeight="bold">
            •
          </Paragraph>
          <Paragraph fontSize="s" color="gray_6">
            {translate('payment:checkoutWaitForHiresInfoDesc1')}
          </Paragraph>
        </StyledListItem>
        <StyledListItem>
          <Paragraph fontSize="s" color="gray_6" fontWeight="bold">
            •
          </Paragraph>
          <Paragraph fontSize="s" color="gray_6">
            {translate('payment:checkoutWaitForHiresInfoDesc2')}
          </Paragraph>
        </StyledListItem>
        <StyledListItem>
          <Paragraph fontSize="s" color="gray_6" fontWeight="bold">
            •
          </Paragraph>
          <Paragraph fontSize="s" color="gray_6">
            {translate('payment:checkoutWaitForHiresInfoDesc3')}
          </Paragraph>
        </StyledListItem>
      </StyledListContainer>
    ),
    [translate],
  )

  const handleRenderSupport = useMemo(
    () => (
      <StyledSupportContainer>
        <Paragraph fontSize="xs" color="gray_5">
          {translate('global:fotoyuSupportTeam')}
        </Paragraph>
        <StyledIconContainer>
          <StyledLink href="https://wa.me/6281219000813" target="_blank">
            <Icon type="whatsapp-ol" color="gray_5" size={convertUnit(18)} />
          </StyledLink>
        </StyledIconContainer>
        <StyledIconContainer>
          <StyledLink
            href="https://www.instagram.com/fotoyu_official/"
            target="_blank">
            <Icon type="instagram-ol" color="gray_5" size={convertUnit(18)} />
          </StyledLink>
        </StyledIconContainer>
      </StyledSupportContainer>
    ),
    [translate],
  )

  return (
    <StyledContainer>
      {handleRenderTitle}
      {handleRenderItems}
      {handleRenderSupport}
    </StyledContainer>
  )
}
