import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  INITIALIZE_DANA_DATA,
  PAYMENT_DURATION,
  PAYMENT_METHOD_ID_GOPAY,
  PAYMENT_METHOD_ID_OVO,
  PAYMENT_METHOD_ID_SHOPEE,
  WINDOW_MODE_MOBILE_WIDTH,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {DanaOutletData, PaymentStatus} from 'types'
import {
  calcTimeDiff,
  formatHours,
  getCurrencyValue,
  parseDate,
  postWindowParentMessage,
  showSnackbar,
  useLocation,
  useTimer,
} from 'utils'
import {Button, ModalLoading} from 'common/components'
import firebase from 'lib/firebase'
import convertUnit from 'lib/unit'
import {useDispatch} from 'lib/redux'
import {PaymentCheckoutStatusBase} from '../StatusBase'
import PaymentCheckoutStatusSuccessWaitingHiresInfo from './PaymentCheckoutStatusSuccessWaitingHiresInfo'
import PaymentCheckoutStatusWaitingForPaymentInfo from './PaymentCheckoutStatusWaitingForPaymentInfo'
import {PaymentCheckoutStatusProps} from './PaymentCheckoutStatusProps'

const StyledContainer = styled.div`
  width: 100%;
  max-width: ${convertUnit(480)};
  padding: ${convertUnit(40)};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  background-color: ${({theme}) => theme.white_1};
  border-radius: ${convertUnit(16)};

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    border-radius: 0;
    padding: ${convertUnit(20)};
    width: 100%;
    height: 100%;
  }
`

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
`
const StyledParagraph = styled.p`
  white-space: pre-wrap;
  font-size: ${convertUnit(18)};
  margin-top: ${convertUnit(10)};
  line-height: ${convertUnit(31)};
  text-align: center;
  font-family: 'Roboto-Regular';
`

const StyledHighlightParagraph = styled.span`
  ${({theme}) => ({color: theme.primary_5})}
  font-family: 'Roboto-Bold';
`

const StyledButtonContainer = styled.div`
  display: flex;
  box-sizing: border-box;
`

const StyledButton = styled(Button)`
  display: flex;
  margin: ${convertUnit(5)};
  flex: 1;
`

export default function PaymentCheckoutStatus({
  isCompatibleVersion,
}: PaymentCheckoutStatusProps) {
  const {translate} = useTranslation()
  const {update} = useDispatch()
  const {
    invoiceId,
    checkoutToken,
    contentData: {total_price},
    paymentMethodId,
    paymentType,
    phone,
    expiredDate,
    voucher,
  } = useLocation('checkout_status').state
  const isFree = useMemo(() => total_price === 0, [total_price])

  const [status, setStatus] = useState<PaymentStatus>(
    isFree ? 'success' : 'waiting',
  )
  const {countdown, reset} = useTimer({
    duration: PAYMENT_DURATION,
    countOnStart: true,
  })
  const [invoice, setInvoice] = useState(invoiceId)

  const isLinkPayment = useMemo(
    () =>
      paymentType === 'Ewallet' &&
      paymentMethodId !== PAYMENT_METHOD_ID_GOPAY &&
      paymentMethodId !== PAYMENT_METHOD_ID_OVO &&
      paymentMethodId !== PAYMENT_METHOD_ID_SHOPEE,
    [paymentMethodId, paymentType],
  )

  const [eWalletData, setEWalletData] = useState<DanaOutletData>(
    INITIALIZE_DANA_DATA,
  )
  const {actions} = eWalletData

  const handleFirestore = useCallback(
    () =>
      firebase
        .firestore()
        .collection(process.env.FIRESTORE_COLLECTION_PAYMENT!)
        .doc(invoiceId)
        .onSnapshot((snapshot) => {
          const data = snapshot.data()!
          if (data !== undefined) {
            setEWalletData(data.ewallet)
          }
        }),
    [invoiceId],
  )

  useLayoutEffect(() => {
    if (isLinkPayment && actions.desktop_web_checkout_url)
      window.location.replace(actions.desktop_web_checkout_url)
  }, [actions.desktop_web_checkout_url, isLinkPayment])

  const handleModalLoading = useMemo(
    () => <ModalLoading visible={isLinkPayment} />,
    [isLinkPayment],
  )

  const handleReset = useCallback(() => {
    reset()
    setStatus('waiting')
  }, [reset])

  const handlePay = useCallback(() => {
    requestData('payment_checkout_update', {
      data: {
        checkout_token: checkoutToken,
        payment_method_id: PAYMENT_METHOD_ID_OVO,
        type: 'purchase',
        phone,
        voucher_id: voucher && voucher.id,
      },
      onRequestSuccess: ({status: statusCode, data}) => {
        if (statusCode === 200) {
          const invoice_id =
            typeof data.result === 'object'
              ? data.result.invoice_id
              : data.result
          handleReset()
          setInvoice(invoice_id)
        } else if (statusCode === 422 && data.message.includes('voucher')) {
          showSnackbar(translate('payment:paymentVoucherError'))
          update('paymentErrorState', {voucherError: true})
        } else {
          showSnackbar(
            translate('global:messageError', {context: `code-${statusCode}`}),
          )
        }
      },
    })
  }, [voucher, checkoutToken, handleReset, phone, translate, update])

  const [currentDate, setCurrentDate] = useState(new Date())
  const timeDiff = useMemo(() => calcTimeDiff(currentDate, expiredDate!), [
    currentDate,
    expiredDate,
  ])

  const handleExploreButton = useCallback(() => {
    postWindowParentMessage({type: 'explore'})
  }, [])

  const handleSuccessBackToCollection = useCallback(() => {
    postWindowParentMessage({type: 'collection'})
  }, [])

  const description = useMemo(() => {
    switch (paymentType) {
      case 'Virtual Account': {
        return translate('payment:statusWaitingDescriptionDate', {
          Date: parseDate(expiredDate!, 'MMMM D, YYYY, HH:mm'),
        })
      }
      case 'Retail': {
        return translate('payment:statusWaitingDescriptionDate', {
          Date: parseDate(expiredDate!, 'MMMM D, YYYY, HH:mm'),
        })
      }
      case 'Ewallet': {
        if (paymentMethodId === PAYMENT_METHOD_ID_OVO)
          return translate('payment:statusWaitingDescriptionCountdown', {
            Countdown: countdown,
          })
        return translate('payment:statusWaitingDescription')
      }
      default: {
        return translate('payment:statusWaitingDescription')
      }
    }
  }, [countdown, expiredDate, paymentMethodId, paymentType, translate])

  const handleFreeDescription = useMemo(() => {
    const str = translate('payment:statusSuccessFreeDescription', {
      amount: getCurrencyValue(0),
    })
    const highlight = getCurrencyValue(0)

    const startIndex = str.indexOf(highlight)
    const endIndex = str.indexOf(highlight) + highlight.length

    return (
      <StyledDescriptionContainer>
        <StyledParagraph>
          {str.substring(0, startIndex)}
          <StyledHighlightParagraph>
            {str.substring(startIndex, endIndex)}
          </StyledHighlightParagraph>
          {str.substring(endIndex, str.length)}
        </StyledParagraph>
      </StyledDescriptionContainer>
    )
  }, [translate])

  const handleRenderButton = useMemo(
    () => (
      <StyledButtonContainer>
        {paymentMethodId === PAYMENT_METHOD_ID_OVO && (
          <StyledButton
            label={translate('global:cancel')}
            onClick={() => postWindowParentMessage({type: 'close'})}
            backgroundColor="white_3"
            color="primary_5"
          />
        )}

        <StyledButton
          label={
            paymentMethodId === PAYMENT_METHOD_ID_OVO
              ? translate('payment:statusFailedButtonLabel')
              : translate('global:backToFotoyu')
          }
          onClick={
            paymentMethodId === PAYMENT_METHOD_ID_OVO
              ? handlePay
              : handleExploreButton
          }
        />
      </StyledButtonContainer>
    ),
    [handleExploreButton, handlePay, paymentMethodId, translate],
  )

  useEffect(() => {
    if (
      status === 'waiting' &&
      paymentMethodId === PAYMENT_METHOD_ID_OVO &&
      !countdown
    ) {
      setStatus('failed')
    }
  }, [countdown, paymentMethodId, status])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentDate(new Date())
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [currentDate])

  useEffect(() => {
    if (!isFree) {
      firebase
        .firestore()
        .collection(process.env.FIRESTORE_COLLECTION_PAYMENT!)
        .doc(invoice)
        .onSnapshot((snapshot) => {
          const data = snapshot.data()!
          if (data === undefined) return

          switch (data.status) {
            case 'PROCESSED':
              if (
                timeDiff.hours < 0 &&
                timeDiff.minutes < 0 &&
                timeDiff.seconds < 0
              ) {
                setStatus('failed')
              } else {
                reset()
                setStatus('waiting')
              }
              break
            case 'COMPLETED':
              setStatus('success')
              break
            case 'FAILED':
              setStatus('failed')
              break
            default:
              setStatus('waiting')
          }
        })
    }
  }, [
    invoice,
    invoiceId,
    isFree,
    reset,
    timeDiff.hours,
    timeDiff.minutes,
    timeDiff.seconds,
  ])

  useEffect(() => {
    if (isLinkPayment) {
      handleFirestore()
    }
  }, [handleFirestore, isLinkPayment])

  switch (status) {
    case 'success':
      return (
        <StyledContainer>
          {isFree ? (
            <PaymentCheckoutStatusBase
              icon="check"
              iconColor="success_5"
              title={translate('payment:statusSuccessTitle')}
              description={handleFreeDescription}
              buttonLabel={translate('global:backToFotoyu')}
              onClickButton={() =>
                isCompatibleVersion
                  ? handleSuccessBackToCollection()
                  : handleExploreButton()
              }
            />
          ) : (
            <PaymentCheckoutStatusBase
              icon="check"
              iconColor="success_5"
              title={translate('payment:statusSuccessTitle')}
              description={translate('payment:statusSuccessDescription')}
              buttonLabel={translate('global:backToFotoyu')}
              bottomElement={<PaymentCheckoutStatusSuccessWaitingHiresInfo />}
              onClickButton={() =>
                isCompatibleVersion
                  ? handleSuccessBackToCollection()
                  : handleExploreButton()
              }
            />
          )}
        </StyledContainer>
      )
    case 'no-connection':
      return (
        <StyledContainer>
          <PaymentCheckoutStatusBase
            icon="no-internet-ol"
            iconColor="gray_5"
            title={translate('payment:statusOfflineTitle')}
            hideButton={paymentMethodId !== PAYMENT_METHOD_ID_OVO}
            description={translate('payment:statusOfflineDescription')}
            buttonLabel={translate('payment:statusOfflineButtonLabel')}
            onClickButton={handlePay}
          />
        </StyledContainer>
      )
    case 'failed':
      return (
        <StyledContainer>
          <PaymentCheckoutStatusBase
            icon="close"
            iconColor="gray_3"
            title={translate('payment:statusFailedTitle')}
            description={translate('payment:statusFailedDescription')}
            hideButton
          />
          {handleRenderButton}
        </StyledContainer>
      )
    default:
      return (
        <>
          <StyledContainer>
            {paymentType === 'Ewallet' ? (
              <PaymentCheckoutStatusBase
                hideButton={isLinkPayment}
                icon="clock"
                iconColor="warning_5"
                title={translate('payment:statusWaitingTitle')}
                description={description}
                buttonLabel={translate('global:backToFotoyu')}
                bottomElement={<PaymentCheckoutStatusWaitingForPaymentInfo />}
                onClickButton={handleExploreButton}
              />
            ) : (
              <PaymentCheckoutStatusBase
                icon="clock"
                iconColor="warning_5"
                title={translate('payment:statusWaitingTitle')}
                description={description}
                buttonLabel={translate('global:backToFotoyu')}
                onClickButton={handleExploreButton}
                countdown={
                  paymentType !== 'Credit Card'
                    ? formatHours(
                        timeDiff.hours,
                        timeDiff.minutes,
                        timeDiff.seconds,
                      )
                    : undefined
                }
                bottomElement={<PaymentCheckoutStatusWaitingForPaymentInfo />}
              />
            )}
          </StyledContainer>
          {handleModalLoading}
        </>
      )
  }
}
