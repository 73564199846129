import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {VoucherResponse, WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import {translate} from 'i18n'
import {requestData} from 'services'
import {calcTimeDiff, parseDate, useDidMount} from 'utils'
import {Button, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {PaymentCheckoutPromoModalProps} from './PaymentCheckoutPromoModalProps'
import PaymentCheckoutPromoItem from './PaymentCheckoutPromoModalItem'

interface StyledModalOverlayProps {
  visible: boolean
}

interface StyledContainerProps extends React.CSSProperties {
  mode: WindowModeType
}

const StyledModalOverlay = styled.div<StyledModalOverlayProps>`
  ${({visible}) => ({visibility: visible ? 'visible' : 'hidden'})};
  position: fixed;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
`

const StyledModal = styled.div<StyledContainerProps>`
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
  background-color: ${({theme}) => theme.white_1};
  transition: all 0.5s ease-in-out;
  z-index: 10;
`

const StyledBackdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.black}B3;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${convertUnit('15px')};
`

const StyledButtonContinue = styled(Button)`
  display: flex;
  margin: ${convertUnit(5)};
  flex: 1;
`

const StyledButtonContainer = styled.div`
  margin-top: ${convertUnit(25)};
  display: flex;
  box-sizing: border-box;
`
const StyledEmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fill-available;
`
const StyledListContainer = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
  }
`

export default function PaymentCheckoutPromoModal({
  visible,
  onClose,
  onUse,
  totalPrice,
  paymentMethod,
  isPaymentMethodInvalidForPromo,
  ...props
}: PaymentCheckoutPromoModalProps) {
  const mode = useWindowMode()
  const [data, setData] = useState<ReadonlyArray<VoucherResponse>>([])
  const [selected, setSelected] = useState<VoucherResponse>()
  const {voucherError} = useSelector('paymentErrorState') || {}

  const handleLoadData = useCallback(() => {
    requestData('payment_get_vouchers', {
      onRequestSuccess: ({status, data: {result}}) => {
        if (status === 200) {
          setData(
            result.filter(
              (x) =>
                calcTimeDiff(
                  new Date(),
                  parseDate(x.end_at, 'YYYY-MM-DD HH:mm:ss'),
                ).hours >= 0 &&
                calcTimeDiff(
                  new Date(),
                  parseDate(x.start_at, 'YYYY-MM-DD HH:mm:ss'),
                ).hours < 24,
            ),
          )
        }
      },
    })
  }, [])

  const onSelect = useCallback(
    (item: VoucherResponse) => {
      setSelected(item)
    },
    [setSelected],
  )

  const handleRenderItem = useCallback(
    (item: VoucherResponse) => (
      <PaymentCheckoutPromoItem
        isSelected={item.id === selected?.id}
        data={item}
        onSelect={() => onSelect(item)}
        totalPrice={totalPrice}
        paymentMethod={paymentMethod}
      />
    ),
    [selected?.id, totalPrice, paymentMethod, onSelect],
  )

  const renderCustomList = useMemo(
    () => (
      <StyledListContainer>
        {data.length > 0 ? (
          data.map((x) => handleRenderItem(x))
        ) : (
          <StyledEmptyListContainer>
            <Icon type="voucher" size={108} color="gray_5" />
            <Paragraph fontSize="l" fontWeight="bold" color="gray_5">
              {translate('payment:checkoutPromoEmpty')}
            </Paragraph>
          </StyledEmptyListContainer>
        )}
      </StyledListContainer>
    ),
    [data, handleRenderItem],
  )

  useDidMount(handleLoadData)

  useEffect(() => {
    isPaymentMethodInvalidForPromo && setSelected(undefined)
  }, [isPaymentMethodInvalidForPromo])

  return (
    <StyledModalOverlay visible={visible}>
      <StyledBackdrop />
      <StyledModal mode={mode} {...props}>
        <StyledContainer>
          {renderCustomList}
          <StyledButtonContainer>
            <StyledButtonContinue
              label={translate('global:cancel')}
              onClick={() => {
                if (isPaymentMethodInvalidForPromo || voucherError) {
                  onUse(undefined)
                }
                onClose()
              }}
              backgroundColor="white_3"
              color="primary_5"
            />
            <StyledButtonContinue
              label={translate('global:use')}
              disabled={!selected}
              onClick={() => {
                selected && onUse(selected)
                onClose()
              }}
            />
          </StyledButtonContainer>
        </StyledContainer>
      </StyledModal>
    </StyledModalOverlay>
  )
}
