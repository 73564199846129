import { CSSProperties } from 'react'
import { FONT_FAMILY_MAP } from 'consts'
import { FontSizeType, FontWeightType } from 'types'
import convertUnit from 'lib/unit'

export function getFontSize(
  size: FontSizeType,
  convertTo?: string | number,
) {
  let unit: CSSProperties['fontSize'] = '12px'

  switch (size) {
    case 'xxl':
      unit = '30px'
      break
    case 'xl':
      unit = '24px'
      break
    case 'l':
      unit = '18px'
      break
    case 'm':
      unit = '15px'
      break
    case 'xs':
      unit = '10px'
      break
    case 'xxs':
      unit = '8px'
      break
  }

  return convertUnit(unit, convertTo)
}

export function getFontFamily(type: FontWeightType) {
  return FONT_FAMILY_MAP[type]
}
