import React, { useMemo } from 'react'
import { FieldError } from 'react-hook-form'
import styled from 'styled-components'
import { CSSHtmlProperties } from 'types'
import { ThemeColor } from 'themes'
import { getPseudoStyle } from 'utils'
import convertUnit from 'lib/unit'
import { Paragraph } from '../Paragraph'
import { InputBaseProps } from './InputBaseProps'
import { Label } from '../Label'

interface StyledInputContainerProps
  extends CSSHtmlProperties<HTMLDivElement> {
  color?: ThemeColor
  focusBorderColor?: ThemeColor
  focusBackgroundColor?: ThemeColor
  hoverBorderColor?: ThemeColor
  backgroundColor?: ThemeColor
  isFocused?: boolean
  showError?: boolean
  error?: FieldError
}

const StyledInputContainer = styled.div<StyledInputContainerProps>`
  ${({
    theme,
    color = 'black',
    focusBorderColor = 'primary_5',
    focusBackgroundColor = 'white_1',
    hoverBorderColor = 'gray_2',
    backgroundColor = 'white_2',
    isFocused,
    showError,
    error,
  }) => ({
    borderColor: theme.gray_1,
    color: theme[color],
    backgroundColor: theme[backgroundColor],
    ...(isFocused && {
      borderColor: theme[focusBorderColor],
      backgroundColor: theme[focusBackgroundColor],
    }),
    ...(!isFocused &&
      !error &&
      getPseudoStyle(':hover', {
        borderColor: theme[hoverBorderColor],
      })),
    ...(showError &&
      error !== undefined && {
        borderColor: theme.danger_5,
      }),
  })}
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  box-sizing: border-box;
  border-style: solid;
  border-radius: ${convertUnit('8px')};
  border-width: ${convertUnit('1px')};
  align-items: center;
  justify-content: center;
  overflow: hidden;
  appearance: none;
  -webkit-appearance: none;
`

const StyledSectionInput = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  box-sizing: border-box;
  align-items: center;
`

const StyledError = styled(Paragraph)`
  ${({ theme }) => ({ color: theme.danger_5 })}
  box-sizing: border-box;
  margin-top: ${convertUnit('7px')};
  margin-bottom: ${convertUnit('2px')};
`

export default function InputBase({
  label,
  formRules,
  refValue = '',
  containerStyle,
  required,
  inputElement,
  leftElement,
  rightElement,
  color,
  backgroundColor,
  inputContainerStyle,
  contentContainerStyle,
  focusBackgroundColor,
  focusBorderColor,
  hoverBorderColor,
  isFocused,
  error,
  errorType,
  showError,
}: InputBaseProps) {
  const handleRenderError = useMemo(() => {
    if (error && errorType && showError) {
      const validationMessage =
        formRules?.messages && formRules.messages[errorType]
      const message =
        error.message ||
        (validationMessage
          ? validationMessage({
              text: refValue,
              length: refValue.length.toString(),
              max: formRules?.max?.toString(),
              maxLength: formRules?.maxLength?.toString(),
              min: formRules?.min?.toString(),
              minLength: formRules?.minLength?.toString(),
              required: formRules?.required?.toString(),
            })
          : undefined)

      if (message) {
        return (
          <StyledError fontWeight="medium">{message}</StyledError>
        )
      }
    }

    return null
  }, [error, errorType, showError, formRules, refValue])

  const handleRenderInput = useMemo(
    () => (
      <StyledInputContainer
        color={color}
        isFocused={isFocused}
        focusBackgroundColor={focusBackgroundColor}
        focusBorderColor={focusBorderColor}
        hoverBorderColor={hoverBorderColor}
        backgroundColor={backgroundColor}
        showError={showError}
        error={error}
        style={inputContainerStyle}
      >
        {inputElement}
      </StyledInputContainer>
    ),
    [
      color,
      isFocused,
      inputContainerStyle,
      focusBackgroundColor,
      focusBorderColor,
      hoverBorderColor,
      backgroundColor,
      showError,
      error,
      inputElement,
    ],
  )

  return (
    <Label
      label={label}
      required={required}
      containerStyle={containerStyle}
    >
      <StyledSectionInput style={contentContainerStyle}>
        {leftElement}
        {handleRenderInput}
        {rightElement}
      </StyledSectionInput>
      {handleRenderError}
    </Label>
  )
}
