import { FC } from 'react'
import { ROUTE_MAP_PAYMENT } from './payment'
import { RouteParam } from './RouteParam'

export interface RouteMapEntry<T extends string> {
  path: string
  component: FC<any>
  exact?: boolean
  nested?: RouteMap<T>
}

export type RouteMap<T> = {
  [K in keyof T]: RouteMapEntry<any>
}

export const ROUTE_MAP: RouteMap<RouteParam> = {
  ...ROUTE_MAP_PAYMENT,
}
