import React, {useCallback, useEffect, useMemo, useState} from 'react'
import QRCode from 'react-qr-code'
import styled from 'styled-components'
import {INITIALIZE_GOPAY_DATA, PAYMENT_EWALLET_TIME_LIMIT} from 'consts'
import {useTranslation} from 'i18n'
import {GopayOutletData, PaymentStatusType, WindowModeType} from 'types'
import {
  getWindowPlatform,
  postWindowParentMessage,
  useHistory,
  useLocation,
} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Icon, Image, ModalLoading, Paragraph} from 'common/components'
import firebase from 'lib/firebase'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {PaymentCheckoutUserInfo} from '../UserInfo'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledInfoContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.danger_1,
  })}
  border-radius: ${convertUnit(8)};
  margin-bottom: ${convertUnit(10)};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: ${convertUnit(5)};
  line-height: ${convertUnit(21)};
`

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  box-sizing: border-box;
  ${({mode, theme}) => ({
    flexDirection: mode === 'mobile' ? 'column' : 'row',
    padding: convertUnit(mode === 'mobile' ? 25 : 10),
    backgroundColor: theme.white_1,
  })}
`

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledRightSection = styled(StyledSection)<StyledContainerProps>`
  ${({mode}) => ({
    marginLeft: mode === 'mobile' ? undefined : convertUnit('50px'),
    marginTop: mode === 'mobile' ? convertUnit('25px') : undefined,
  })};
  display: flex;
  flex-direction: column;
`

const StyledQrCode = styled(QRCode)`
  margin-top: ${convertUnit(10)};
  height: ${convertUnit(200)};
  width: ${convertUnit(200)};
  display: flex;
  align-self: center;
`

const StyledQrImage = styled(Image)`
  margin-top: ${convertUnit(10)};
  height: ${convertUnit(200)};
  width: ${convertUnit(200)};
  display: flex;
  align-self: center;
`

const StyledTitle = styled(Paragraph)`
  margin-top: ${convertUnit('8px')};
`

const StyledLogo = styled(Image)`
  width: ${convertUnit('44px')};
  height: ${convertUnit('26px')};
  object-fit: contain;
`

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit('20px')};
`

const StyledDescriptionContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_2,
  })}
  display: flex;
  flex-direction: column;
  margin-top: ${convertUnit('10px')};
  padding: ${convertUnit('10px')};
`

const StyledList = styled.ol`
  font-family: 'Roboto-Regular' !important;
  margin: 0;
  padding-left: ${convertUnit('20px')};
`

const StyledDesc = styled(Paragraph)`
  white-space: pre-line;
  margin-right: ${convertUnit('10px')};
  margin-left: ${convertUnit('10px')};
  line-height: ${convertUnit('21px')};
`

export default function PaymentCheckoutEwalletDetail() {
  const {translate} = useTranslation()
  const paymentData = useLocation('checkout_ewallet_details').state
  const {voucher, extraFee} = paymentData
  const [eWallet, setEwallet] = useState<GopayOutletData>(INITIALIZE_GOPAY_DATA)
  const [status, setStatus] = useState<PaymentStatusType>()
  const [currentDate, setCurrentDate] = useState(new Date())
  const history = useHistory()
  const mode = useWindowMode()
  const [loading, setLoading] = useState(true)
  const platform = getWindowPlatform()
  const isWeb = useMemo(() => platform === 'web', [platform])
  const appName = useMemo(
    () => (paymentData.paymentMethod === 'ShopeePay' ? 'Shopee' : 'Gojek'),
    [paymentData.paymentMethod],
  )
  const {username} = useSelector('userState')!

  const discount = useMemo(
    () =>
      voucher
        ? voucher?.discount_type === 'flat'
          ? voucher?.discount
          : paymentData.contentData.total_price * (voucher.discount / 100) >
            (voucher.max_discount_amount || 0)
          ? voucher.max_discount_amount || 0
          : (paymentData.contentData.total_price || 0) *
            (voucher.discount / 100)
        : 0,
    [voucher, paymentData.contentData.total_price],
  )

  const handleFirestore = useCallback(
    () =>
      firebase
        .firestore()
        .collection(process.env.FIRESTORE_COLLECTION_PAYMENT!)
        .doc(paymentData.invoiceId)
        .onSnapshot((snapshot) => {
          const data = snapshot.data()!
          if (data !== undefined) {
            setEwallet(data.ewallet)
            setStatus(data.status)
            setLoading(false)
          }
        }),
    [paymentData.invoiceId, setLoading],
  )

  const handleRenderInfo = useMemo(
    () =>
      paymentData && (
        <PaymentCheckoutUserInfo
          currentDate={paymentData.date}
          expectedAmount={
            paymentData.contentData.total_price - discount + extraFee
          }
          username={username}
        />
      ),
    [discount, extraFee, paymentData, username],
  )

  const handleTransactionStatus = useCallback(() => {
    history.replace('checkout_status', {
      invoiceId: paymentData.invoiceId,
      contentData: paymentData.contentData,
      paymentMethodId: paymentData.paymentMethodId!,
      paymentType: paymentData.paymentType,
    })
  }, [
    history,
    paymentData.contentData,
    paymentData.invoiceId,
    paymentData.paymentMethodId,
    paymentData.paymentType,
  ])

  const handlePayWithClicked = useCallback(() => {
    postWindowParentMessage({
      type: 'redirect',
      payload: eWallet.actions.deeplink_redirect,
    })
  }, [eWallet.actions.deeplink_redirect])

  const handleAccountDetail = useMemo(
    () =>
      paymentData && (
        <StyledRightSection mode={mode}>
          <StyledInfoContainer>
            <Icon
              type="info"
              size={16}
              marginRight={convertUnit(10)}
              color="danger_5"
            />
            <Paragraph fontSize="s" fontWeight="medium" color="danger_5">
              {translate('payment:paymentInstructionTimeLimit', {
                time: PAYMENT_EWALLET_TIME_LIMIT,
              })}
            </Paragraph>
          </StyledInfoContainer>
          <StyledTitleContainer>
            <StyledTitle fontSize="m" fontWeight="medium">
              {translate('payment:paymentInstruction')}
            </StyledTitle>
            <StyledLogo src={paymentData.logo} alt={paymentData.logo} />
          </StyledTitleContainer>
          <StyledDescriptionContainer>
            <StyledList>
              {!isWeb && (
                <li>
                  <StyledDesc fontSize="m">
                    {translate('payment:eWalletInstruction', {app: appName})}
                  </StyledDesc>
                </li>
              )}
              <li>
                {isWeb ? (
                  <StyledDesc fontSize="m">
                    {translate('payment:eWalletInstruction_QRWeb', {
                      method: paymentData.paymentMethod,
                      app: appName,
                    })}
                  </StyledDesc>
                ) : (
                  <StyledDesc fontSize="m">
                    {translate('payment:eWalletInstruction_QRMobile', {
                      method: paymentData.paymentMethod,
                      app: appName,
                    })}
                  </StyledDesc>
                )}
              </li>
              <li>
                <StyledDesc fontSize="m">
                  {translate('payment:eWalletInstruction_complete')}
                </StyledDesc>
              </li>
            </StyledList>
            {eWallet.actions.qr_checkout_string ? (
              <StyledQrCode value={eWallet.actions.qr_checkout_string} />
            ) : (
              <StyledQrImage
                alt={eWallet.actions.generate_qr_code}
                src={eWallet.actions.generate_qr_code}
              />
            )}
            {!isWeb && (
              <StyledButton
                label={translate('payment:payWith')}
                rightIcon={
                  <StyledLogo
                    style={{marginLeft: convertUnit('10px')}}
                    src={paymentData.logo}
                    alt={paymentData.logo}
                  />
                }
                backgroundColor="white_3"
                color="primary_5"
                onClick={handlePayWithClicked}
              />
            )}
          </StyledDescriptionContainer>
          <StyledButton
            label={translate('payment:checkTransaction')}
            onClick={handleTransactionStatus}
          />
        </StyledRightSection>
      ),
    [
      appName,
      eWallet.actions.generate_qr_code,
      eWallet.actions.qr_checkout_string,
      handlePayWithClicked,
      handleTransactionStatus,
      isWeb,
      mode,
      paymentData,
      translate,
    ],
  )

  useEffect(handleFirestore, [handleFirestore])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentDate(new Date())
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [currentDate])

  useEffect(() => {
    if (status && status !== 'PROCESSED') {
      handleTransactionStatus()
    }
  }, [handleTransactionStatus, history, paymentData, status])

  return (
    <StyledContainer mode={mode}>
      {handleRenderInfo}
      {handleAccountDetail}
      <ModalLoading visible={loading} />
    </StyledContainer>
  )
}
