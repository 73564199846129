import { TranslationKey } from '../keys'
import { TRANSLATION_GLOBAL_ID } from './global'
import { TranslationResource } from '../TranslationType'
import { TRANSLATION_PAYMENT_ID } from './payment'

export const TRANSLATION_ID: TranslationResource<TranslationKey> = {
  translation: {
    ...TRANSLATION_GLOBAL_ID.translation,
    ...TRANSLATION_PAYMENT_ID.translation,
  },
}
