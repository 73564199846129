import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {DownTimeModalItemProps} from './DownTimeModalItemProps'

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(295)};
  padding: ${convertUnit(20)};
  display: flex;
  flex-direction: column;
`

const StyledParagraph = styled(Paragraph)`
  padding: ${convertUnit(3)};
  text-align: center;
`

const StyledTitle = styled(Paragraph)`
  margin-bottom: ${convertUnit(8)};
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
  width: 100%;
`

export default function ServerDownTimeModal({
  onClickOk,
  visible,
}: DownTimeModalItemProps) {
  const {translate} = useTranslation()

  return (
    <StyledModal visible={visible}>
      <StyledTitle fontSize="l" fontWeight="bold">
        {translate('global:serverUnderMaintenance')}
      </StyledTitle>
      <StyledParagraph fontSize="m" fontWeight="regular">
        {translate('global:serverUnderMaintenanceDescFirst')}
      </StyledParagraph>
      <StyledParagraph fontSize="m" fontWeight="regular">
        {translate('global:serverUnderMaintenanceDescSecond')}
      </StyledParagraph>
      <StyledButton label={translate('global:ok')} onClick={onClickOk} />
    </StyledModal>
  )
}
