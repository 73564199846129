import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { useWindowLayout } from 'windows'
import { ViewProps } from './ViewProps'

export default function View({ onLayout, ...props }: ViewProps) {
  const ref = useRef<HTMLDivElement>(null)
  const windowLayout = useWindowLayout()

  const handleWindowLayout = useCallback(() => {
    const instance = ref.current

    if (onLayout && instance) {
      const {
        clientWidth,
        clientHeight,
        offsetWidth,
        offsetHeight,
        scrollWidth,
        scrollHeight,
      } = instance

      onLayout({
        clientWidth,
        clientHeight,
        offsetWidth,
        offsetHeight,
        scrollWidth,
        scrollHeight,
      })
    }
  }, [onLayout])

  useLayoutEffect(() => {
    handleWindowLayout()
  }, [windowLayout, handleWindowLayout])

  return <div {...props} ref={ref} />
}
