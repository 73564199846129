import React, { useMemo } from 'react'
import { List } from '../List'
import { ListIndicator } from '../ListIndicator'
import { ListLazyBaseProps } from './ListLazyBaseProps'

export default function ListLazyBase<ItemT>({
  status,
  extraContent,
  listEmptyElement,
  listEndPageElement,
  listFooterElement,
  listInitializeElement,
  listLoadingElement,
  listLoadingElementStyle,
  ...props
}: ListLazyBaseProps<ItemT>) {
  const handleRenderFooter = useMemo(
    () => (
      <>
        {extraContent}
        <ListIndicator
          status={status}
          listEmptyElement={listEmptyElement}
          listEndPageElement={listEndPageElement}
          listInitializeElement={listInitializeElement}
          listLoadingElement={listLoadingElement}
          listLoadingElementStyle={listLoadingElementStyle}
        />
        {listFooterElement}
      </>
    ),
    [
      extraContent,
      status,
      listEmptyElement,
      listEndPageElement,
      listInitializeElement,
      listLoadingElement,
      listLoadingElementStyle,
      listFooterElement,
    ],
  )

  return <List {...props} listFooterElement={handleRenderFooter} />
}
