import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import { Paragraph } from '../Paragraph'
import { TextInformationProps } from './TextInformationProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledIconContainer = styled.div`
  box-sizing: border-box;
  padding: ${convertUnit('4px')};
  margin-top: ${convertUnit('3px')};
  margin-right: ${convertUnit('5px')};
  margin-bottom: ${convertUnit('5px')};
`

const StyledIcon = styled.div`
  ${({ theme }) => ({ backgroundColor: theme.gray_3 })}
  width: ${convertUnit('8px')};
  height: ${convertUnit('8px')};
  border-radius: 50%;
`

export default function TextInformation({
  message,
}: TextInformationProps) {
  return (
    <StyledContainer>
      <StyledIconContainer>
        <StyledIcon />
      </StyledIconContainer>
      <Paragraph fontSize="m" color="gray_3">
        {message}
      </Paragraph>
    </StyledContainer>
  )
}
