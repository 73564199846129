export function getCurrencyValue(
  nominal: number,
  code: string = 'IDR',
  locale: string = 'en',
) {
  return nominal.toLocaleString(locale, {
    currency: code,
    currencyDisplay: 'code',
    style: 'currency',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
}
