import { TranslationKey } from '../keys'
import { TRANSLATION_GLOBAL_EN } from './global'
import { TRANSLATION_PAYMENT_EN } from './payment'
import { TranslationResource } from '../TranslationType'

export const TRANSLATION_EN: TranslationResource<TranslationKey> = {
  translation: {
    ...TRANSLATION_GLOBAL_EN.translation,
    ...TRANSLATION_PAYMENT_EN.translation,
  },
}
