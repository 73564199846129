import React from 'react'
import styled from 'styled-components'
import { BUTTON_SIZE_MAP } from 'consts'
import { getBoxShadow } from 'utils'
import { ButtonIconProps } from './ButtonIconProps'
import { Icon } from '../Icon'
import { Button } from '../Button'

interface StyledButtonProps {
  hasShadow: boolean
}

const StyledButton = styled(Button)<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ theme, hasShadow }) =>
    hasShadow
      ? getBoxShadow(theme, {
          horizontalOffset: 1,
          verticalOffset: 2,
          blurRadius: 8,
          opacity: 0.15,
        })
      : undefined};
`

export default function ButtonIcon({
  type = 'normal',
  iconType,
  iconColor = 'gray_1',
  iconStyle,
  hasIconShadow,
  hasShadow = true,
  ...props
}: ButtonIconProps) {
  return (
    <StyledButton
      {...props}
      type={type}
      width={BUTTON_SIZE_MAP[type]}
      hasShadow={hasShadow}
    >
      <Icon
        type={iconType}
        size={(BUTTON_SIZE_MAP[type] * 5) / 11}
        color={iconColor}
        style={iconStyle}
        hasShadow={hasIconShadow}
      />
    </StyledButton>
  )
}
