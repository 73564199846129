import React from 'react'
import styled from 'styled-components'
import {Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {translate} from 'i18n'
import {formatHours, getCurrencyValue, parseDate} from 'utils'
import {PaymentCheckoutInfoItem} from '../InfoItem'
import {PaymentCheckoutUserInfoProps} from './PaymentCheckoutUserInfoProps'

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledInfoItem = styled(PaymentCheckoutInfoItem)`
  margin-top: ${convertUnit('10px')};
`

const StyledDurationText = styled(Paragraph)`
  margin-top: ${convertUnit('10px')};
`

const StyledDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export default function PaymentCheckoutUserInfo({
  currentDate,
  expirationDate,
  timeDiff,
  expectedAmount,
  username,
}: PaymentCheckoutUserInfoProps) {
  return (
    <StyledSection>
      <StyledInfoItem
        title={translate('payment:checkoutTransactionDate')}
        description={parseDate(
          currentDate.toISOString(),
          'MMMM D, YYYY, HH:mm',
        )}
      />
      {expirationDate && timeDiff && (
        <>
          <StyledDateContainer>
            <StyledDurationText fontSize="m" fontWeight="medium">
              {translate('payment:paymentDurationEnds')}
            </StyledDurationText>
            <StyledDurationText fontSize="m" fontWeight="medium">
              {translate('payment:paymentTimeLeft')}
            </StyledDurationText>
          </StyledDateContainer>
          <StyledDateContainer>
            <Paragraph fontSize="m">
              {parseDate(expirationDate, 'MMMM D, YYYY, HH:mm')}
            </Paragraph>
            {
              <Paragraph fontSize="m" fontWeight="medium" color="primary_5">
                {formatHours(
                  timeDiff.hours,
                  timeDiff.minutes,
                  timeDiff.seconds,
                )}
              </Paragraph>
            }
          </StyledDateContainer>
        </>
      )}
      <StyledInfoItem
        title={translate('payment:checkoutContentTotalPayment')}
        description={getCurrencyValue(expectedAmount)}
        totalPrice
        boldDescription
      />
      <StyledInfoItem
        title={translate('payment:checkoutAccountName')}
        description={username}
        boldDescription
      />
    </StyledSection>
  )
}
