import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useDefaultState, useDidUpdate } from 'utils'
import convertUnit from 'lib/unit'
import { Paragraph } from '../Paragraph'
import { TabViewProps } from './TabViewProps'

interface StyledTabItemContainerProps {
  isSelected: boolean
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledTabItemContainer = styled.div<StyledTabItemContainerProps>`
  ${({ isSelected, theme }) => ({
    borderBottomColor: isSelected ? theme.primary_5 : theme.gray_1,
    color: isSelected ? theme.primary_5 : theme.gray_1,
  })}
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${convertUnit('16px')};
  border-bottom-style: solid;
  border-bottom-width: ${convertUnit('3px')};
  :hover {
    cursor: default;
    opacity: 0.6;
  }
`

const StyledTitle = styled(Paragraph)`
  color: inherit;
  text-align: center;
`

export default function TabView<T extends string>({
  routes,
  selectedRoute,
  containerStyle,
  stateSelect,
  renderTitle,
  renderItem,
}: TabViewProps<T>) {
  const [select, setSelect] = useDefaultState<T | undefined>(
    selectedRoute || (routes.length ? routes[0] : undefined),
    stateSelect,
  )

  const handleRenderTabItems = useMemo(
    () =>
      routes.map((route) => (
        <StyledTabItemContainer
          key={route}
          isSelected={select === route}
          onClick={() => setSelect(route)}
        >
          <StyledTitle fontSize="l" fontWeight="medium">
            {renderTitle(route)}
          </StyledTitle>
        </StyledTabItemContainer>
      )),
    [select, routes, renderTitle, setSelect],
  )

  const handleRenderItem = useMemo(
    () => (select ? renderItem(select) : null),
    [select, renderItem],
  )

  useDidUpdate(() => setSelect(selectedRoute), [
    selectedRoute,
    setSelect,
  ])

  return (
    <StyledContainer style={containerStyle}>
      <StyledTabContainer>{handleRenderTabItems}</StyledTabContainer>
      {handleRenderItem}
    </StyledContainer>
  )
}
