import React from 'react'
import styled from 'styled-components'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {PaymentSelectMethodItemProps} from './PaymentSelectMethodItemProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: ${convertUnit(12)};
  padding-left: ${convertUnit(20)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_2};
  align-items: center;
  cursor: pointer;
  margin-bottom: ${convertUnit(5)};
  justify-content: space-between;
  user-select: none;
`

const StyledLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledMethod = styled(Paragraph)`
  margin-left: ${convertUnit(20)};
`

const StyledMinMaxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default function PaymentSelectMethodItem({
  description,
  icon,
  onClick,
}: PaymentSelectMethodItemProps) {
  return (
    <StyledContainer onClick={onClick}>
      <StyledLogoContainer>
        <Icon type={icon} color="info_5" size={50} />
        <StyledMinMaxContainer>
          <StyledMethod fontSize="m" fontWeight="bold">
            {description}
          </StyledMethod>
        </StyledMinMaxContainer>
      </StyledLogoContainer>
    </StyledContainer>
  )
}
