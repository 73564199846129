import React, {
  FocusEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { FieldError } from 'react-hook-form'
import {
  InputFormValidationKeys,
  InputHandlerParam,
  InputHandlerValue,
} from 'types'

export function useInputHandler<
  TFieldValues extends object = object,
  T = HTMLInputElement | HTMLTextAreaElement
>({
  name,
  form,
  formRules,
  onFocus,
  onBlur,
  onChange,
  allowedCharacters,
  onChangeText,
  onKeyPress,
  refElement,
}: InputHandlerParam<TFieldValues, T>) {
  const [isFocused, setFocused] = useState(false)
  const setLastUpdate = useState<number>()[1]

  const register = useMemo(() => form && form.register(formRules), [
    form,
    formRules,
  ])

  const error = useMemo<FieldError | undefined>(
    () =>
      form && name
        ? // @ts-ignore
          form.errors[name]
        : undefined,
    [form, name],
  )
  const errorType = useMemo(
    () => error?.type as InputFormValidationKeys | undefined,
    [error],
  )
  const showError = useMemo(
    () => errorType && !formRules?.hideErrors?.[errorType],
    [errorType, formRules],
  )

  const handleRef = useCallback(
    (instance: any) => {
      if (instance) {
        register && register(instance)
        refElement.current = instance
      }
    },
    [refElement, register],
  )

  const handleTriggerError = useCallback(() => {
    form && form.trigger(name)
  }, [form, name])

  const handleFocus = useCallback(
    (event: FocusEvent<T>) => {
      onFocus && onFocus(event)
      setFocused(true)
    },
    [onFocus, setFocused],
  )

  const handleBlur = useCallback(
    (event: FocusEvent<T>) => {
      onBlur && onBlur(event)
      handleTriggerError()
      setFocused(false)
    },
    [onBlur, setFocused, handleTriggerError],
  )

  const handleChange = useCallback(
    (event: any) => {
      onChange && onChange(event)
      onChangeText && onChangeText(event.target.value || '')
      setLastUpdate(new Date().getTime())
      error && handleTriggerError()
    },
    [
      error,
      handleTriggerError,
      onChange,
      onChangeText,
      setLastUpdate,
    ],
  )

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<T>) => {
      onKeyPress && onKeyPress(event)

      if (
        allowedCharacters &&
        !(typeof allowedCharacters === 'function'
          ? allowedCharacters(event.key)
          : allowedCharacters.test(event.key))
      ) {
        event.preventDefault()
      }

      if (event.key === 'Enter') {
        event.preventDefault()
        refElement.current?.blur()
      }
    },
    [allowedCharacters, onKeyPress, refElement],
  )

  return useMemo<InputHandlerValue<T>>(
    () => ({
      error,
      errorType,
      showError,
      isFocused,
      handleFocus,
      handleBlur,
      handleChange,
      handleKeyPress,
      handleRef,
    }),
    [
      error,
      errorType,
      showError,
      isFocused,
      handleFocus,
      handleBlur,
      handleChange,
      handleKeyPress,
      handleRef,
    ],
  )
}
