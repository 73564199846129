import React, {useMemo} from 'react'
import styled from 'styled-components'
import {GIFTSHOP_MIN_VERSION, WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {isVersionCompatible, useQueryParamValue} from 'utils'
import {useSelector} from 'lib/redux'
import {PaymentCheckoutStatus, PaymentCheckoutStatusLink} from '../containers'

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.white_3};

  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    background-color: ${({theme}) => theme.white_1};
  }
`

export default function PaymentCheckoutStatusScreen() {
  const {invoice_id} = useQueryParamValue(['invoice_id'])
  const {version} = useSelector('versionState')

  const isCompatibleVersion = useMemo(
    () => isVersionCompatible(GIFTSHOP_MIN_VERSION, version),
    [version],
  )

  return (
    <StyledContainer>
      {invoice_id ? (
        <PaymentCheckoutStatusLink isCompatibleVersion={isCompatibleVersion} />
      ) : (
        <PaymentCheckoutStatus isCompatibleVersion={isCompatibleVersion} />
      )}
    </StyledContainer>
  )
}
