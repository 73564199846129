export const whitelistUser = [
  'oPgqAlL0yW384VEaNVGYZB5dKRz6e12D',
  'QnJZred57vjp8BGXaewDNA29LM0xalb3',
  'aL4oVK7jM90WPxG6nxGXrmJdYvg23yNA',
  'y3XbPKvmL0Nx4Gp1gDBw679WVrMaRBD1',
  'd75mgZbM0DyoWGQ89REn4Bap2N8P3QL1',
  '3jo6yZDMYRevzbE9pjk1W4x2gAqXO9p5',
  'nLX4yMZBN7lojWke5vkzrR3mbgAQ5O2J',
  'JYbe3Rpm1927rawqdBGzdWKDAByN8jq6',
]
