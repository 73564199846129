import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {
  IMAGE_ASSET,
  PAYMENT_METHOD_ID_CREDIT,
  PAYMENT_METHOD_ID_OVO,
} from 'consts'
import {useTranslation} from 'i18n'
import {
  getCurrencyValue,
  getWindowPlatform,
  postWindowParentMessage,
  showSnackbar,
  useHistory,
} from 'utils'
import {VoucherResponse} from 'types'
import {requestData} from 'services'
import {
  AutoLayout,
  Button,
  ButtonOutline,
  Icon,
  Image,
  Paragraph,
} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {whitelistUser} from '__mocks__'
import {PaymentCheckoutMethodItem} from '../MethodItem'
import {PaymentCheckoutMethodModal} from '../MethodModal'
import {PaymentCheckoutTransactionDetailProps} from './PaymentCheckoutTransactionProps'
import {PaymentSelectMethodItem} from '../SelectMethodItem'
import {PaymentCheckoutPromoModal} from '../PromoModal'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${convertUnit(25)};
`

const StyledButtonContainer = styled.div`
  display: flex;
  box-sizing: border-box;
`

const StyledTotalPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
`

const StyledFreeContent = styled.div`
  ${({theme}) => ({backgroundColor: theme.info_1})};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-radius: ${convertUnit(16)};
  padding: ${convertUnit(25)};
`

const StyledSmallButton = styled(Button)`
  display: flex;
  margin: ${convertUnit(5)};
  flex: 1;
`

const StyledChangePaymentButton = styled(ButtonOutline)`
  margin: ${convertUnit(5)};
  margin-bottom: ${convertUnit(10)};
  margin-top: 0;
`

const StyledModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const StyledHeaderParagraph = styled(Paragraph)`
  margin: ${convertUnit(20)};
  margin-bottom: ${convertUnit(10)};
  margin-top: ${convertUnit(10)};
`

const StyledSeperator = styled.div`
  background-color: ${({theme}) => theme.white_3};
  width: 100%;
  height: ${convertUnit(1)};
`

const StyledDiscountContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: ${convertUnit(8)} ${convertUnit(20)};
  border: ${convertUnit(1)} solid ${({theme}) => theme.gray_1};
  border-radius: ${convertUnit(8)};
  align-items: center;
  cursor: pointer;
  margin-bottom: ${convertUnit(15)};
  gap: ${convertUnit(8)};
  user-select: none;
`
const StyledAppliedPromo = styled(StyledDiscountContainer)`
  justify-content: space-between;
`

const StyledDiscount = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCheckContainer = styled.div`
  display: flex;
  height: ${convertUnit(20)};
  width: ${convertUnit(20)};
`

export default function PaymentCheckoutTransactionDetail({
  data,
  methods,
  checkoutToken,
  accessToken,
  isButtonLoadingState,
}: PaymentCheckoutTransactionDetailProps) {
  const {translate} = useTranslation()
  const {id} = useSelector('userState') || {}
  const history = useHistory()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalPromoVisible, setModalPromoVisible] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = isButtonLoadingState
  const date = useMemo(() => new Date(), [])
  const freeImage = IMAGE_ASSET('giftshop', 'roboyu-free.png')
  const {update} = useDispatch()
  const isWeb = useMemo(() => getWindowPlatform() === 'web', [])
  const lastPaymentMethod = useSelector('lastPaymentMethodState')

  const stateAppliedPromo = useState<VoucherResponse | undefined>()
  const [voucher, setAppliedPromo] = stateAppliedPromo

  // delete later, for production testing purpose
  const isWhitelistedUser = useMemo(
    () => whitelistUser.map((x) => id === x).includes(true),
    [id],
  )

  const defaultSelect = useMemo(() => {
    if (lastPaymentMethod?.id) {
      return methods.find(
        (type) =>
          lastPaymentMethod?.id === type.payment_method_id &&
          type.payment_method_id === lastPaymentMethod?.id &&
          data.total_price >= type.min_amount &&
          data.total_price <= type.max_amount,
      )
    }
    return undefined
  }, [data.total_price, lastPaymentMethod?.id, methods])

  const stateSelect = useState(defaultSelect)
  const select = stateSelect[0]

  const isPaymentMethodInvalidForPromo = useMemo(
    () =>
      select && voucher
        ? select.payment_method.toLowerCase() !==
          voucher.payment_method_name.toLowerCase()
        : false,
    [voucher, select],
  )

  const discount = useMemo(
    () =>
      voucher
        ? voucher?.discount_type === 'flat'
          ? voucher?.discount
          : data.total_price * (voucher.discount / 100) >
            (voucher.max_discount_amount || 0)
          ? voucher.max_discount_amount || 0
          : data.total_price * (voucher.discount / 100)
        : 0,
    [voucher, data.total_price],
  )

  const totalPrice = useMemo(
    () => data.total_price - discount + (select?.extra_fee || 0),
    [data.total_price, discount, select?.extra_fee],
  )

  const handleRedirectPage = useCallback(
    (invoiceId: string) => {
      if (data.total_price === 0) {
        history.replace('checkout_status', {
          invoiceId,
          checkoutToken,
          contentData: data,
          paymentMethodId: PAYMENT_METHOD_ID_OVO,
          paymentType: 'Ewallet',
        })
      } else if (
        select?.payment_type === 'Virtual Account' ||
        select?.payment_type === 'Retail'
      ) {
        history.replace('checkout_details', {
          invoiceId,
          contentData: data,
          paymentMethodId: select.payment_method_id,
          paymentMethod: select.payment_method,
          paymentType: select.payment_type,
          extraFee: select.extra_fee,
          logo: select.logo,
          date,
        })
      } else if (
        select?.payment_method === 'GoPay' ||
        select?.payment_method === 'ShopeePay'
      ) {
        history.replace('checkout_ewallet_details', {
          invoiceId,
          contentData: data,
          paymentMethodId: select.payment_method_id,
          paymentMethod: select.payment_method,
          paymentType: select.payment_type,
          extraFee: select.extra_fee,
          logo: select.logo,
          date,
          voucher,
        })
      } else if (select?.payment_type === 'Ewallet') {
        history.replace('checkout_status', {
          invoiceId,
          checkoutToken,
          contentData: data,
          paymentMethodId: select.payment_method_id,
          paymentType: select.payment_type,
          voucher,
        })
      }
    },
    [
      voucher,
      checkoutToken,
      data,
      date,
      history,
      select?.extra_fee,
      select?.logo,
      select?.payment_method,
      select?.payment_method_id,
      select?.payment_type,
    ],
  )

  const handleContinue = useCallback(() => {
    setIsButtonLoading(true)
    const headers = {Authorization: `Bearer ${accessToken}`}
    if (select && select.payment_method_id === PAYMENT_METHOD_ID_OVO) {
      history.push('checkout_phone_transaction', {
        token: checkoutToken,
        contentData: data,
        selectedPaymentMethod: select,
        voucher,
      })
      setIsButtonLoading(false)
    } else if (
      select &&
      select.payment_method_id === PAYMENT_METHOD_ID_CREDIT
    ) {
      history.push('checkout_credit_transaction', {
        token: checkoutToken,
        contentData: data,
        selectedPaymentMethod: select,
        voucher,
      })
      setIsButtonLoading(false)
    } else if (data.total_price === 0) {
      requestData('payment_checkout_update', {
        data: {
          checkout_token: checkoutToken,
          payment_method_id: PAYMENT_METHOD_ID_OVO,
          type: 'purchase',
          phone: '0',
        },
        onRequestSuccess: ({status, data: responseData}) => {
          if (status === 200) {
            const invoiceId =
              typeof responseData.result === 'object'
                ? responseData.result.invoice_id
                : responseData.result
            handleRedirectPage(invoiceId)
          } else {
            showSnackbar(
              translate('global:messageError', {context: `code-${status}`}),
            )
          }
        },
      })
    } else {
      select &&
        requestData('payment_checkout_update', {
          headers,
          useDefaultMessage: true,
          data: {
            checkout_token: checkoutToken,
            payment_method_id: select.payment_method_id,
            type: 'purchase',
            voucher_id: voucher && voucher.id,
          },
          onRequestSuccess: ({status, data: responseData}) => {
            if (status === 200) {
              const invoiceId =
                typeof responseData.result === 'object'
                  ? responseData.result.invoice_id
                  : responseData.result
              update('lastPaymentMethodState', {
                id: select.payment_method_id,
                method: select.payment_method,
              })
              handleRedirectPage(invoiceId)
              setIsButtonLoading(false)
            } else {
              setIsButtonLoading(false)
              showSnackbar(
                translate(
                  status === 422 && responseData.message.includes('voucher')
                    ? 'payment:paymentVoucherError'
                    : 'global:messageError',
                  {context: `code-${status}`},
                ),
              )
            }
          },
        })
    }
  }, [
    accessToken,
    voucher,
    checkoutToken,
    data,
    handleRedirectPage,
    history,
    select,
    setIsButtonLoading,
    translate,
    update,
  ])

  const handleRenderTotalPrice = useMemo(
    () => (
      <>
        {select && select.extra_fee > 0 && (
          <StyledTotalPriceContainer>
            <Paragraph fontSize="m" fontWeight="bold">
              {translate('payment:methodFee')}
            </Paragraph>
            <Paragraph fontSize="m">
              {getCurrencyValue(select.extra_fee)}
            </Paragraph>
          </StyledTotalPriceContainer>
        )}
        {voucher && (
          <StyledTotalPriceContainer>
            <Paragraph fontSize="m" fontWeight="bold">
              {translate('global:discount')}
            </Paragraph>
            <Paragraph fontSize="m">{getCurrencyValue(discount)}</Paragraph>
          </StyledTotalPriceContainer>
        )}
        <StyledTotalPriceContainer>
          <Paragraph fontSize="m" fontWeight="bold">
            {translate('payment:checkoutContentTotalPrice')}
          </Paragraph>
          <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
            {getCurrencyValue(totalPrice)}
          </Paragraph>
        </StyledTotalPriceContainer>
      </>
    ),
    [voucher, discount, select, totalPrice, translate],
  )

  const handleRenderHeader = useMemo(
    () => (
      <>
        <StyledModalHeader>
          <StyledHeaderParagraph fontSize="l" fontWeight="medium">
            {translate('global:checkoutPayment')}
          </StyledHeaderParagraph>
        </StyledModalHeader>
        <StyledSeperator />
      </>
    ),
    [translate],
  )

  const handleRenderPromo = useMemo(
    () =>
      voucher ? (
        <StyledAppliedPromo onClick={() => setModalPromoVisible(true)}>
          <StyledDiscount>
            <Paragraph fontSize="m" fontWeight="bold">
              {translate('payment:checkoutPromoTitle', {
                context: voucher.discount_type,
                amount:
                  voucher.discount_type === 'flat'
                    ? getCurrencyValue(voucher.discount)
                    : voucher.discount,
              })}
            </Paragraph>
            <Paragraph fontWeight="medium" color="success_6">
              {translate('payment:checkoutPromoDescription', {
                method:
                  voucher.method_variant_name || voucher.payment_method_name,
                payment: getCurrencyValue(voucher.min_transaction_amount),
              })}
            </Paragraph>
          </StyledDiscount>
          <StyledCheckContainer>
            <Icon type="check" color="success_5" />
          </StyledCheckContainer>
        </StyledAppliedPromo>
      ) : process.env.APP_ENV !== 'production' ? (
        <StyledDiscountContainer onClick={() => setModalPromoVisible(true)}>
          <Icon type="percentage" color="success_6" />
          <Paragraph fontSize="m" fontWeight="bold" color="success_6">
            {translate('payment:checkoutPromo')}
          </Paragraph>
        </StyledDiscountContainer>
      ) : (
        isWhitelistedUser ? (
          <StyledDiscountContainer onClick={() => setModalPromoVisible(true)}>
            <Icon type="percentage" color="success_6" />
            <Paragraph fontSize="m" fontWeight="bold" color="success_6">
              {translate('payment:checkoutPromo')}
            </Paragraph>
          </StyledDiscountContainer>
        ) : (
          <></>
        )
        
      ),
    [voucher, translate, isWhitelistedUser],
  )

  const handleRenderMethod = useMemo(
    () => (
      <AutoLayout distance={10} onClick={() => setIsModalVisible(true)}>
        {select ? (
          <PaymentCheckoutMethodItem
            data={select}
            totalPrice={data.total_price}
            onSelect={() => setIsModalVisible(true)}
            isSelected
          />
        ) : (
          <PaymentSelectMethodItem
            icon="credit-card"
            description={translate('payment:checkoutMethod')}
            onClick={() => setIsModalVisible(true)}
          />
        )}
        {handleRenderPromo}
      </AutoLayout>
    ),
    [data.total_price, handleRenderPromo, select, translate],
  )

  const handleRenderFreeContent = useMemo(
    () => (
      <StyledFreeContent>
        <Image src={freeImage} alt={freeImage} />
        <Paragraph color="primary_5" fontSize="l" fontWeight="medium">
          {translate('payment:checkoutFreeDescription')}
        </Paragraph>
      </StyledFreeContent>
    ),
    [freeImage, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <>
        {select && (
          <StyledChangePaymentButton
            borderColor="gray_5"
            label={translate('payment:changePaymentMethod')}
            onClick={() => setIsModalVisible(true)}
            backgroundColor="white_1"
            backgroundHoverColor="white_3"
            color="gray_5"
          />
        )}
        <StyledButtonContainer>
          <StyledSmallButton
            label={translate('global:cancel')}
            onClick={() => postWindowParentMessage({type: 'close'})}
            backgroundColor="white_3"
            color="primary_5"
          />
          <StyledSmallButton
            label={translate(
              data.total_price !== 0
                ? 'payment:checkoutPhoneButtonLabel'
                : 'global:continue',
            )}
            isLoading={isButtonLoading}
            disabled={
              isButtonLoading ||
              (data.total_price !== 0 && select === undefined) ||
              isPaymentMethodInvalidForPromo
            }
            onClick={handleContinue}
          />
        </StyledButtonContainer>
      </>
    ),
    [
      data.total_price,
      handleContinue,
      isButtonLoading,
      isPaymentMethodInvalidForPromo,
      select,
      translate,
    ],
  )

  const handleAllMethod = useMemo(
    () => (
      <PaymentCheckoutMethodModal
        visible={isModalVisible}
        selected={stateSelect}
        totalPrice={data.total_price}
        methods={methods}
        onClose={() => {
          setIsModalVisible((prev) => !prev)
          if (isPaymentMethodInvalidForPromo) {
            showSnackbar(translate('payment:checkoutPromoRemoved'))
          }
        }}
      />
    ),
    [
      data.total_price,
      isModalVisible,
      isPaymentMethodInvalidForPromo,
      methods,
      stateSelect,
      translate,
    ],
  )

  const handlePromoListModal = useMemo(
    () => (
      <PaymentCheckoutPromoModal
        visible={modalPromoVisible}
        onClose={() => setModalPromoVisible((prev) => !prev)}
        onUse={(item) => setAppliedPromo(item)}
        totalPrice={data.total_price}
        paymentMethod={select}
        isPaymentMethodInvalidForPromo={isPaymentMethodInvalidForPromo}
      />
    ),
    [
      data.total_price,
      isPaymentMethodInvalidForPromo,
      modalPromoVisible,
      select,
      setAppliedPromo,
    ],
  )

  useEffect(() => {
    update('paymentErrorState', {voucherError: false})
  }, [update])

  return (
    <>
      {isWeb && handleRenderHeader}
      <StyledContainer>
        <AutoLayout distance={20}>
          {data.total_price !== 0
            ? handleRenderMethod
            : handleRenderFreeContent}
          {handleRenderTotalPrice}
          {handleRenderButton}
          {handleAllMethod}
          {handlePromoListModal}
        </AutoLayout>
      </StyledContainer>
    </>
  )
}
