export const AVATAR_SIZE = '40px'

export const ICON_SIZE = '20px'

export const BRAND_SIZE = 28
export const BRAND_LETTER_SPACE = '5px'

export const BUTTON_PADDING_VERTICAL = '10px'
export const BUTTON_PADDING_HORIZONTAL = '16px'

export const BOUNDING_BOX_BORDER_RADIUS = '8px'
