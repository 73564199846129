import React from 'react'
import styled from 'styled-components'
import {getCurrencyValue} from 'utils'
import {useTranslation} from 'i18n'
import {Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {PaymentCheckoutContentItemProps} from './PaymentCheckoutContentItemProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${convertUnit(10)} 0;
  align-items: center;
`

const StyledImage = styled(Image)`
  height: ${convertUnit(64)};
  width: ${convertUnit(64)};
  border-radius: ${convertUnit(8)};
`

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${convertUnit(25)};
  margin-right: ${convertUnit(10)};
`

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const StyledSubInfo = styled(Paragraph)`
  margin-top: ${convertUnit(5)};
`
const StyledParagraph = styled(Paragraph)`
  word-wrap: break-word;
  overflow-wrap: anywhere;
`

export default function PaymentCheckoutContentItem({
  data: {price, title, url, creator_name, is_free_purchase},
}: PaymentCheckoutContentItemProps) {
  const {translate} = useTranslation()

  return (
    <StyledContainer>
      <StyledImage src={url} alt={title} />
      <StyledDescriptionContainer>
        <StyledParagraph fontSize="m" fontWeight="bold">
          {title}
        </StyledParagraph>
        <StyledSubInfo>{creator_name}</StyledSubInfo>
      </StyledDescriptionContainer>
      <StyledPriceContainer>
        <Paragraph fontWeight="medium" color="gray_5">
          {translate('payment:checkoutContentPrice')}
        </Paragraph>
        <StyledSubInfo fontSize="m" fontWeight="bold" color="primary_5">
          {getCurrencyValue(is_free_purchase ? 0 : price)}
        </StyledSubInfo>
      </StyledPriceContainer>
    </StyledContainer>
  )
}
