import {useCallback, useMemo} from 'react'
import i18next, {ResourceLanguage, TOptions} from 'i18next'
import {
  initReactI18next,
  useTranslation as useTranslationOrigin,
  UseTranslationOptions,
} from 'react-i18next'
import {TRANSLATION_NAMESPACE_DEFAULT} from 'consts'
import {REDUX_STORE, useSelector} from 'lib/redux'
import {TranslationKey} from './keys'
import {TRANSLATION_EN, TRANSLATION_ID} from './translations'
import {
  TranslationResource,
  TranslationResourceKey,
  TranslationState,
} from './TranslationType'

export function formatTranslationResource<T extends string>(
  resource: TranslationResource<T>,
): ResourceLanguage {
  const translation: TranslationResourceKey<string> = {}
  const {translation: source, ...props} = resource

  for (const [key, value] of Object.entries(source)) {
    const parts = key.split(':')
    const ns = parts.length > 1 ? parts[0] : TRANSLATION_NAMESPACE_DEFAULT
    const id = parts.length > 1 ? parts[1] : key

    if (!(ns in translation)) {
      translation[ns] = {}
    }

    translation[ns][id] = value
  }

  return {...props, ...translation}
}

export function initTranslation() {
  if (!i18next.isInitialized) {
    i18next.use(initReactI18next).init({
      lng: 'en',
      fallbackLng: 'en',
      defaultNS: TRANSLATION_NAMESPACE_DEFAULT,
      resources: {
        en: formatTranslationResource(TRANSLATION_EN),
        id: formatTranslationResource(TRANSLATION_ID),
      },
    })
  }
}

export function translate(key: TranslationKey, options?: TOptions) {
  return i18next.t(key, {
    lng: REDUX_STORE.getState().userState?.lang || 'id',
    ...options,
  })
}

export function useTranslation(options?: UseTranslationOptions) {
  const userInfo = useSelector('userState')
  const {t, ready, i18n} = useTranslationOrigin(undefined, options)

  const handleTranslate = useCallback(
    (key: TranslationKey, _options: TOptions) =>
      t(key, {lng: userInfo?.lang, ..._options}),
    [t, userInfo?.lang],
  )

  return useMemo<TranslationState>(
    () => ({
      translate: handleTranslate,
      ready,
      i18n,
    }),
    [handleTranslate, ready, i18n],
  )
}
