import {
  ServiceHandler,
  ServiceHandlerOption,
} from 'types'
import { ServiceParamExample } from '../ServiceParamExample'

/**
 * @description called when example post finished
 */
function handleExample({
  response,
}: ServiceHandlerOption<ServiceParamExample, 'example_post'>) {
  const {data, status} = response;
  
  console.log({data, status}) // example side effect
}

export const SERVICE_HANDLER_EXAMPLE: ServiceHandler<ServiceParamExample> = {
  example_post: handleExample,
}