import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {REGEX_NUMBER} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  debounce,
  getCurrencyValue,
  showSnackbar,
  useDidMount,
  useHistory,
  useLocation,
} from 'utils'
import convertUnit from 'lib/unit'
import {Paragraph, Button, Input, Image} from 'common/components'
import {PaymentCheckoutResponse} from 'types'
import {useDispatch, useSelector} from 'lib/redux'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
  justify-content: center;
  align-items: center;
`

const StyledPayment = styled(Paragraph)`
  text-align: center;
`

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(25)};
  align-items: center;
  justify-content: center;
`

const StyledPrice = styled(Paragraph)`
  margin-left: ${convertUnit(5)};
`

const StyledButton = styled(Button)`
  display: flex;
  margin: ${convertUnit(5)};
  flex: 1;
`

const StyledButtonContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  margin-top: ${convertUnit(25)};
  width: 100%;
`

const StyledLogo = styled(Image)`
  object-fit: contain;
  margin-bottom: ${convertUnit(20)};
`

export default function PaymentCheckoutPhoneTransaction() {
  const {translate} = useTranslation()
  const history = useHistory()
  const {voucherError} = useSelector('paymentErrorState')
  const {token, contentData, selectedPaymentMethod, voucher} = useLocation(
    'checkout_phone_transaction',
  ).state
  const [phone, setPhone] = useState('')
  const [disableButton, setDisableButton] = useState(false)
  const [data, setData] = useState<PaymentCheckoutResponse>()
  const {update} = useDispatch()

  const discount = useMemo(
    () =>
      voucher
        ? voucher?.discount_type === 'flat'
          ? voucher?.discount
          : (data?.total_price || 0) * (voucher.discount / 100) >
            (voucher.max_discount_amount || 0)
          ? voucher.max_discount_amount || 0
          : (data?.total_price || 0) * (voucher.discount / 100)
        : 0,
    [voucher, data?.total_price],
  )

  const handleLoadData = useCallback(async () => {
    const responseData = await requestData('payment_get_token', {
      params: {token},
    })
    setData(
      typeof responseData !== 'string' ? responseData.data.result : undefined,
    )
  }, [token])

  useDidMount(() => {
    handleLoadData()
  })

  const handlePay = debounce(() => {
    setDisableButton(true)
    if (data)
      requestData('payment_checkout_update', {
        useDefaultMessage: true,
        data: {
          checkout_token: token,
          payment_method_id: selectedPaymentMethod.payment_method_id,
          phone,
          type: 'purchase',
          voucher_id: voucher && voucher.id,
        },
        onRequestSuccess: ({status, data: responseData}) => {
          if (status === 200) {
            const invoiceId =
              typeof responseData.result === 'object'
                ? responseData.result.invoice_id
                : responseData.result
            update('lastPaymentMethodState', {
              id: selectedPaymentMethod.payment_method_id,
              method: selectedPaymentMethod.payment_method,
            })
            history.push('checkout_status', {
              invoiceId,
              checkoutToken: token,
              contentData,
              paymentMethodId: selectedPaymentMethod.payment_method_id,
              paymentType: 'Ewallet',
              phone,
              voucher,
            })
          } else if (
            status === 422 &&
            responseData.message.includes('voucher')
          ) {
            showSnackbar(translate('payment:paymentVoucherError'))
            update('paymentErrorState', {voucherError: true})
            setDisableButton(false)
          } else {
            showSnackbar(
              translate('global:messageError', {context: `code-${status}`}),
            )
          }
        },
      })
  }, 300)

  return (
    <StyledContainer>
      <StyledLogo
        src={selectedPaymentMethod.logo}
        alt={selectedPaymentMethod.payment_method_id}
        height={45}
        width={160}
      />
      <StyledPayment fontWeight="bold">
        {translate('payment:checkoutPhoneDescription', {
          method: selectedPaymentMethod.payment_method,
        })}
      </StyledPayment>
      <StyledPriceContainer>
        <Paragraph fontSize="m">
          {translate('payment:checkoutPhoneTotalPrice')}
        </Paragraph>
        <StyledPrice fontSize="m" fontWeight="bold">
          {getCurrencyValue(
            data?.total_price ? data?.total_price - discount : 0,
          )}
        </StyledPrice>
      </StyledPriceContainer>
      <Input
        allowedCharacters={REGEX_NUMBER}
        placeholder="e.g. 0812939299399"
        containerStyle={{
          marginTop: convertUnit(15),
          width: '100%',
          margin: convertUnit(5),
        }}
        onChangeText={setPhone}
      />
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:back')}
          onClick={() => history.goBack()}
          backgroundColor="white_3"
          color="primary_5"
        />
        <StyledButton
          label={translate('payment:checkoutPhoneButtonLabel')}
          disabled={disableButton || phone.length < 10 || voucherError}
          isLoading={disableButton}
          onClick={handlePay}
        />
      </StyledButtonContainer>
    </StyledContainer>
  )
}
