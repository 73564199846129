export const REGEX_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const REGEX_PHONE_NUMBER = /^[+]*[(]?\d{1,4}[)]?[-\s./0-9]*$/
export const REGEX_START_WITH_LETTER = /^[A-Za-z].*$/
export const REGEX_ALPHABET = /[A-Za-z]/
export const REGEX_NUMBER = /^\d*$/
export const REGEX_CVV = /^[0-9]{3}$/
export const REGEX_USERNAME = /^[A-Za-z0-9._-]*$/
export const REGEX_USERNAME_SPECIAL = /[._-]/
export const REGEX_NAME = /^[A-Za-z ]*$/
export const REGEX_VERSION = /^[0-9]+[.[\d]+]*$/

export const REGEX_VISA = /^4/
export const REGEX_MASTERCARD = /^5[1-5]/
export const REGEX_16JCB = /^35(2[89]|[3-8][0-9])/
export const REGEX_15JCB = /^(2131|1800)\d{11}$/
