import React, {useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {showSnackbar, useDidUpdate, useHistory, useLocation} from 'utils'
import convertUnit from 'lib/unit'
import {CreditCardStatusType, GetCreditVerificationResponse} from 'types'
import {requestData} from 'services'
import {useDispatch} from 'lib/redux'
import {useTranslation} from 'i18n'

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  padding: ${convertUnit(25)};
`

const StyledIframe = styled.iframe`
  flex: 1;
  border: 0;
  width: 100%;
  height: 100%;
`

export default function PaymentCheckoutCreditSecure() {
  const {translate} = useTranslation()
  const {
    token: checkout_token,
    contentData,
    authenticationUrl,
    selectedPaymentMethod,
    voucher,
    bin,
  } = useLocation('checkout_credit_secure').state
  const [authenticationId, setAuthenticationId] = useState('')
  const [cardId, setCardId] = useState('')
  const [authStatus, setAuthStatus] = useState<CreditCardStatusType>()
  const history = useHistory()
  const frame = useRef<HTMLIFrameElement>()
  const {update} = useDispatch()

  const handlePay = useCallback(() => {
    requestData('payment_checkout_update', {
      useDefaultMessage: true,
      data: {
        checkout_token,
        payment_method_id: selectedPaymentMethod.payment_method_id,
        credit_card: {authentication_id: authenticationId, token_id: cardId},
        type: 'purchase',
        voucher_id: voucher && voucher.id,
        bin,
      },
      onRequestSuccess: ({status, data: responseData}) => {
        if (status === 200) {
          const invoiceId =
            typeof responseData.result === 'object'
              ? responseData.result.invoice_id
              : responseData.result
          update('lastPaymentMethodState', {
            id: selectedPaymentMethod.payment_method_id,
            method: selectedPaymentMethod.payment_method,
          })
          history.push('checkout_status', {
            invoiceId,
            checkoutToken: checkout_token,
            contentData,
            paymentMethodId: selectedPaymentMethod.payment_method_id,
            paymentType: 'Credit Card',
            voucher,
          })
        } else if (status === 422 && responseData.message.includes('voucher')) {
          showSnackbar(translate('payment:paymentVoucherError'))
          update('paymentErrorState', {voucherError: true})
          history.go(-2)
        } else {
          showSnackbar(
            translate('global:messageError', {context: `code-${status}`}),
          )
        }
      },
    })
  }, [
    voucher,
    authenticationId,
    bin,
    cardId,
    checkout_token,
    contentData,
    history,
    selectedPaymentMethod.payment_method,
    selectedPaymentMethod.payment_method_id,
    translate,
    update,
  ])

  const handleVerificationResponse = useCallback((event: MessageEvent) => {
    const responseData = JSON.parse(event.data) as GetCreditVerificationResponse
    setAuthenticationId(responseData.authentication_id)
    setCardId(responseData.id)
    setAuthStatus(responseData.status)
  }, [])

  useEffect(() => {
    window.addEventListener('message', handleVerificationResponse)
    return () => {
      window.removeEventListener('message', handleVerificationResponse)
    }
  })

  useDidUpdate(() => {
    if (authStatus === 'VERIFIED') {
      handlePay()
    }
  }, [authStatus])

  return (
    <StyledContainer>
      <StyledIframe
        src={authenticationUrl}
        ref={(e) => {
          frame.current = e!
        }}
        title="secure3DS"
      />
    </StyledContainer>
  )
}
