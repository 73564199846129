import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {PaymentStatusType} from 'types'
import {postWindowParentMessage, useQueryParamValue} from 'utils'
import firebase from 'lib/firebase'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {PaymentCheckoutStatusBase} from '../StatusBase'
import {PaymentErrorModal} from '../ErrorModal'
import PaymentCheckoutStatusSuccessWaitingHiresInfo from './PaymentCheckoutStatusSuccessWaitingHiresInfo'
import PaymentCheckoutStatusWaitingForPaymentInfo from './PaymentCheckoutStatusWaitingForPaymentInfo'
import {PaymentCheckoutStatusProps} from './PaymentCheckoutStatusProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: ${convertUnit(25)};
`

export default function PaymentCheckoutStatusLink({
  isCompatibleVersion,
}: PaymentCheckoutStatusProps) {
  const {translate} = useTranslation()
  const {invoice_id} = useQueryParamValue(['invoice_id'])
  const [status, setStatus] = useState<PaymentStatusType>('PROCESSED')
  const user = useSelector('userState')
  const username = user?.username
  const [collectorUsername, setCollectorUsername] = useState('')

  const isValid = useMemo(
    () => (collectorUsername ? username === collectorUsername : true),
    [collectorUsername, username],
  )

  const handleFirestore = useCallback(
    () =>
      firebase
        .firestore()
        .collection(process.env.FIRESTORE_COLLECTION_PAYMENT!)
        .doc(invoice_id)
        .onSnapshot((snapshot) => {
          const data = snapshot.data()!
          if (data !== undefined) {
            setCollectorUsername(data.collector_username)
            setStatus(data.status as PaymentStatusType)
          }
        }),
    [invoice_id],
  )

  const handleRenderEmptyModal = useMemo(
    () => (
      <PaymentErrorModal
        visible={!isValid}
        onClick={() => postWindowParentMessage({type: 'close'})}
      />
    ),
    [isValid],
  )

  const handleExploreButton = useCallback(() => {
    postWindowParentMessage({type: 'explore'})
  }, [])

  const handleSuccessBackToCollection = useCallback(() => {
    postWindowParentMessage({type: 'collection'})
  }, [])

  useEffect(() => {
    handleFirestore()
  }, [handleFirestore])

  switch (status) {
    case 'COMPLETED':
      return (
        <StyledContainer>
          <PaymentCheckoutStatusBase
            icon="check"
            iconColor="success_5"
            title={translate('payment:statusSuccessTitle')}
            description={translate('payment:statusSuccessDescription')}
            buttonLabel={translate('global:backToFotoyu')}
            bottomElement={<PaymentCheckoutStatusSuccessWaitingHiresInfo />}
            onClickButton={() =>
              isCompatibleVersion
                ? handleSuccessBackToCollection()
                : handleExploreButton()
            }
          />
          {handleRenderEmptyModal}
        </StyledContainer>
      )
    case 'FAILED':
      return (
        <StyledContainer>
          {handleRenderEmptyModal}
          <PaymentCheckoutStatusBase
            icon="close"
            iconColor="gray_3"
            title={translate('payment:statusFailedTitle')}
            description={translate('payment:statusFailedDescription')}
            hideButton
          />
        </StyledContainer>
      )
    default:
      return (
        <StyledContainer>
          {handleRenderEmptyModal}
          <PaymentCheckoutStatusBase
            icon="clock"
            iconColor="warning_5"
            title={translate('payment:statusWaitingTitle')}
            description={translate('payment:statusWaitingDescriptionDana')}
            buttonLabel={translate('global:backToFotoyu')}
            onClickButton={handleExploreButton}
            bottomElement={<PaymentCheckoutStatusWaitingForPaymentInfo />}
          />
        </StyledContainer>
      )
  }
}
