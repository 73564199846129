import {
  DanaOutletData,
  GopayOutletData,
  RetailOutletData,
  VirtualAccountData,
} from 'types'

export const PAYMENT_CURRENCY_NAME_DEFAULT = 'IDR'
export const PAYMENT_EWALLET_TIME_LIMIT = 15 // minutes

export const PAYMENT_CURRENCY_ID_DEFAULT = 'MR9Xdjv70WpeODE8DEPLAyb8xVBQzq31'

export const PAYMENT_METHOD_ID_CREDIT = '4ezgM1YO8Lo3nVGb2Gx50lbAdXQRvNKZ'
export const PAYMENT_METHOD_ID_OVO = 'MR9Xdjv70WpeODE8DEPLAyb8xVBQzq31'
export const PAYMENT_METHOD_ID_BCA = 'VzNRq71Qovb0eAkNxGjp2WdDBym9OZKa'
export const PAYMENT_METHOD_ID_GOPAY = 'pVdPJrOWmn72KqkZNkavbegDy6Ml89x3'
export const PAYMENT_METHOD_ID_SHOPEE = 'vXJZ0AjWQoxgbOExMGmzpV8qeY6lPB59'
export const PAYMENT_OVO_MAX_LIMIT = 2000000

export const PAYMENT_CREDIT_TOKENIZATION_URL =
  'https://api.xendit.co/v2/credit_card_tokens'

export const PAYMENT_DURATION = 60

export const INITIALIZE_VIRTUAL_ACCOUNT_DATA: VirtualAccountData = {
  account_number: '',
  bank_code: '',
  expected_amount: 0,
  expiration_date: '',
  external_id: '',
  id: '',
  name: '',
  owner_id: '',
  status: '',
}

export const INITIALIZE_RETAIL_DATA: RetailOutletData = {
  expected_amount: 0,
  expiration_date: '',
  id: '',
  payment_code: '',
  retail_outlet_name: '',
}

export const INITIALIZE_GOPAY_DATA: GopayOutletData = {
  actions: {
    cancel: '',
    deeplink_redirect: '',
    qr_checkout_string: '',
    generate_qr_code: '',
  },
  id: '',
  name: '',
  payment_type: '',
  transaction_time: '',
}

export const INITIALIZE_DANA_DATA: DanaOutletData = {
  actions: {
    desktop_web_checkout_url: '',
    mobile_web_checkout_url: '',
  },
  id: '',
  payment_type: '',
  transaction_time: '',
}
