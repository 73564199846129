import {translate} from 'i18n'
import {InputFormRules} from 'types'
import {getValidation} from '../Validation'

export const VALIDATION_INPUT_REQUIRED: InputFormRules = {
  required: true,
  messages: {
    required: getValidation('global:validationInputRequired'),
  },
}

export const VALIDATION_INPUT_EXPIRY_DATE = (
  month: number,
  isValid: boolean,
): InputFormRules => ({
  required: true,
  messages: {
    required: getValidation('global:validationInputRequired'),
  },
  validate: {
    notExpired: () =>
      !isValid ? translate('global:validationInputExpiredNotValid') : true,
    month: () =>
      month > 12 ? translate('global:validationInputExpiredNotValid') : true,
  },
})

export const VALIDATION_INPUT_CVV: InputFormRules = {
  minLength: 3,
  required: true,
  messages: {
    minLength: getValidation('global:validationCVVMinLength'),
    required: getValidation('global:validationInputRequired'),
  },
}
