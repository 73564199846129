import {WINDOW_MODE_MOBILE_WIDTH, WINDOW_MODE_TABLET_WIDTH} from 'consts'
import {PlatformType, WindowModeType, WindowParentMessageParam} from 'types'
import {getGiftShopURL} from '../environtments'

export function getWindowMode(
  width: number = window.innerWidth,
): WindowModeType {
  if (width <= WINDOW_MODE_MOBILE_WIDTH) {
    return 'mobile'
  }
  if (width <= WINDOW_MODE_TABLET_WIDTH) {
    return 'tablet'
  }
  return 'website'
}

export function postWindowParentMessage({
  type,
  payload,
}: WindowParentMessageParam) {
  const message = JSON.stringify({type, payload})

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(message)
  } else if (window.opener) {
    window.opener.postMessage(message, getGiftShopURL())
  }
}

export function getWindowPlatform(): PlatformType {
  if (window.ReactNativeWebView) {
    return 'mobile-android'
  }
  return 'web'
}
