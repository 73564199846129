import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Icon, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useTranslation} from 'i18n'
import {getCurrencyValue} from 'utils'
import {PaymentCheckoutMenthodItemProps} from './PaymentCheckoutMethodItemProps'

interface StyledDisabled {
  disabled: boolean
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: ${convertUnit(20)};
  border-radius: ${convertUnit(8)};
  background-color: ${({theme}) => theme.white_2};
  align-items: center;
  cursor: pointer;
  margin-bottom: ${convertUnit(15)};
  justify-content: space-between;
  user-select: none;
`

const StyledLogo = styled(Image)<StyledDisabled>`
  ${({disabled}) => ({opacity: disabled ? 0.6 : 1})}
  display: inline-block;
  object-fit: contain;
  height: ${convertUnit(40)};
  width: ${convertUnit(59)};
`

const StyledLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledMethod = styled(Paragraph)`
  margin-left: ${convertUnit(20)};
`

const StyledCheckContainer = styled.div`
  display: flex;
  height: ${convertUnit(20)};
  width: ${convertUnit(20)};
`

const StyledMinMaxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default function PaymentCheckoutMethodItem({
  data: {logo, payment_method, max_amount, min_amount, extra_fee},
  isSelected,
  totalPrice,
  onSelect,
}: PaymentCheckoutMenthodItemProps) {
  const {translate} = useTranslation()
  const disabled = useMemo(
    () => totalPrice < min_amount || totalPrice > max_amount,
    [max_amount, min_amount, totalPrice],
  )

  const handleRenderCheck = useMemo(
    () =>
      isSelected && (
        <StyledCheckContainer>
          <Icon type="check" color="primary_5" />
        </StyledCheckContainer>
      ),
    [isSelected],
  )

  const description = useMemo(() => {
    if (totalPrice < min_amount) {
      return translate('payment:minPayment', {
        Amount: getCurrencyValue(min_amount),
      })
    }
    if (totalPrice > max_amount) {
      return translate('payment:maxPayment', {
        Amount: getCurrencyValue(max_amount),
      })
    }
    return translate('payment:methodFee', {
      Amount: getCurrencyValue(extra_fee),
    })
  }, [extra_fee, max_amount, min_amount, totalPrice, translate])

  return (
    <StyledContainer onClick={!disabled ? onSelect : undefined}>
      <StyledLogoContainer>
        <StyledLogo disabled={disabled} src={logo} alt={payment_method} />
        <StyledMinMaxContainer>
          <StyledMethod
            fontSize="m"
            fontWeight="medium"
            color={disabled ? 'gray_3' : 'black'}>
            {payment_method}
          </StyledMethod>
          <StyledMethod
            fontWeight="medium"
            color={disabled ? 'gray_3' : 'gray_5'}>
            {description}
          </StyledMethod>
        </StyledMinMaxContainer>
      </StyledLogoContainer>
      {handleRenderCheck}
    </StyledContainer>
  )
}
