export function getAPIBaseUrl() {
  return process.env.API_BASE_URL
}

export function getCDNBaseUrl() {
  return process.env.CDN_BASE_URL
}

export function getOAuthClientId() {
  return process.env.OAUTH_CLIENT_ID!
}

export function getGiftShopURL() {
  return process.env.HOST_GIFTSHOP!
}

export function getXenditAPIKey() {
  return process.env.XENDIT_API_KEY
}
