import React, {useMemo} from 'react'
import styled from 'styled-components'
import {debounce} from 'utils'
import {Button, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {PaymentCheckoutStatusBaseProps} from './PaymentCheckoutStatusBaseProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.white_1};
  justify-content: center;
`

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-top: ${convertUnit(15)};
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`

const StyledMessageContainer = styled.div`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

const StyledText = styled(Paragraph)`
  text-align: center;
`

export default function PaymentCheckoutStatusBase({
  countdown,
  icon,
  iconColor,
  title,
  description,
  buttonLabel,
  hideButton,
  bottomElement,
  onClickButton,
}: PaymentCheckoutStatusBaseProps) {
  const handleRenderInfo = useMemo(
    () => (
      <StyledInfoContainer>
        <Icon type={icon} color={iconColor} size={64} />
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {title}
        </StyledParagraph>
        {typeof description !== 'string' ? (
          description
        ) : (
          <StyledParagraph fontSize="l">{description}</StyledParagraph>
        )}
      </StyledInfoContainer>
    ),
    [description, icon, iconColor, title],
  )

  const handleCountdown = useMemo(
    () => (
      <StyledText fontWeight="medium" fontSize="l" color="primary_5">
        {countdown}
      </StyledText>
    ),
    [countdown],
  )

  const handleRenderButton = useMemo(
    () =>
      !hideButton && (
        <>
          <StyledMessageContainer>{bottomElement}</StyledMessageContainer>
          <StyledButton
            label={buttonLabel}
            onClick={debounce(onClickButton!, 300)}
          />
        </>
      ),
    [bottomElement, buttonLabel, hideButton, onClickButton],
  )

  return (
    <StyledContainer>
      {handleRenderInfo}
      {handleCountdown}
      {handleRenderButton}
    </StyledContainer>
  )
}
