import {REGEX_VERSION} from 'consts'

export function isVersionCompatible(minVersion: string, currVersion?: string) {
  if (
    !currVersion ||
    !minVersion ||
    !REGEX_VERSION.test(minVersion) ||
    !REGEX_VERSION.test(currVersion)
  ) {
    return false
  }
  const minArr = minVersion.split('.').map((ver) => parseInt(ver, 10))
  const currArr = currVersion.split('.').map((ver) => parseInt(ver, 10))

  for (let i = 0; i < Math.max(currArr.length, minArr.length); i += 1) {
    if (!minArr[i] && currArr[0] > 0) return true
    if (minArr[i] !== currArr[i]) {
      return minArr[i] < currArr[i]
    }

    if (currArr.length === minArr.length && i === currArr.length - 1) {
      return currArr[i] >= minArr[i]
    }
  }
  return false
}
