import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import { Icon } from '../Icon'
import { ButtonHeaderProps } from './ButtonHeaderProps'

interface StyledContainerProps {
  useDefaultMargin: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({ theme, useDefaultMargin }) => ({
    color: theme.primary_5,
    margin: useDefaultMargin ? `0 ${convertUnit('4px')}` : undefined,
    ':hover': { color: theme.primary_4 },
  })}
  padding: ${convertUnit('12px')};
  transition: all 0.15s ease-out;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

export default function ButtonHeader({
  icon,
  useDefaultMargin = true,
  ...props
}: ButtonHeaderProps) {
  return (
    <StyledContainer {...props} useDefaultMargin={useDefaultMargin}>
      <Icon type={icon} size={convertUnit('24px')} color="inherit" />
    </StyledContainer>
  )
}
