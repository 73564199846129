import React from 'react'
import styled from 'styled-components'
import { getFontFamily, getFontSize } from 'utils'
import convertUnit from 'lib/unit'
import { ParagraphProps } from './ParagraphProps'

const StyledParagraph = styled.p<ParagraphProps>`
  ${({
    fontSize = 's',
    fontWeight = 'regular',
    theme,
    color = 'black',
    textAlign,
  }) => ({
    textAlign,
    fontSize:
      typeof fontSize === 'string'
        ? getFontSize(fontSize)
        : convertUnit(fontSize),
    fontFamily: getFontFamily(fontWeight),
    color: color !== 'inherit' ? theme[color] : 'inherit',
  })}
  padding-top: ${convertUnit('2px')};
  padding-bottom: ${convertUnit('2px')};
  margin: 0;
`

export default function Paragraph({ ...props }: ParagraphProps) {
  return <StyledParagraph {...props} />
}
