import React, {useMemo} from 'react'
import {translate} from 'i18n'
import {PaymentDetailsInstructionsProps} from './PaymentDetailsInstructionsProps'
import {PaymentCheckoutExpandDetails} from '../ExpandDetails'

export default function PaymentDetailInstructions({
  bank,
  accountNumber,
}: PaymentDetailsInstructionsProps) {
  const handleInstruction = useMemo(
    () => (
      <>
        <PaymentCheckoutExpandDetails
          label={translate('global:mBanking', {context: bank})}
          description={translate('payment:virtualmBankingDescription', {
            Account: accountNumber,
            context: bank,
          })}
        />
        <PaymentCheckoutExpandDetails
          label={translate('global:eBanking')}
          description={translate('payment:virtualiBankingDescription', {
            Account: accountNumber,
            context: bank,
          })}
        />
        <PaymentCheckoutExpandDetails
          label={translate('global:transferATM')}
          description={translate('payment:virtualAtmDescription', {
            Account: accountNumber,
            context: bank,
          })}
        />
      </>
    ),
    [accountNumber, bank],
  )
  const handleBRIExtra = useMemo(
    () =>
      bank === 'BRI' && (
        <>
          <PaymentCheckoutExpandDetails
            label={translate('global:transferMiniATM')}
            description={translate('payment:virtualMiniAtmDescription_BRI')}
          />
        </>
      ),
    [bank],
  )

  return (
    <>
      {handleInstruction}
      {handleBRIExtra}
    </>
  )
}
