import React, { useMemo } from 'react'
import Lottie, { Options } from 'react-lottie'
import animationData from 'assets/lottie/loading.json'
import { Modal } from '../Modal'
import { ModalLoadingProps } from './ModalLoadingProps'

export default function ModalLoading({
  ...props
}: ModalLoadingProps) {
  const options = useMemo<Options>(() => ({ animationData }), [])

  return (
    <Modal {...props}>
      <Lottie isClickToPauseDisabled options={options} />
    </Modal>
  )
}
