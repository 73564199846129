import React from 'react'
import {translate} from 'i18n'
import styled from 'styled-components'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {PaymentErrorModalProps} from './PaymentErrorModalProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
`
const StyledHeaderParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
  width: 100%;
`

export default function PaymentErrorModal({
  visible,
  onClick,
}: PaymentErrorModalProps) {
  return (
    <Modal visible={visible}>
      <StyledContainer>
        <StyledHeaderParagraph fontSize="xl" fontWeight="bold">
          {translate('payment:statusFailedTitle')}
        </StyledHeaderParagraph>
        <StyledParagraph fontSize="l" fontWeight="regular">
          {translate('payment:statusFailedTitleLabel')}
        </StyledParagraph>
        <StyledButton onClick={onClick}>
          {translate('payment:statusFailedTitleButton')}
        </StyledButton>
      </StyledContainer>
    </Modal>
  )
}
