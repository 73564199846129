import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  FocusEvent,
} from 'react'
import styled from 'styled-components'
import { FieldError } from 'react-hook-form'
import {
  CSSHtmlProperties,
  DropdownInputOption,
  FontSizeType,
  FontWeightType,
  InputFormValidationKeys,
} from 'types'
import { ThemeColor } from 'themes'
import { getFontSize } from 'utils'
import { FONT_FAMILY_MAP } from 'consts'
import convertUnit from 'lib/unit'
import { Paragraph } from '../Paragraph'
import { DropdownInputProps } from './DropdownInputProps'

const StyledError = styled(Paragraph)`
  ${({ theme }) => ({ color: theme.danger_5 })}
  box-sizing: border-box;
  margin-top: ${convertUnit('7px')};
  margin-bottom: ${convertUnit('2px')};
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`
interface StyledInputContainerProps
  extends CSSHtmlProperties<HTMLDivElement> {
  color?: ThemeColor
  focusBorderColor?: ThemeColor
  focusBackgroundColor?: ThemeColor
  hoverBorderColor?: ThemeColor
  backgroundColor?: ThemeColor
  isFocused: boolean
  showError?: boolean
  error?: FieldError
}

const StyledInputContainer = styled.div<StyledInputContainerProps>`
  ${({
    theme,
    color = 'black',
    focusBorderColor = 'primary_5',
    focusBackgroundColor = 'white_1',
    hoverBorderColor = 'gray_2',
    backgroundColor = 'white_2',
    isFocused,
    showError,
    error,
  }) => ({
    borderColor: theme.gray_1,
    color: theme[color],
    backgroundColor: theme[backgroundColor],
    ...(isFocused
      ? {
          borderColor: theme[focusBorderColor],
          backgroundColor: theme[focusBackgroundColor],
        }
      : !error
      ? {
          ':hover': { borderColor: theme[hoverBorderColor] },
        }
      : undefined),
    ...(showError &&
      error !== undefined && {
        borderColor: theme.danger_5,
      }),
  })}
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  box-sizing: border-box;
  border-style: solid;
  border-radius: ${convertUnit('8px')};
  border-width: ${convertUnit('1px')};
  align-items: center;
  justify-content: center;
  overflow: hidden;
  appearance: none;
  -webkit-appearance: none;
`

interface StyledInputProps {
  fontSize?: FontSizeType
  fontWeight?: FontWeightType
  placeholderColor?: ThemeColor
}

const StyledSelect = styled.select<StyledInputProps>`
  ${({
    theme,
    color = 'black',
    placeholderColor = 'gray_3',
    fontSize = 'm',
    fontWeight = 'regular',
  }) => ({
    fontSize: getFontSize(fontSize),
    fontFamily: FONT_FAMILY_MAP[fontWeight],
    color: theme[color],
    '::placeholder': {
      color: theme[placeholderColor],
    },
  })}
  flex: 1;
  box-sizing: border-box;
  border-style: none;
  outline: none;
  padding: ${convertUnit('14px')} ${convertUnit('16px')};
  background-color: inherit;
`

const StyledDanger = styled.small`
  color: ${({ theme }) => theme.danger_5};
`

export default function DropdownInput<
  TFieldValues extends object = object
>({
  options,
  label,
  name,
  form,
  formRules,
  containerStyle,
  required,
  color,
  focusBackgroundColor,
  focusBorderColor,
  hoverBorderColor,
  backgroundColor,
  onFocus,
  onBlur,
  onChange,
  onChangeSelection,
  ...props
}: DropdownInputProps<TFieldValues>) {
  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef<HTMLSelectElement | null>(null)

  const register = useMemo(() => form && form.register(formRules), [
    form,
    formRules,
  ])

  const error = useMemo(
    () =>
      form && name
        ? (form.errors[name as string] as FieldError)
        : undefined,
    [form, name],
  )

  const errorType = useMemo(
    () => error?.type as InputFormValidationKeys | undefined,
    [error],
  )

  const showError = useMemo(
    () => errorType && formRules?.hideErrors?.[errorType],
    [formRules, errorType],
  )

  const handleTriggerError = useCallback(() => {
    form && form.trigger(name)
  }, [form, name])

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLSelectElement>) => {
      onFocus && onFocus(event)
      setIsFocused(true)
    },
    [onFocus, setIsFocused],
  )

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLSelectElement>) => {
      onBlur && onBlur(event)
      handleTriggerError()
      setIsFocused(false)
    },
    [onBlur, setIsFocused, handleTriggerError],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      event.persist()
      onChange && onChange(event)
      onChangeSelection && onChangeSelection(event.target.value || '')
      error && handleTriggerError()
    },
    [error, handleTriggerError, onChange, onChangeSelection],
  )

  const handleRef = useCallback(
    (instance: HTMLSelectElement) => {
      ref.current = instance
      register && register(ref.current)
    },
    [register],
  )
  const handleRenderLabel = useMemo(
    () =>
      label ? (
        <Paragraph
          fontSize="m"
          fontWeight="medium"
          style={{ marginBottom: convertUnit('5px') }}
        >
          {label}
          {required && <StyledDanger>*</StyledDanger>}
        </Paragraph>
      ) : null,
    [label, required],
  )

  const handleRenderError = useMemo(() => {
    if (error && errorType && showError) {
      const value = ref.current?.value || ''
      const validationMessage =
        formRules?.messages && formRules.messages[errorType]
      const message =
        error.message ||
        (validationMessage
          ? validationMessage({
              text: value,
              length: value.length.toString(),
              max: formRules?.max?.toString(),
              maxLength: formRules?.maxLength?.toString(),
              min: formRules?.min?.toString(),
              minLength: formRules?.minLength?.toString(),
              required: formRules?.required?.toString(),
            })
          : undefined)

      if (message) {
        return (
          <StyledError fontWeight="medium">{message}</StyledError>
        )
      }
    }

    return null
  }, [error, errorType, showError, formRules])

  return (
    <StyledContainer style={containerStyle}>
      {handleRenderLabel}
      <StyledInputContainer
        color={color}
        isFocused={isFocused}
        focusBackgroundColor={focusBackgroundColor}
        focusBorderColor={focusBorderColor}
        hoverBorderColor={hoverBorderColor}
        backgroundColor={backgroundColor}
        showError={showError}
        error={error}
      >
        <StyledSelect
          {...props}
          name={name}
          ref={handleRef}
          onChange={(event) => handleChange(event)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          {options.map((option: DropdownInputOption) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </StyledSelect>
      </StyledInputContainer>
      {handleRenderError}
    </StyledContainer>
  )
}
