import { SnackbarHandler } from 'types'

let handler: SnackbarHandler | undefined

export function setSnackbarHandler(instance: SnackbarHandler) {
  handler = instance
}

export function showSnackbar(message: string) {
  handler && handler.showSnackbar(message)
}
