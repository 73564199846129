import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { getBoxShadow } from 'utils'
import convertUnit from 'lib/unit'
import { MenuProps } from './MenuProps'

const StyledContainer = styled.div`
  position: relative;
`

const StyledContentContainer = styled.div`
  ${({ theme }) => ({
    width: '100%',
    backgroundColor: theme.white_1,
    boxShadow: getBoxShadow(theme, { verticalOffset: 8 }),
  })}
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  transform: translate(0, ${convertUnit('5px')});
  overflow: hidden;
  z-index: 10;
`

export default function Menu({
  stateToggle,
  contentContainerStyle,
  renderContent,
  children,
  ...props
}: MenuProps) {
  const [toggle, setToggle] = stateToggle
  const refContent = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        refContent.current &&
        // @ts-ignore
        !refContent.current.contains(event.target)
      ) {
        setToggle(false)
      }
    },
    [setToggle],
  )

  const handleRenderContent = useMemo(
    () =>
      toggle && (
        <StyledContentContainer
          ref={refContent}
          style={contentContainerStyle}
        >
          {renderContent}
        </StyledContentContainer>
      ),
    [toggle, contentContainerStyle, renderContent],
  )

  useEffect(() => {
    if (toggle) {
      document.addEventListener('click', handleClickOutside)

      return () =>
        document.removeEventListener('click', handleClickOutside)
    }

    return undefined
  }, [toggle, handleClickOutside])

  return (
    <StyledContainer {...props}>
      {children}
      {handleRenderContent}
    </StyledContainer>
  )
}
