import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {getCurrencyValue} from 'utils'
import {useTranslation} from 'i18n'
import convertUnit from 'lib/unit'
import {CheckboxItem, Icon, Paragraph} from 'common/components'
import {PaymentCheckoutContentItem} from '../ContentItem'
import {PaymentCheckoutContentParams} from './PaymentCheckoutContentParam'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${convertUnit(25)};
`

const StyledTotalPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin-top: ${convertUnit(25)};
  align-items: center;
  justify-content: space-between;
`

const StyledExpandContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${convertUnit(10)};
  padding: ${convertUnit(2)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const StyledExpand = styled(Paragraph)`
  margin: 0 ${convertUnit(5)};
`

const StyledCheckboxContainer = styled.div`
  padding-top: ${convertUnit(20)};
  display: flex;
  flex-direction: row;
`

export default function PaymentCheckoutContent({
  paymentData,
  totalPrice,
  onAutopostSelect,
}: PaymentCheckoutContentParams) {
  const {translate} = useTranslation()
  const {data} = paymentData
  const [expand, setExpand] = useState(false)
  const [autopost, setAutopost] = useState(false);

  const handleRenderContent = useMemo(
    () =>
      data
        .filter((_, index) => expand || index < 2)
        .map((item) => (
          <PaymentCheckoutContentItem key={item.content_id} data={item} />
        )),
    [data, expand],
  )

  const handleRenderExpand = useMemo(
    () =>
      !expand &&
      data.length > 2 && (
        <StyledExpandContainer onClick={() => setExpand(true)}>
          <StyledExpand fontWeight="bold" color="primary_5">
            {translate('payment:checkoutContentExpand')}
          </StyledExpand>
          <Icon size={12} color="primary_5" type="chevron-down" />
        </StyledExpandContainer>
      ),
    [data, expand, translate],
  )

  const handleRenderTotalPrice = useMemo(
    () => (
      <StyledTotalPriceContainer>
        <Paragraph fontSize="m" fontWeight="medium">
          {translate('payment:checkoutContentTotalPrice')}
        </Paragraph>
        <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
          {getCurrencyValue(totalPrice)}
        </Paragraph>
      </StyledTotalPriceContainer>
    ),
    [totalPrice, translate],
  )

  const handleCheckboxState = useCallback(
    () => {
      setAutopost((prev) => !prev)
      onAutopostSelect()
    },
    [onAutopostSelect],
  )
  
  const handleRenderDoNotAutopost = useMemo(
    () => (
      <StyledCheckboxContainer>
        <CheckboxItem
          isSelected={autopost}
          size={16}
          onClick={handleCheckboxState}
          backgroundColor="gray_5"
          color="gray_5"
          fontSize="xs"
          fontWeight="medium"
          description={translate('payment:checkoutContentDoNotAutopost')}
        />
      </StyledCheckboxContainer>
    ), [autopost, handleCheckboxState, translate])

  return (
    <StyledContainer>
      {handleRenderContent}
      {handleRenderExpand}
      {handleRenderDoNotAutopost}
      {handleRenderTotalPrice}
    </StyledContainer>
  )
}
