import React from 'react'
import styled from 'styled-components'
import { Button } from 'common/components'
import { ButtonOutlineProps } from './ButtonOutlineProps'

const StyledButtonOutline = styled(Button)<ButtonOutlineProps>`
  ${({ borderColor = 'primary_5', theme, disabled }) => ({
    backgroundColor: 'transparent',
    ...(!disabled && { border: `1px solid ${theme[borderColor]}` }),
  })};
`

export default function ({ ...props }: ButtonOutlineProps) {
  return <StyledButtonOutline {...props} />
}
