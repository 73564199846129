import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {USER_DATA_INITIAL} from 'consts'
import {requestData} from 'services'
import {PaymentCheckoutResponse, PaymentMethodResponse} from 'types'
import {
  postWindowParentMessage,
  useDidMount,
  useMapRef,
  useQueryParamValue,
} from 'utils'
import {ModalLoading} from 'common/components'
import {useDispatch} from 'lib/redux'
import {PaymentErrorModal} from '../ErrorModal'
import PaymentCheckoutTransactionDetail from './PaymentCheckoutTransactionDetail'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export default function PaymentCheckoutTransaction() {
  const {access_token, token: checkout_token, version} = useQueryParamValue([
    'access_token',
    'token',
    'version',
  ])
  const {map, setMapValue, updateMap} = useMapRef<{
    initialize: boolean
    data?: PaymentCheckoutResponse
    methods?: PaymentMethodResponse[]
  }>({initialize: false})
  const {initialize, data, methods} = map.current
  const {update} = useDispatch()
  const isButtonLoadingState = useState(true)
  const setIsButtonLoading = isButtonLoadingState[1]

  const isValid = useMemo(() => !!data && !!methods?.length, [data, methods])

  useDidMount(() => {
    update('userState', {
      ...USER_DATA_INITIAL,
      access_token: access_token!,
    })
    update('versionState', {
      version,
    })
    requestData('payment_get_self_info', {
      onRequestSuccess: ({status, data: responseData}) => {
        if (status !== 200) return
        update('userState', {
          ...responseData.result,
          access_token: access_token!,
        })
      },
    })
  })

  const handleLoadData = useCallback(async () => {
    const token_headers = {
      token: checkout_token,
    }

    const responseData = await requestData('payment_get_token', {
      params: token_headers,
    })
    const responseMethods = await requestData('payment_methods')

    setMapValue({
      initialize: true,
      data:
        typeof responseData !== 'string' ? responseData.data.result : undefined,
      methods:
        typeof responseMethods !== 'string'
          ? responseMethods.data.result
          : undefined,
    })
    setIsButtonLoading(false)
    updateMap()
  }, [checkout_token, setIsButtonLoading, setMapValue, updateMap])

  const handleRenderEmptyModal = useMemo(
    () => (
      <PaymentErrorModal
        visible={initialize && !isValid}
        onClick={() => postWindowParentMessage({type: 'close'})}
      />
    ),
    [initialize, isValid],
  )

  const handleRenderLoadingModal = useMemo(
    () => <ModalLoading visible={!initialize} />,
    [initialize],
  )

  const handleRenderCheckout = useMemo(
    () =>
      !!data &&
      !!methods?.length &&
      checkout_token &&
      access_token && (
        <PaymentCheckoutTransactionDetail
          data={data}
          methods={methods}
          checkoutToken={checkout_token}
          accessToken={access_token}
          isButtonLoadingState={isButtonLoadingState}
        />
      ),
    [access_token, checkout_token, data, isButtonLoadingState, methods],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <StyledContainer>
      {handleRenderEmptyModal}
      {handleRenderLoadingModal}
      {handleRenderCheckout}
    </StyledContainer>
  )
}
