import {TranslationKeyGlobal} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_GLOBAL_ID: TranslationResource<TranslationKeyGlobal> = {
  translation: {
    'global:back': 'Kembali',
    'global:backToFotoyu': 'Kembali ke FotoYu',
    'global:cancel': 'Batal',
    'global:cardPayment': 'Pembayaran Kartu',
    'global:checkoutPayment': 'Pembayaran',
    'global:close': 'Tutup',
    'global:continue': 'Lanjutkan',
    'global:copy': 'Salin',
    'global:copied': 'Nomor berhasil disalin',
    'global:creditCard': 'Kartu Kredit',
    'global:day': 'Day',
    'global:day_0': 'Minggu',
    'global:day_1': 'Senin',
    'global:day_2': 'Selasa',
    'global:day_3': 'Rabu',
    'global:day_4': 'Kamis',
    'global:day_5': 'Jumat',
    'global:day_6': 'Sabtu',
    'global:discount': 'Diskon',
    'global:eBanking': 'e-Banking',
    'global:eWallet': 'eWallets',
    'global:everyday': 'Setiap Hari',
    'global:formatPhone': '+{{phone_code}}{{phone_number}}',
    'global:fotoyuSupportTeam': 'Tim dukungan FotoYu:',
    'global:mBanking': 'm-Banking',
    'global:mBanking_MANDIRI': 'm-Banking (Livin by Mandiri)',
    'global:mBanking_BNI': 'm-Banking (BNI Mobile)',
    'global:messageError': 'Ups... terjadi sesuatu yang tidak terduga',
    'global:messageError-execute':
      'Terjadi kesalahan saat mengeksekusi tindakan. Silahkan coba lagi.',
    'global:messageError-fetch':
      'Terjadi kesalahan saat menarik data. Silahkan coba lagi.',
    'global:messageError_code-400': 'Format data yang dikirim tidak valid',
    'global:messageError_code-401':
      'Akses token yang digunakan sudah tidak valid',
    'global:messageError_code-403': 'Kamu dilarang mengakses fitur ini',
    'global:messageError_code-404': 'Halaman tidak ditemukan',
    'global:messageError_code-408': '$t(global:messageError_timeout)',
    'global:messageError_code-409': 'Data Konflik: ID telah digunakan',
    'global:messageError_no-connection':
      'Nyalakan koneksi internet untuk melanjutkan',
    'global:messageError_timeout': 'Koneksi Terputus',
    'global:no': 'Tidak',
    'global:ok': 'OK',
    'global:paymentType': 'Tipe Pembayaran',
    'global:retail': 'Retail',
    'global:seeAll': 'Lihat Semua',
    'global:serverUnderMaintenance': 'Server Dalam Pemeliharaan',
    'global:serverUnderMaintenanceDescFirst':
      'Pelayanan akan aktif kembali sesaat lagi.',
    'global:serverUnderMaintenanceDescSecond':
      'Mohon maaf atas ketidaknyamanannya.',
    'global:transactionHistory': 'Riwayat Transaksi',
    'global:transferATM': 'Transfer ATM',
    'global:transferMiniATM': 'Transfer Mini ATM',
    'global:uploadAnImage': 'Unggah gambar',
    'global:uploadCriteria':
      'Gambar hanya boleh JPG, JPEG, PNG dan tidak boleh lebih dari 30MB',
    'global:use': 'Gunakan',
    'global:validationExampleEmail': 'Contoh validasi pesan untuk email',
    'global:validationExampleMaxLength':
      'Contoh validasi pesan panjang maksimum',
    'global:validationExampleMinLength':
      'Contoh validasi pesan minimum validasi',
    'global:validationExamplePhone':
      'Contoh validasi pesan untuk nomor telepon',
    'global:validationExampleRequired': 'Contoh validasi pesan yang diperlukan',
    'global:validationInputRequired': 'Input diperlukan',
    'global:validationInputExpiredNotValid': 'Input tidak valid',
    'global:validationCVVMinLength': 'Nomor cvv tidak valid',
    'global:virtualAccount': 'Akun Virtual',
    'global:virtualAccountMethod': 'Akun Virtual {{method}}',
    'global:yes': 'Ya',
  },
}
