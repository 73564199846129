import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {postWindowParentMessage} from 'utils'
import {ButtonOutline, Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(20)};
`

const StyledInfoContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_3,
  })}
  border-radius: ${convertUnit(8)};
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(16)};
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  line-height: ${convertUnit(21)};
`

const StyledButtonOutline = styled(ButtonOutline)`
  width: 100%;
`

export default function PaymentCheckoutStatusWaitingForPaymentInfo() {
  const {translate} = useTranslation()

  return (
    <StyledContainer>
      <StyledInfoContainer>
        <Icon type="info" size={16} />
        <div>
          <Paragraph fontSize="s" fontWeight="medium">
            {translate('payment:statusWaitingReminder1')}
          </Paragraph>
          <Paragraph fontSize="s" fontWeight="medium">
            {translate('payment:statusWaitingReminder2')}
          </Paragraph>
        </div>
      </StyledInfoContainer>
      <StyledButtonOutline
        label={translate('global:transactionHistory')}
        onClick={() => postWindowParentMessage({type: 'history'})}
        borderColor="gray_5"
        color="gray_5"
      />
    </StyledContainer>
  )
}
