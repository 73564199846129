import React from 'react'
import styled from 'styled-components'
import {Paragraph} from 'common/components'
import {PaymentCheckoutInfoItemProps} from './PaymentCheckoutInfoItemProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`

const StyledParagraph = styled(Paragraph)`
  flex: 1;
`

export default function PaymentCheckoutInfoItem({
  title,
  description,
  totalPrice,
  boldDescription,
  details,
  ...props
}: PaymentCheckoutInfoItemProps) {
  return details ? (
    <StyledContainer {...props}>
      <StyledParagraph color="gray_4">{title}</StyledParagraph>
      <StyledParagraph textAlign="right" color="gray_4">
        {description}
      </StyledParagraph>
    </StyledContainer>
  ) : (
    <StyledContainer {...props}>
      <StyledParagraph fontSize="m" fontWeight="medium">
        {title}
      </StyledParagraph>
      <StyledParagraph
        fontSize="m"
        textAlign="right"
        fontWeight={boldDescription ? 'bold' : 'regular'}
        color={totalPrice ? 'primary_5' : 'black'}>
        {description}
      </StyledParagraph>
    </StyledContainer>
  )
}
