import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {PaymentDetailInstructions} from 'pages'
import {RetailOutletData, VirtualAccountData, WindowModeType} from 'types'
import {
  calcTimeDiff,
  getCurrencyValue,
  postWindowParentMessage,
  showSnackbar,
  useHistory,
  useLocation,
} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Icon, Image, ModalLoading, Paragraph} from 'common/components'
import firebase from 'lib/firebase'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {PaymentCheckoutUserInfo} from '../UserInfo'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  box-sizing: border-box;
  ${({mode, theme}) => ({
    flexDirection: mode === 'mobile' ? 'column' : 'row',
    padding: convertUnit(mode === 'mobile' ? 25 : 10),
    backgroundColor: theme.white_1,
  })}
`

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledRightSection = styled(StyledSection)<StyledContainerProps>`
  ${({mode}) => ({
    marginLeft: mode === 'mobile' ? undefined : convertUnit('50px'),
    marginTop: mode === 'mobile' ? convertUnit('25px') : undefined,
  })}
`

const StyledTitle = styled(Paragraph)`
  margin-top: ${convertUnit('8px')};
`

const StyledCopyParagraph = styled(Paragraph)`
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

const StyledLogo = styled(Image)`
  width: ${convertUnit('44px')};
  height: ${convertUnit('26px')};
  object-fit: contain;
`

const StyledInstructionTitle = styled(Paragraph)`
  margin-top: ${convertUnit('40px')};
`

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit('40px')};
`

const StyledDescriptionContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_2,
  })}
  display: flex;
  flex-direction: column;
  margin-top: ${convertUnit('10px')};
  padding: ${convertUnit('10px')};
`

const StyledMessageContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_3,
  })}
  border-radius: ${convertUnit(8)};
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  margin-top: ${convertUnit(20)};
`

export default function PaymentCheckoutDetail() {
  const {translate} = useTranslation()
  const paymentData = useLocation('checkout_details').state
  const [virtualAccount, setVirtualAccount] = useState<VirtualAccountData>({
    account_number: '',
    bank_code: '',
    expected_amount: 0,
    expiration_date: '',
    external_id: '',
    id: '',
    name: '',
    owner_id: '',
    status: '',
  })
  const [retail, setRetail] = useState<RetailOutletData>({
    expected_amount: 0,
    expiration_date: '',
    id: '',
    payment_code: '',
    retail_outlet_name: '',
  })
  const [currentDate, setCurrentDate] = useState(new Date())
  const [timeDiff, setTimeDiff] = useState(
    calcTimeDiff(currentDate, virtualAccount.expiration_date),
  )
  const history = useHistory()
  const mode = useWindowMode()
  const [loading, setLoading] = useState(true)
  const {username} = useSelector('userState')!

  const handleFirestore = useCallback(
    () =>
      firebase
        .firestore()
        .collection(process.env.FIRESTORE_COLLECTION_PAYMENT!)
        .doc(paymentData.invoiceId)
        .onSnapshot((snapshot) => {
          const data = snapshot.data()!
          if (data !== undefined) {
            setVirtualAccount(data.virtual_account)
            setRetail(data.retail_outlet)
            setLoading(false)
          }
        }),
    [paymentData.invoiceId, setLoading],
  )

  useEffect(handleFirestore, [handleFirestore])

  const handleTabClose = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault()
    return postWindowParentMessage({type: 'explore'})
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', handleTabClose)
    return () => {
      window.removeEventListener('beforeunload', handleTabClose)
    }
  }, [handleTabClose])

  const details = useMemo(() => {
    if (retail.expected_amount !== 0) {
      return {
        expected_amount: retail.expected_amount,
        expiration_date: retail.expiration_date,
        code: retail.payment_code,
        name: retail.retail_outlet_name,
      }
    }
    return {
      expected_amount: virtualAccount.expected_amount,
      expiration_date: virtualAccount.expiration_date,
      code: virtualAccount.account_number,
      name: virtualAccount.bank_code,
    }
  }, [retail, virtualAccount])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentDate(new Date())
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [currentDate])

  useEffect(() => {
    setTimeDiff(calcTimeDiff(currentDate, details.expiration_date))
  }, [currentDate, details.expiration_date])

  const handleRenderInfo = useMemo(
    () =>
      paymentData && (
        <PaymentCheckoutUserInfo
          currentDate={paymentData.date}
          expirationDate={details.expiration_date}
          timeDiff={timeDiff}
          expectedAmount={details.expected_amount}
          username={username}
        />
      ),

    [
      details.expected_amount,
      details.expiration_date,
      paymentData,
      timeDiff,
      username,
    ],
  )

  const handleCheckTransaction = useCallback(() => {
    history.replace('checkout_status', {
      invoiceId: paymentData.invoiceId,
      contentData: paymentData.contentData,
      paymentMethodId: paymentData.paymentMethodId!,
      paymentType: paymentData.paymentType,
      expiredDate: details.expiration_date,
    })
  }, [details, history, paymentData])

  useEffect(() => {
    if (timeDiff.hours < 0 && timeDiff.minutes < 0 && timeDiff.seconds < 0) {
      history.push('checkout_status', {
        invoiceId: paymentData.invoiceId,
        contentData: paymentData.contentData,
        paymentMethodId: paymentData.paymentMethodId!,
        paymentType: paymentData.paymentType,
        expiredDate: details.expiration_date,
      })
    }
  }, [details, history, paymentData, timeDiff])

  const typeDetails = useCallback(() => {
    switch (paymentData.paymentType) {
      case 'Retail': {
        return {
          title:
            paymentData.paymentMethod.charAt(0) +
            paymentData.paymentMethod.slice(1).toLowerCase(),
          container: (
            <StyledDescriptionContainer>
              <StyledTitle>
                {translate('payment:retailDescription')}
              </StyledTitle>
              <StyledTitle>
                {translate('payment:retailDescription', {
                  context: 'second',
                  Amount: getCurrencyValue(details.expected_amount),
                })}
              </StyledTitle>
              <StyledTitle>
                {translate('payment:retailDescription', {
                  context: 'third',
                })}
              </StyledTitle>
              <StyledTitle>
                {translate('payment:retailDescription', {
                  context: 'fourth',
                })}
              </StyledTitle>
            </StyledDescriptionContainer>
          ),
        }
      }
      case 'Virtual Account':
      default: {
        return {
          title: translate('global:virtualAccountMethod', {
            method: paymentData.paymentMethod,
          }),
          container: (
            <PaymentDetailInstructions
              bank={paymentData.paymentMethod}
              accountNumber={virtualAccount.account_number}
            />
          ),
        }
      }
    }
  }, [details, paymentData, translate, virtualAccount])

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(details.code)
    showSnackbar(translate('global:copied'))
  }, [details.code, translate])

  const handleAccountDetail = useMemo(
    () =>
      paymentData && (
        <StyledRightSection mode={mode}>
          <StyledTitleContainer>
            <StyledTitle fontSize="m" fontWeight="medium">
              {typeDetails().title}
            </StyledTitle>
            <StyledLogo src={paymentData.logo} alt={paymentData.logo} />
          </StyledTitleContainer>
          <StyledTitleContainer>
            <Paragraph fontSize="l" fontWeight="bold" color="primary_5">
              {details.code}
            </Paragraph>
            <StyledCopyParagraph
              fontSize="m"
              fontWeight="medium"
              color="primary_5"
              onClick={copyToClipboard}>
              {translate('global:copy')}
            </StyledCopyParagraph>
          </StyledTitleContainer>
          <StyledMessageContainer>
            <Icon
              type="info"
              size={16}
              style={{
                alignSelf: 'center',
                marginRight: convertUnit(16),
              }}
            />
            <Paragraph
              fontSize="s"
              fontWeight="medium"
              style={{alignSelf: 'center', lineHeight: convertUnit(21)}}>
              {translate('payment:transactionVANumberReminder')}
            </Paragraph>
          </StyledMessageContainer>
          <StyledInstructionTitle fontSize="m" fontWeight="medium">
            {translate('payment:paymentInstruction')}
          </StyledInstructionTitle>
          {typeDetails().container}
          <StyledButton
            label={translate('payment:checkTransaction')}
            onClick={handleCheckTransaction}
          />
        </StyledRightSection>
      ),
    [
      copyToClipboard,
      details.code,
      handleCheckTransaction,
      mode,
      paymentData,
      translate,
      typeDetails,
    ],
  )

  return (
    <StyledContainer mode={mode}>
      {handleRenderInfo}
      {handleAccountDetail}
      <ModalLoading visible={loading} />
    </StyledContainer>
  )
}
