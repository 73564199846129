import convertUnit from 'lib/unit'
import React, {Fragment, ReactElement, useMemo} from 'react'
import styled, {CSSProperties} from 'styled-components'
import {AutoLayoutProps} from './AutoLayoutProps'

const StyledContainer = styled.div<CSSProperties>`
  ${({flexDirection}) => ({flexDirection})}
  display: flex;
`

const StyledSeparator = styled.div<CSSProperties>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default function AutoLayout({
  direction = 'vertical',
  reverse = false,
  distance,
  style,
  children,
  separatorElement,
}: AutoLayoutProps) {
  const data = useMemo<ReactElement[]>(
    () => (Array.isArray(children) ? children : [children]),
    [children],
  )

  const flexDirection = useMemo<CSSProperties['flexDirection']>(() => {
    if (direction === 'vertical') {
      return reverse ? 'column-reverse' : 'column'
    }

    return reverse ? 'row-reverse' : 'row'
  }, [direction, reverse])

  const styleSeparator = useMemo<CSSProperties>(
    () =>
      direction === 'vertical'
        ? {height: convertUnit(distance)}
        : {width: convertUnit(distance)},
    [direction, distance],
  )

  const handleRenderSeparator = useMemo(
    () => (
      <StyledSeparator style={styleSeparator}>
        {separatorElement}
      </StyledSeparator>
    ),
    [separatorElement, styleSeparator],
  )

  const handleRenderChildren = useMemo(
    () =>
      data.map((child, index) => (
        <Fragment key={index.toString()}>
          {!!index && handleRenderSeparator}
          {child}
        </Fragment>
      )),
    [data, handleRenderSeparator],
  )

  return (
    <StyledContainer style={style} flexDirection={flexDirection}>
      {handleRenderChildren}
    </StyledContainer>
  )
}
