import React from 'react'
import {getWindowPlatform, postWindowParentMessage} from 'utils'
import {AppUpdateModal, PaymentErrorModal} from '../containers'

export default function PaymentCheckoutUpdateAppScreen() {
  const platform = getWindowPlatform()
  return platform === 'web' ? (
    <PaymentErrorModal
      visible
      onClick={() => postWindowParentMessage({type: 'close'})}
    />
  ) : (
    <AppUpdateModal />
  )
}
