import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useWindowMode} from 'windows'
import {SnackbarHandler, SnackbarState, WindowModeType} from 'types'
import convertUnit from 'lib/unit'
import {getUniqueId, setSnackbarHandler} from 'utils'
import {SnackbarProviderProps} from './SnackbarProps'
import SnackbarItem from './SnackbarItem'
import {SnackbarContext} from './SnackbarContext'

interface SnackbarContainerProps {
  mode: WindowModeType
}

const StyledSnackbarContainer = styled.div<SnackbarContainerProps>`
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({mode}) =>
    mode === 'website'
      ? {
          bottom: convertUnit('25px'),
          left: convertUnit('25px'),
          width: convertUnit('500px'),
        }
      : {
          width: '100%',
          bottom: convertUnit('15px'),
        }};
`

export default function SnackbarProvider({children}: SnackbarProviderProps) {
  const mode = useWindowMode()
  const [snackbars, setSnackbars] = useState<SnackbarState[]>([])

  const handleShowSnackbar = useCallback(
    (message: string) =>
      setSnackbars((previous) => [...previous, {id: getUniqueId(), message}]),
    [],
  )

  const handleRemoveSnackbar = useCallback(
    (id: string) =>
      setSnackbars((previous) => previous.filter((item) => item.id !== id)),
    [setSnackbars],
  )

  const handleRenderSnackbars = useMemo(
    () => (
      <StyledSnackbarContainer mode={mode}>
        {snackbars.map((item) => (
          <SnackbarItem
            {...item}
            key={item.id}
            onDelete={() => handleRemoveSnackbar(item.id)}
          />
        ))}
      </StyledSnackbarContainer>
    ),
    [snackbars, mode, handleRemoveSnackbar],
  )

  const handler = useMemo<SnackbarHandler>(
    () => ({
      snackbars,
      showSnackbar: handleShowSnackbar,
      removeSnackbar: handleRemoveSnackbar,
    }),
    [snackbars, handleRemoveSnackbar, handleShowSnackbar],
  )

  useEffect(() => {
    setSnackbarHandler(handler)
  }, [handler])

  return (
    <SnackbarContext.Provider value={handler}>
      {handleRenderSnackbars}
      {children}
    </SnackbarContext.Provider>
  )
}
