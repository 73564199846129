import React, {createElement, useMemo} from 'react'
import {
  BrowserRouter,
  BrowserRouterProps,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import {Routes} from 'types'
import {getRoutePath} from 'utils'
import {ROUTE_MAP_PAYMENT} from './payment'
import {RouteMap, RouteMapEntry} from './RouteMap'

interface RouteSwitchProps<T> {
  map: RouteMap<T>
  relativePath?: string
  redirectPath?: string
}

interface RouterProps<T> extends BrowserRouterProps {
  basename: Routes
  map: RouteMap<T>
}

function RouteSwitch<T>({
  relativePath = '',
  redirectPath,
  map,
}: RouteSwitchProps<T>) {
  return (
    <Switch>
      {Object.entries<RouteMapEntry<any>>(map).map(
        ([route, {path, component, nested, exact = true}]) => (
          <Route key={route} path={`${relativePath}${path}`} exact={exact}>
            {createElement(
              component,
              undefined,
              nested ? (
                <RouteSwitch map={nested} relativePath={path} />
              ) : undefined,
            )}
          </Route>
        ),
      )}
      {redirectPath && <Redirect to={redirectPath} />}
    </Switch>
  )
}

function Router<T>({basename, map}: RouterProps<T>) {
  const base = useMemo(() => getRoutePath(basename), [basename])

  return (
    <BrowserRouter>
      <RouteSwitch map={map} redirectPath={base} />
    </BrowserRouter>
  )
}

export default function RouteStack() {
  return <Router basename="checkout_method" map={ROUTE_MAP_PAYMENT} />
}
