import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  IMAGE_ASSET,
  PAYMENT_CREDIT_TOKENIZATION_URL,
  REGEX_15JCB,
  REGEX_16JCB,
  REGEX_MASTERCARD,
  REGEX_NUMBER,
  REGEX_VISA,
} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  CardType,
  CreditCardDataType,
  CreditCardPaymentFormData,
  GetCreditCardBody,
  GetCreditCardTokenResponse,
  PaymentCheckoutResponse,
} from 'types'
import {
  getCurrencyValue,
  getXenditAPIKey,
  showSnackbar,
  useDidMount,
  useHistory,
  useLocation,
} from 'utils'
import {Paragraph, Button, Input, Image, Icon} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {
  VALIDATION_INPUT_CVV,
  VALIDATION_INPUT_EXPIRY_DATE,
  VALIDATION_INPUT_REQUIRED,
} from 'common/validations/input'
import {PaymentCheckoutInfoItem} from '../InfoItem'

interface StyledDisabled {
  enabled: boolean
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${convertUnit(25)};
  background-color: ${({theme}) => theme.white_1};
`

const StyledInputLabel = styled(Paragraph)`
  margin-top: ${convertUnit(25)};
`

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const StyledColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const StyledLogo = styled(Image)<StyledDisabled>`
  ${({enabled}) => ({opacity: enabled ? 1 : 0.6})}
  display: inline-block;
  object-fit: contain;
  margin: ${convertUnit(5)};
  height: ${convertUnit(25)};
  width: ${convertUnit(30)};
`

const StyledButton = styled(Button)`
  display: flex;
  margin: ${convertUnit(5)};
  flex: 1;
`

const StyledButtonContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  margin-top: ${convertUnit(25)};
  width: 100%;
`

const StyledInfoItem = styled(PaymentCheckoutInfoItem)`
  margin-top: ${convertUnit(10)};
`

const StyledInfoContainer = styled.div`
  ${({theme}) => ({
    backgroundColor: theme.white_3,
  })}
  border-radius: ${convertUnit(8)};
  margin-top: ${convertUnit(10)};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: ${convertUnit(8)} ${convertUnit(16)};
  line-height: ${convertUnit(21)};
`
const StyledAppliedPromo = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: ${convertUnit(8)} ${convertUnit(20)};
  border: ${convertUnit(1)} solid ${({theme}) => theme.gray_1};
  border-radius: ${convertUnit(8)};
  align-items: center;
  margin-top: ${convertUnit(10)};
  gap: ${convertUnit(8)};
  user-select: none;
  justify-content: space-between;
`
const StyledDiscount = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledCheckContainer = styled.div`
  display: flex;
  height: ${convertUnit(20)};
  width: ${convertUnit(20)};
`
const StyledInvalidPromo = styled(StyledAppliedPromo)`
  background-color: ${({theme}) => theme.danger_1};
  border: ${convertUnit(0)};
`
export default function PaymentCheckoutCreditTransaction() {
  const {translate} = useTranslation()
  const {voucherError} = useSelector('paymentErrorState')
  const history = useHistory()
  const {
    token: checkout_token,
    contentData,
    selectedPaymentMethod,
    voucher,
  } = useLocation('checkout_credit_transaction').state
  const visaImage = IMAGE_ASSET('icons', 'visa.png')
  const masterImage = IMAGE_ASSET('icons', 'mastercard.png')
  const jcbImage = IMAGE_ASSET('icons', 'jcb.png')
  const [inputMonth, setInputMonth] = useState(0)
  const [inputYear, setInputYear] = useState(0)
  const [inputCardNumber, setInputCardNumber] = useState('')
  const [data, setData] = useState<PaymentCheckoutResponse>()
  const form = useForm<CreditCardPaymentFormData>()
  const {watch, errors} = form
  const values = watch()
  const {ccCard, cvv, expiryDate} = values
  const validPromo = useMemo(
    () =>
      ccCard
        ? voucher?.bin && voucher.bin !== null
          ? ccCard.replace(' ', '').startsWith(voucher?.bin)
          : true
        : false,
    [ccCard, voucher?.bin],
  )

  const cardData: CreditCardDataType = useMemo(
    () => ({
      account_number: inputCardNumber.replaceAll(' ', ''),
      cvn: cvv,
      exp_month: inputMonth.toString().padStart(2, '0'),
      exp_year: inputYear.toString(),
    }),
    [cvv, inputCardNumber, inputMonth, inputYear],
  )

  const discount = useMemo(
    () =>
      voucher
        ? voucher?.discount_type === 'flat'
          ? voucher?.discount
          : (data?.total_price || 0) * (voucher.discount / 100) >
            (voucher.max_discount_amount || 0)
          ? voucher.max_discount_amount || 0
          : (data?.total_price || 0) * (voucher.discount / 100)
        : 0,
    [voucher, data?.total_price],
  )

  const totalPrice = useMemo(
    () =>
      (data?.total_price || 0) -
      (validPromo ? discount : 0) +
      (selectedPaymentMethod?.extra_fee || 0),
    [data?.total_price, discount, selectedPaymentMethod?.extra_fee, validPromo],
  )

  const submitCardData: GetCreditCardBody = useMemo(
    () => ({
      amount: totalPrice.toString(),
      card_data: cardData,
      is_single_use: true,
      should_authenticate: true,
    }),
    [cardData, totalPrice],
  )

  const creditCardBrand: CardType = useMemo(() => {
    if (REGEX_VISA.test(ccCard)) {
      return 'Visa'
    }
    if (REGEX_MASTERCARD.test(ccCard)) {
      return 'Mastercard'
    }
    if (REGEX_16JCB.test(ccCard) || REGEX_15JCB.test(ccCard)) {
      return 'JCB'
    }
    return 'Unknown'
  }, [ccCard])

  const handleLoadData = useCallback(async () => {
    const responseData = await requestData('payment_get_token', {
      params: {token: checkout_token},
    })
    setData(
      typeof responseData !== 'string' ? responseData.data.result : undefined,
    )
  }, [checkout_token])

  const handleTokenization = useCallback(() => {
    const basicToken = btoa(`${getXenditAPIKey()}:`)
    fetch(PAYMENT_CREDIT_TOKENIZATION_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${basicToken}`,
      },
      body: JSON.stringify(submitCardData),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status.toString())
        } else {
          return response.json()
        }
      })
      .then((datas: GetCreditCardTokenResponse) => {
        history.push('checkout_credit_secure', {
          token: checkout_token,
          authenticationUrl: datas.payer_authentication_url,
          contentData,
          selectedPaymentMethod,
          voucher: validPromo ? voucher : undefined,
          bin:
            voucher?.bin !== null
              ? cardData.account_number.substring(0, voucher?.bin.length)
              : '',
        })
      })
      .catch((error) => {
        const status = error.message
        showSnackbar(
          translate('payment:cardErrorMessage', {
            context: status,
          }),
        )
      })
  }, [
    voucher,
    cardData.account_number,
    checkout_token,
    contentData,
    history,
    selectedPaymentMethod,
    submitCardData,
    translate,
    validPromo,
  ])

  const formatCcNumber = useMemo(() => {
    if (ccCard) {
      const cardDigits = ccCard.replace(/\D/g, '')

      let formattedInput = ''
      for (let i = 0; i < cardDigits.length; i += 4) {
        formattedInput += `${cardDigits.slice(i, i + 4)} `
      }

      if (!(cardDigits.length > 16)) {
        setInputCardNumber(formattedInput.trim())
        return formattedInput.trim()
      }
      return inputCardNumber
    }
    return ''
  }, [ccCard, inputCardNumber])

  const formatExpiryDate = useMemo(() => {
    if (expiryDate) {
      const expiryDigits = expiryDate.replace(/\D/g, '')

      let formattedInput = expiryDigits

      if (expiryDigits.length > 2) {
        formattedInput = `${expiryDigits.slice(0, 2)} / ${expiryDigits.slice(
          2,
          4,
        )}`
      }
      return formattedInput
    }
    return ''
  }, [expiryDate])

  const validateExpiryDate = useMemo(() => {
    if (expiryDate) {
      const currentDate = new Date()
      const month = parseInt(expiryDate.split(' / ')[0], 10)
      setInputMonth(month)
      const year = parseInt(`20${expiryDate.split(' / ')[1]}`, 10)
      setInputYear(year)

      if (isNaN(month) || isNaN(year)) {
        return false
      }

      const expiredDate = new Date(year, month - 1, 1)

      return expiredDate > currentDate
    }
    return true
  }, [expiryDate])

  const handleRenderPromo = useMemo(
    () =>
      voucher ? (
        <StyledAppliedPromo>
          <StyledDiscount>
            <Paragraph fontSize="m" fontWeight="bold">
              {translate('payment:checkoutPromoTitle', {
                context: voucher.discount_type,
                amount:
                  voucher.discount_type === 'flat'
                    ? getCurrencyValue(voucher.discount)
                    : voucher.discount,
              })}
            </Paragraph>
            <Paragraph
              fontWeight="medium"
              color={validPromo ? 'success_6' : 'danger_6'}>
              {translate('payment:checkoutPromoDescription', {
                method:
                  voucher.method_variant_name || voucher.payment_method_name,
                payment: getCurrencyValue(voucher.min_transaction_amount),
              })}
            </Paragraph>
          </StyledDiscount>
          <StyledCheckContainer>
            <Icon
              type={validPromo ? 'check' : 'not-interested'}
              color={validPromo ? 'success_5' : 'danger_5'}
            />
          </StyledCheckContainer>
        </StyledAppliedPromo>
      ) : (
        <></>
      ),
    [voucher, translate, validPromo],
  )

  const handleRenderPromoInvalid = useMemo(
    () =>
      voucher &&
      !validPromo && (
        <StyledInvalidPromo>
          <Paragraph color="danger_5" fontWeight="medium">
            {translate('payment:checkoutPromoRemovedIncorrectCard', {
              method:
                voucher.method_variant_name || voucher.payment_method_name,
            })}
          </Paragraph>
        </StyledInvalidPromo>
      ),
    [voucher, translate, validPromo],
  )

  useDidMount(() => {
    handleLoadData()
  })

  return (
    <StyledContainer>
      <StyledInfoItem
        title={translate('payment:checkoutContentTotalPayment')}
        description={getCurrencyValue(totalPrice)}
        totalPrice
        boldDescription
      />
      <StyledInputLabel fontWeight="bold" fontSize="m">
        {translate('payment:checkoutCardNumber')}
      </StyledInputLabel>
      <Input
        name="ccCard"
        form={form}
        formRules={VALIDATION_INPUT_REQUIRED}
        value={formatCcNumber}
        allowedCharacters={REGEX_NUMBER}
        placeholder={translate('payment:checkoutCardNumberPlaceHolder')}
        containerStyle={{
          width: '100%',
        }}
        maxLength={20}
      />
      <StyledRowContainer>
        <StyledLogo
          enabled={creditCardBrand === 'Visa'}
          src={visaImage}
          alt={visaImage}
        />
        <StyledLogo
          enabled={creditCardBrand === 'Mastercard'}
          src={masterImage}
          alt={masterImage}
        />
        <StyledLogo
          enabled={creditCardBrand === 'JCB'}
          src={jcbImage}
          alt={jcbImage}
        />
      </StyledRowContainer>
      <StyledRowContainer>
        <StyledColumnContainer>
          <StyledInputLabel fontWeight="bold" fontSize="m">
            {translate('payment:checkoutCardExpiredDate')}
          </StyledInputLabel>
          <Input
            name="expiryDate"
            form={form}
            formRules={VALIDATION_INPUT_EXPIRY_DATE(
              inputMonth,
              validateExpiryDate,
            )}
            allowedCharacters={REGEX_NUMBER}
            placeholder={translate(
              'payment:checkoutCardExpiredDatePlaceHolder',
            )}
            containerStyle={{
              width: convertUnit(95),
            }}
            value={formatExpiryDate}
          />
        </StyledColumnContainer>
        <StyledColumnContainer style={{marginLeft: convertUnit(20)}}>
          <StyledInputLabel fontWeight="bold" fontSize="m">
            {translate('payment:checkoutCardCVV')}
          </StyledInputLabel>
          <Input
            name="cvv"
            form={form}
            formRules={VALIDATION_INPUT_CVV}
            allowedCharacters={REGEX_NUMBER}
            placeholder={translate('payment:checkoutCardCVV')}
            containerStyle={{
              width: convertUnit(110),
            }}
            rightIcon="visibility"
            maxLength={3}
          />
        </StyledColumnContainer>
      </StyledRowContainer>
      <StyledInfoContainer>
        <Icon type="info" size={16} marginRight={convertUnit(10)} />
        <Paragraph fontSize="s" fontWeight="medium">
          {translate('payment:checkoutCardCVVMessage')}
        </Paragraph>
      </StyledInfoContainer>
      {handleRenderPromo}
      {handleRenderPromoInvalid}
      <StyledButtonContainer>
        <StyledButton
          label={translate('global:back')}
          onClick={() => history.goBack()}
          backgroundColor="white_3"
          color="primary_5"
        />
        <StyledButton
          label={translate('payment:checkoutPhoneButtonLabel')}
          disabled={Object.keys(errors).length > 0 || voucherError}
          onClick={handleTokenization}
        />
      </StyledButtonContainer>
    </StyledContainer>
  )
}
