import React from 'react'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import styled from 'styled-components'
import {RouteStack} from './routes'
import {ThemeProvider} from './themes'
import {WindowProvider} from './windows'
import {DownTimeModalProvider} from './context'
import {SnackbarProvider} from './common/components'
import {REDUX_STORE, REDUX_PERSISTOR} from './lib/redux'

import(/* webpackChunkName: 'RegisterSW' */ './register-sw')

const StyledRoot = styled.div`
  ${({theme}) => ({backgroundColor: theme.white_1})}
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export default function App() {
  return (
    <WindowProvider>
      <Provider store={REDUX_STORE}>
        <PersistGate persistor={REDUX_PERSISTOR}>
          <ThemeProvider>
            <SnackbarProvider>
              <DownTimeModalProvider>
                <StyledRoot>
                  <RouteStack />
                </StyledRoot>
              </DownTimeModalProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </WindowProvider>
  )
}
