import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {SNACKBAR_DURATION} from 'consts'
import {useTranslation} from 'i18n'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {Paragraph} from '../Paragraph'
import {SnackbarItemProps} from './SnackbarItemProps'

interface StyledContainerProps {
  mode: WindowModeType
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({mode, theme}) => ({
    backgroundColor: theme.gray_8,
    width: mode === 'mobile' ? '80%' : convertUnit(511),
  })};
  margin-bottom: ${convertUnit(10)};
  min-height: ${convertUnit(30)};
  max-height: ${convertUnit(100)};
  overflow: hidden;
  padding: ${convertUnit(15)} ${convertUnit(20)};
  display: flex;
  align-items: center;
  border-radius: ${convertUnit(8)};
`

const StyledAction = styled(Paragraph)`
  cursor: pointer;
  margin-left: auto;
  text-transform: uppercase;
`

export default function SnackbarItem({message, onDelete}: SnackbarItemProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const [tick, setTick] = useState(0)
  const [isTicking, setIsTicking] = useState(true)

  const handleStartTimer = useCallback(() => {
    if (isTicking) {
      if (tick < SNACKBAR_DURATION) {
        const timeout = setTimeout(() => setTick((prev) => prev + 1000), 1000)

        return () => clearTimeout(timeout)
      }

      onDelete()
    }

    return undefined
  }, [isTicking, tick, onDelete])

  useEffect(handleStartTimer, [handleStartTimer])

  return (
    <StyledContainer
      mode={mode}
      onMouseEnter={() => setIsTicking(false)}
      onMouseLeave={() => setIsTicking(true)}>
      <Paragraph
        fontSize={mode === 'mobile' ? 's' : 'm'}
        color="gray_1"
        style={{maxWidth: '80%'}}>
        {message}
      </Paragraph>
      <StyledAction
        fontSize={mode === 'mobile' ? 's' : 'm'}
        color="primary_3"
        fontWeight="medium"
        onClick={onDelete}>
        {translate('global:close')}
      </StyledAction>
    </StyledContainer>
  )
}
