import {
  PaymentCheckoutCreditScreen,
  PaymentCheckoutCreditSecure,
  PaymentCheckoutDetailScreen,
  PaymentCheckoutEwalletDetailScreen,
  PaymentCheckoutPhoneScreen,
  PaymentCheckoutStatusScreen,
  PaymentCheckoutTransactionScreen,
  PaymentCheckoutUpdateAppScreen,
} from 'pages'
import {RouteMap} from '../RouteMap'
import {RouteParamPayment} from './RouteParamPayment'

export const ROUTE_MAP_PAYMENT: RouteMap<RouteParamPayment> = {
  checkout_phone_transaction: {
    path: '/phone/transaction',
    component: PaymentCheckoutPhoneScreen,
    exact: true,
  },
  checkout_credit_transaction: {
    path: '/credit/transaction',
    component: PaymentCheckoutCreditScreen,
    exact: true,
  },
  checkout_credit_secure: {
    path: '/credit/secure',
    component: PaymentCheckoutCreditSecure,
    exact: true,
  },
  checkout_details: {
    path: '/details',
    component: PaymentCheckoutDetailScreen,
    exact: true,
  },
  checkout_ewallet_details: {
    path: '/details/ewallet',
    component: PaymentCheckoutEwalletDetailScreen,
    exact: true,
  },
  checkout_status: {
    path: '/status',
    component: PaymentCheckoutStatusScreen,
    exact: true,
  },
  checkout_method: {
    path: '/checkout',
    component: PaymentCheckoutTransactionScreen,
  },
  update_app: {
    path: '/',
    component: PaymentCheckoutUpdateAppScreen,
  },
}
