import {TranslationKeyPayment} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_PAYMENT_ID: TranslationResource<TranslationKeyPayment> = {
  translation: {
    'payment:appUpdate': 'Versi Baru Tersedia',
    'payment:appUpdateDesc': 'Mohon perbarui aplikasi kamu ke versi terbaru.',
    'payment:cancelPayment': 'Batal Pembayaran',
    'payment:cardErrorMessage':
      'Terjadi kesalahan saat mengambil data. Silakan coba lagi.',
    'payment:cardErrorMessage_400':
      'Nomor kartu kredit tidak valid. Silakan coba lagi.',
    'payment:cardErrorMessage_500':
      'Terjadi kesalahan saat menyambung ke server kami. Silakan coba lagi nanti.',
    'payment:cardErrorMessage_503':
      'Ada masalah dengan jaringan kartu kredit. Silakan coba lagi nanti.',
    'payment:changePaymentMethod': 'Ubah Metode Pembayaran',
    'payment:checkTransaction': 'Cek Transaksi',
    'payment:checkoutAccountName': 'Nama Akun',
    'payment:checkoutCardNumber': 'Nomor Kartu',
    'payment:checkoutCardNumberPlaceHolder': '16 digit nomor',
    'payment:checkoutCardCVV': 'CVV',
    'payment:checkoutCardCVVMessage':
      'CVV adalah angka tiga digit dan biasanya muncul di bagian belakang kartu',
    'payment:checkoutCardExpiredDate': 'Tanggal Kadaluarsa',
    'payment:checkoutCardExpiredDatePlaceHolder': 'MM / YY',
    'payment:checkoutContentDoNotAutopost': 'Tolong tidak repost',
    'payment:checkoutContentExpand': 'Tampilkan Semua',
    'payment:checkoutContentPrice': 'Harga',
    'payment:checkoutContentTotalPayment': 'Total Pembayaran',
    'payment:checkoutContentTotalPrice': 'Harga Total',
    'payment:checkoutFreeDescription': 'Konten ini gratis!',
    'payment:checkoutMethod': 'Pilih Metode Pembayaran',
    'payment:checkoutPhoneButtonLabel': 'Bayar',
    'payment:checkoutPhoneTitle': 'Pembayaran {{method}}',
    'payment:checkoutPhoneDescription':
      'Pastikan nomor yang kamu masukkan terdaftar di {{method}}.',
    'payment:checkoutPhoneTotalPrice': 'Harga Total:',
    'payment:checkoutPromo': 'Gunakan Promo Spesial',
    'payment:checkoutPromoDescription': '({{method}}) Min. {{payment}}',
    'payment:checkoutPromoEmpty': 'Tidak Ada Voucher Tersedia',
    'payment:checkoutPromoList': 'Daftar Promo',
    'payment:checkoutPromoQtyLeft': '{{amount}} yuser saja',
    'payment:checkoutPromoMaxDiscount': 'Diskon hingga {{amount}}',
    'payment:checkoutPromoRemoved':
      'Maaf! Sepertinya voucher belum berhasil diterapkan.',
    'payment:checkoutPromoRemovedIncorrectCard':
      'Agar promo berlaku, pastikan metode pembayaran yang kamu gunakan adalah "{{method}}".',
    'payment:checkoutPromoTimeLeft': '{{time}} jam',
    'payment:checkoutPromoTimeLeft_minutes': '{{time}} menit',
    'payment:checkoutPromoTimeStart': 'Mulai dalam {{time}} jam',
    'payment:checkoutPromoTimeStart_minutes': 'Mulai dalam {{time}} menit',
    'payment:checkoutPromoTitle': 'Diskon {{amount}}%',
    'payment:checkoutPromoTitle_flat': 'Diskon {{amount}}',
    'payment:checkoutTotalPriceValidation':
      'Minimum pembayaran untuk metode yang dipilih adalah {{min}} dan maksimum {{max}}',
    'payment:checkoutTransactionDate': 'Tanggal Transaksi',
    'payment:checkoutTransactionTitle': 'Detail Transaksi',
    'payment:checkoutTransactionType': 'Jenis Transaksi',
    'payment:checkoutWaitForHiresInfoDesc1':
      'Hi-Res akan muncul di “Koleksi” di tab “FotoYu”.',
    'payment:checkoutWaitForHiresInfoDesc2':
      'Kamu juga dapat mengobrol dengan kreator penjual, dengan mengklik ikon pesan di bawah setiap konten yang kamu beli.',
    'payment:checkoutWaitForHiresInfoDesc3':
      'Kreator harus mengirimkan Hi-Res dalam 48 jam, atau kamu akan menerima pengembalian dana.',
    'payment:checkoutWaitForHiresInfoTitle': 'Mohon tunggu versi Hi-Res.',
    'payment:chooseMethod': 'Pilih Metode Ini',
    'payment:eWalletInstruction':
      'Jika kamu memiliki aplikasi {{app}} di ponsel ini, kamu dapat menekan tombol di bawah ini untuk melanjutkan pembayaran di aplikasi {{app}}.',
    'payment:eWalletInstruction_QRWeb':
      'Silahkan scan QR code di bawah ini menggunakan fitur scan {{method}} di aplikasi {{app}}.',
    'payment:eWalletInstruction_QRMobile':
      'Jika tidak, silakan scan QR code di bawah ini menggunakan fitur pemindaian {{method}} di aplikasi {{app}} di ponsel kamu.',
    'payment:eWalletInstruction_complete':
      'Ketika proses selesai, pembayaran akan diproses secara otomatis.',
    'payment:methodFee': 'Biaya {{Amount}}',
    'payment:maxPayment': '(Max. pembayaran {{Amount}})',
    'payment:minPayment': '(Min. pembayaran {{Amount}})',
    'payment:paymentInstructionTimeLimit':
      'Transaksi akan dibatalkan secara otomatis setelah {{time}} menit',
    'payment:paymentInstruction': 'Instruksi Pembayaran',
    'payment:paymentDurationEnds': 'Durasi Pembayaran Berakhir',
    'payment:paymentTimeLeft': 'Sisa Waktu:',
    'payment:paymentVoucherError':
      'Maaf! Sepertinya voucher belum berhasil diterapkan. Silakan kembali dan coba lagi.',
    'payment:payWith': 'Bayar Menggunakan',
    'payment:retailDescription':
      '1. Sampaikan kepada kasir Alfamart/Alfamidi untuk melakukan pembayaran ke merchant PT. Super Giga Generasi (SUPER GIGA). \n2. Tunjukan kode pembayaran ke kasir dan lakukan pembayaran sebesar {{Amount}}. \n3.  Setelah transaksi berhasil, kamu akan mendapatkan bukti pembayaran. Mohon simpan bukti pembayaran sebagai jaminan apabila diperlukan verifikasi lebih lanjut. \n4. Pesanan kamu akan terverifikasi secara otomatis dalam aplikasi Fotoyu setelah pembayaran berhasil',
    'payment:retailDescription_second':
      '2.  Tunjukan kode pembayaran ke kasir dan lakukan pembayaran sebesar {{Amount}}.',
    'payment:retailDescription_third':
      '3. Setelah transaksi berhasil, kamu akan mendapatkan bukti pembayaran. Mohon simpan bukti pembayaran sebagai jaminan apabila diperlukan verifikasi lebih lanjut.',
    'payment:retailDescription_fourth':
      '4. Pesanan kamu akan terverifikasi secara otomatis dalam aplikasi Fotoyu setelah pembayaran berhasil',
    'payment:statusFailedButtonLabel': 'Coba Lagi',
    'payment:statusFailedDescription':
      'Ada masalah saat memproses pembayaran kamu.',
    'payment:statusFailedTitle': 'Gagal',
    'payment:statusFailedTitleLabel':
      'Pembayaran tidak dapat diproses saat ini, silakan coba lagi',
    'payment:statusFailedTitleButton': 'OK',
    'payment:statusOfflineButtonLabel': 'Coba Lagi',
    'payment:statusOfflineDescription':
      'Silakan periksa koneksi kamu, dan coba lagi.',
    'payment:statusOfflineTitle': 'Internet Offline',
    'payment:statusSuccessButtonLabel': 'Cek Transaksi',
    'payment:statusSuccessDescription':
      'Pembayaran kamu telah berhasil diproses.',
    'payment:statusSuccessFreeDescription':
      'Pembayaran kamu sebesar {{amount}} berhasil diproses.',
    'payment:statusSuccessFreeDescription_1': ' berhasil diproses.',
    'payment:statusSuccessTitle': 'Berhasil!',
    'payment:statusWaitingDescription': 'Silakan selesaikan proses pembayaran',
    'payment:statusWaitingDescriptionDana':
      'Silakan periksa dan selesaikan proses pembayaran DANA.',
    'payment:statusWaitingDescriptionCountdown':
      'Silakan periksa aplikasi OVO kamu dan lakukan pembayaran dalam waktu ({{Countdown}})',
    'payment:statusWaitingDescriptionDate':
      'Mohon untuk menyelesaikan pembayaran sebelum {{Date}}',
    'payment:statusWaitingReminder1':
      'Cek status pembayaran di riwayat transaksi pada: Halaman "Akun Saya" -> ikon dompet.',
    'payment:statusWaitingReminder2':
      'Harap menunggu 2 menit untuk pemrosesan.',
    'payment:statusWaitingTitle': 'Menunggu Pembayaran',
    'payment:transactionVANumberReminder':
      'Nomor VA ini hanya bisa dipakai sekali. Saat pembayaran, gunakan layanan bank yang sama dengan yang dipilih.',
    'payment:virtualiBankingDescription': '',
    'payment:virtualiBankingDescription_BCA':
      '1. Pilih Transfer Dana > Transfer ke BCA Virtual Account. \n2. Centang nomor Virtual Account dan masukkan {{Account}} dan pilih Lanjutkan. \n3.Periksa informasi yang tertera di layar. Pastikan Merchant adalah PT. Super Giga Generasi (SUPER GIGA), Total tagihan sudah benar. Jika benar pilih Ya.Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar pilih Lanjutkan. \n4. Masukkan respon KeyBCA kamu dan klik Kirim. ',
    'payment:virtualiBankingDescription_BNI':
      '1. Login ke i-Banking kamu. Pilih Transfer, kemudian pilih Virtual Account Billing. \n2. Masukkan nomor Virtual Account {{Account}}, kemudian pilih Lanjut. \n3. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, masukkan PIN kamu dan pilih OK.',
    'payment:virtualiBankingDescription_BRI':
      '1. Pilih menu Pembayaran. \n2. Pada kolom navigasi kiri, dalam menu Pembayaran, pilih BRIVA. \n3. Pilih rekening asal, lalu pilih Isi Kode Bayar dan masukkan nomor Virtual Account {{Account}} kemudian pilih Kirim. \n4. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, masukkan password iBanking and mToken kamu, kemudian klik Kirim.',
    'payment:virtualiBankingDescription_PERMATA':
      '1. Pilih Pembayaran Bayar Tagihan. \n2. Pilih Virtual Account. \n3. Masukkan kode bayar {{Account}}, kemudian pilih Lanjutkan. \n4. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, pilih Selanjutnya. \n6. Masukkan Kode Respon kamu, kemudian pilih Lanjutkan.',
    'payment:virtualiBankingDescription_MANDIRI':
      '1. Pilih Bayar > Multi Payment \n2. Pilih Dari Rekening: Rekening kamu dan Penyedia Jasa: XENDIT, lalu klik Lanjutkan. \n3. Masukkan nomor Virtual Account {{Account}} dan pilih Lanjutkan. \n4. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, centang tagihan dan klik Lanjutkan. \n5. Masukkan PIN token kamu dan klik Kirim.',
    'payment:virtualmBankingDescription': '',
    'payment:virtualmBankingDescription_BCA':
      '1.Pilih m-Transfer > BCA Virtual Account. \n2. Masukkan nomor Virtual Account {{Account}} dan pilih Send. \n3. Periksa informasi yang tertera di layar. Pastikan Merchant adalah PT. Super Giga Generasi (SUPER GIGA), Total tagihan sudah benar. Jika benar pilih Ya. \n4. Masukkan PIN m-BCA kamu dan pilih OK. ',
    'payment:virtualmBankingDescription_BNI':
      '1. Login ke m-Banking kamu. Pilih Transfer, kemudian pilih Virtual Account Billing. \n2. Masukkan nomor Virtual Account {{Account}}, kemudian pilih Lanjut. \n3. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, masukkan PIN kamu dan pilih OK.',
    'payment:virtualmBankingDescription_BRI':
      '1. Masuk ke menu Mobile Banking BRI. Kemudian, pilih Pembayaran > BRIVA. \n2. Masukkan Nomor BRIVA {{Account}} \n3. Masukkan PIN kamu kemudian pilih Send. Apabila pesan konfirmasi untuk transaksi menggunakan SMS muncul, pilih OK. Status transaksi akan dikirimkan melalui SMS dan dapat digunakan sebagai bukti pembayaran.',
    'payment:virtualmBankingDescription_PERMATA':
      '1. Pilih Pembayaran Bayar Tagihan \n2. Pilih Virtual Account. \n3. Masukkan kode bayar {{Account}}, kemudian pilih OK. \n4. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, pilih Selanjutnya. \n5. Pilih nomor rekening yang akan kamu gunakan. \n6. Masukkan PIN kamu, kemudian pilih Selanjutnya.',
    'payment:virtualmBankingDescription_MANDIRI': `1. Login ke mBanking kamu. Pilih IDR Transfer kemudian pilih Transfer to new recipient.
      \n2. Masukkan nomor Virtual Account {{Account}}, kemudian pilih Lanjut.
      \n3. Periksa informasi yang tertera di layar. Pastikan total tagihan sudah benar. Jika benar, masukkan PIN kamu dan pilih OK.`,
    'payment:virtualAtmDescription': '',
    'payment:virtualAtmDescription_BCA':
      '1.Pilih Transaksi Lainnya > Transfer > Ke Rek BCA Virtual Account. \n2. Masukkan kode perusahaan 7007015 / 7007115 / 7007215 dan pilih Benar. \n3. Masukkan nomor Virtual Account {{Account}} dan pilih Benar. \n4. Periksa informasi yang tertera di layar. Pastikan Merchant adalah PT. Super Giga Generasi (SUPER GIGA), Total tagihan sudah benar. Jika benar pilih Ya.',
    'payment:virtualAtmDescription_BNI':
      '1. Pilih Transfer. \n2. Pilih Giro/Tabungan. \n3. Pilih Virtual Account Billing. \n4. Masukkan nomor Virtual Account {{Account}} dan pilih Benar. \n5. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, tekan angka 1 dan pilih Ya. \n6. Periksa layar konfirmasi dan pilih Ya.',
    'payment:virtualAtmDescription_BRI':
      '1. Pilih Transaksi Lain > Pembayaran > Lainnya > BRIVA. \n2. Masukkan Nomor BRIVA {{Account}} kemudian pilih Benar. \n3. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, pilih Ya.',
    'payment:virtualAtmDescription_PERMATA':
      '1. Pilih Transaksi Lainnya. \n2. Pilih Pembayaran > Pembayaran Lainnya. \n3. Pilih Virtual Account. \n4. Masukkan kode bayar [VA Number], kemudian pilih Benar. \n5. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, pilih Benar.',
    'payment:virtualAtmDescription_MANDIRI':
      '1. Pilih Bayar/Beli. \n2. Pilih Lainnya > Lainnya > Multi Payment. \n3. Masukkan kode perusahaan 88608 / 88908 dan pilih Benar. \n4. Masukkan nomor Virtual Account {{Account}} dan pilih Benar. \n5. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, tekan angka 1 dan pilih Ya. \n6. Periksa layar konfirmasi dan pilih Ya.',
    'payment:virtualMiniAtmDescription_BRI':
      '1. Pilih Mini ATM > Pembayaran > BRIVA. \n2. Gesek kartu debit BRI kamu. \n3. Masukkan Nomor BRIVA {{Account}} kemudian tekan OK. \n4. Masukkan PIN kamu kemudian tekan OK. \n5. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar, kemudian pilih Lanjut. Apabila transaksi berhasil, maka bukti transfer akan tercetak.',
  },
}
