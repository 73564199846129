import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import {DropdownItemProps} from './DropdownItemProps'
import {Icon} from '../Icon'
import {Paragraph} from '../Paragraph'

interface StyledContainerProps {
  toggle: boolean
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({theme, toggle}) => ({
    borderColor: toggle ? theme.gray_3 : theme.gray_1,
    backgroundColor: toggle ? theme.gray_1 : theme.white_2,
    ...(!toggle && {
      ':hover': {
        borderColor: theme.gray_2,
      },
    }),
  })}
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  padding: ${convertUnit('11px')} ${convertUnit('16px')};
  border-style: solid;
  border-width: ${convertUnit('1px')};
  border-radius: ${convertUnit('8px')};
  align-items: center;
  :hover {
    cursor: default;
  }
`

const StyledLabel = styled(Paragraph)`
  margin-right: ${convertUnit('10px')};
`

export default function DropdownItem({
  label,
  toggle,
  ...props
}: DropdownItemProps) {
  return (
    <StyledContainer {...props} toggle={toggle}>
      <StyledLabel fontSize="m" fontWeight="medium">
        {label}
      </StyledLabel>
      <Icon type="chevron-down" size={16} />
    </StyledContainer>
  )
}
