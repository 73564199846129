// list of all units and their identifying string
const units = {
  dp: 'dp',
  pixel: 'px',
  sp: 'sp',
  mm: 'mm',
  inch: 'in',
  point: 'pt',
  remme: 'rem',
}

/**
 * @param  {any} unit
 * @return {number}
 */
const remmeUnit = (unit: any): number => {
  switch (unit) {
    case units.pixel:
      return 0.0625 // 1px = 0.0625rem
    case units.dp:
      return 3.89189189 // 1dp = 3.89189189rem
    case units.point:
      return 0.0833333963255 // 1pt = 0.0833333963255rem
    default:
      return 0.0625
  }
}

/**
 * @param  {any} unit
 * @return {number}
 */
const pixelUnit = (unit: any): number => {
  switch (unit) {
    case units.remme:
      return 16 // 1rem = 16pixel (base)
    case units.mm:
      return 6.3 // 1mm = 6.30pixel
    case units.inch:
      return 160 // 1inch = 160.00pixel
    case units.point:
      return 2.22 // 1point = 2.22pixel
    case units.dp:
    case units.sp:
    default:
      return 1
  }
}

/**
 * @param  {any} unit
 * @return {number}
 */
const dpUnit = (unit: any): number => {
  switch (unit) {
    case units.point:
    case units.pixel:
      return 2.22 // 1 px = 1 pt = 2.22 dp
    case units.inch:
      return 160 // 1 inch = 160.0 dp
    case units.mm:
      return 6.3 // 1 mm = 6.3 dp
    case units.remme:
    default:
      return 0.2 // 1 rem = 0.2 dp
  }
}

/**
 * @param  {any} unit
 * @return {number}
 */
const ptUnit = (unit: any): number => {
  switch (unit) {
    case units.remme:
      return 12 // 1 rem = 12 pt
    case units.mm:
      return 1.83 // 1 mm = 1.83 pt
    case units.inch:
      return 72 // 1 in = 72 pt
    case units.dp:
    case units.pixel:
    case units.sp:
    default:
      return 0.45
  }
}

/**
 * @param  {any} unit
 * @return {number}
 */
const spUnit = (unit: any): number => {
  switch (unit) {
    case units.remme:
      return 16 // 1 rem = 16px (base 16px)
    case units.point:
      return 2.22 // 1 pt = 2.22 sp
    case units.inch:
      return 160 // 1 inch = 160.00 sp
    case units.mm:
      return 6.3 // 1 mm = 6.30 sp
    case units.dp:
    case units.pixel:
    default:
      return 1
  }
}

/**
 * @param  {any} unit
 * @return {number}
 */
const mmUnit = (unit: any): number => {
  switch (unit) {
    case units.inch:
      return 25.4 // 1 inch = 25.40 mm
    case units.point:
      return 0.35 // 1 pt = 0.35 mm
    case units.pixel:
    case units.dp:
    case units.sp:
    default:
      return 0.16
  }
}

/**
 * @param  {any} unit
 * @return {number}
 */
const inchUnit = (unit: any): number => {
  switch (unit) {
    case units.mm:
      return 0.04 // 1 mm = 0.04 inch
    case units.dp:
    case units.pixel:
    case units.point:
    default:
      return 0.01
  }
}

/**
 * @param  {string | number} convertFrom
 * @param  {string|number} convertTo?
 * @param  {string} dpi?
 * @return {string}
 */
const convertUnit = (
  convertFrom: string | number,
  convertTo?: string | number,
  dpi?: string,
): string => {
  if (!dpi) dpi = 'mdpi'
  if (!convertTo) convertTo = 'rem'
  let unitResult = convertTo

  // check convertTo param is exists
  const checkUnit = Object.values(units).find((x) => x === convertTo)
  unitResult = checkUnit === undefined ? units.remme : convertTo
  const baseUnit: number = Number(
    convertFrom.toString().replace(/[^0-9.]+/g, ''),
  ) // get the numeric value
  let unitFrom: string = convertFrom.toString().replace(/[0-9.]/g, '') // get unit
  unitFrom = unitFrom.length === 0 ? 'px' : unitFrom
  let result = 0
  let addition = 1
  switch (unitResult) {
    case units.remme:
      // 1px = 0.0625rem with default pixel size is 16px
      addition = remmeUnit(unitFrom)
      break
    case units.dp:
      addition = dpUnit(unitFrom)
      break
    case units.pixel:
      addition = pixelUnit(unitFrom)
      break
    case units.sp:
      addition = spUnit(unitFrom)
      break
    case units.mm:
      addition = mmUnit(unitFrom)
      break
    case units.inch:
      addition = inchUnit(unitFrom)
      break
    case units.point:
      addition = ptUnit(unitFrom)
      break
  }
  result = baseUnit * addition
  return `${result}${unitResult}`
}

export default convertUnit
