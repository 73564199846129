import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import { CheckboxItemProps } from './CheckboxItemProps'
import { RadioItem } from '../RadioItem'

const StyledCheckboxItem = styled(RadioItem)`
  border-radius: ${convertUnit('5px')};
`

export default function CheckboxItem({
  ...props
}: CheckboxItemProps) {
  return <StyledCheckboxItem {...props} />
}
