import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {
  ServiceAPIRejectResponse,
  ServiceAPIResponse,
  ServiceConfig,
  ServiceError,
  ServiceHandlerValue,
} from 'types'
import {SERVICE_TIMEOUT, SERVICE_TIMEOUT_MESSAGE} from 'consts'
import {
  getMultiformData,
  getWindowPlatform,
  handleServiceResponseMessage,
  showDownTimeModal,
} from 'utils'
import {SERVICE_HANDLER} from './ServiceHandler'
import {SERVICE_MAP} from './ServiceMap'
import {ServiceParam} from './ServiceParam'
import {REDUX_STORE} from '../lib/redux/ReduxStore'

function handleRequestOnFulfilled(request: AxiosRequestConfig) {
  const token = REDUX_STORE.getState().userState?.access_token

  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  request.headers['X-Client'] = getWindowPlatform()

  return request
}

function handleServiceResponseInterceptor(response: AxiosResponse) {
  const {data, status} = response

  if (status === 403 && data.message === 'Feature Is Disabled') {
    showDownTimeModal(true)
  }

  return response
}

function createAxiosAPIInstance() {
  const axiosInstance = axios.create({
    timeout: SERVICE_TIMEOUT,
    timeoutErrorMessage: SERVICE_TIMEOUT_MESSAGE,
  })
  axiosInstance.interceptors.request.use(handleRequestOnFulfilled)

  return axiosInstance
}

function formatConfig<T extends keyof ServiceParam>(
  config?: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
) {
  if (config) {
    const {
      data,
      dataType,
      headers,
      headerContentType = 'application/json',
    } = config

    config.data = data
      ? dataType === 'form-data' && typeof data === 'object'
        ? getMultiformData(data as any)
        : data
      : undefined
    config.headers = {
      ...headers,
      'Content-Type': headerContentType,
    }
  }

  return config
}

function handleRequestFulfilled<T extends keyof ServiceParam>(
  _key: T,
  value: AxiosResponse<ServiceParam[T]['response']>,
  config?: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
) {
  const onRequestFulfilled = config?.onRequestFulfilled

  value = handleServiceResponseInterceptor(value) as AxiosResponse<
    ServiceParam[T]['response']
  >

  onRequestFulfilled && onRequestFulfilled(value)

  return value
}

function handleRequestRejected<T extends keyof ServiceParam>(
  _key: T,
  reason: ServiceAPIRejectResponse<
    ServiceParam[T]['response']
  > = 'unknown-error',
  config?: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
): AxiosResponse<ServiceParam[T]['response']> | ServiceError {
  if (typeof reason === 'object') {
    const {response, message} = reason

    if (response) {
      return handleRequestFulfilled(_key, response, config)
    }

    reason = message === SERVICE_TIMEOUT_MESSAGE ? 'timeout' : 'unknown-error'
  }

  return reason
}

function handleRequestResponse<T extends keyof ServiceParam>(
  key: T,
  response: ServiceAPIResponse<AxiosResponse<ServiceParam[T]['response']>>,
  config: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
) {
  const {
    actionType,
    useDefaultMessage,
    onRequestReceived,
    onRequestFailed,
    onRequestSuccess,
  } = config
  const handlerPreset = SERVICE_HANDLER[key] as ServiceHandlerValue | undefined

  onRequestReceived && onRequestReceived()
  useDefaultMessage &&
    (typeof response === 'string' || response.status >= 300) &&
    handleServiceResponseMessage(response, actionType)

  if (typeof response === 'string') {
    onRequestFailed && onRequestFailed(response)
  } else {
    onRequestSuccess && onRequestSuccess(response)
    handlerPreset && handlerPreset({response})
  }

  return response
}

const instance = createAxiosAPIInstance()

export async function requestData<T extends keyof ServiceParam>(
  key: T,
  config?: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
): Promise<AxiosResponse<ServiceParam[T]['response']> | ServiceError> {
  const response = navigator.onLine
    ? await instance
        .request<ServiceParam[T]['response']>({
          ...SERVICE_MAP[key],
          ...formatConfig(config),
        })
        .then(
          (value) => handleRequestFulfilled(key, value, config),
          (reason) => handleRequestRejected(key, reason),
        )
    : handleRequestRejected(key, 'no-connection')

  return handleRequestResponse(key, response, config || {})
}

export async function requestDataPayload<T extends keyof ServiceParam>(
  key: T,
  config?: ServiceConfig<
    ServiceParam[T]['params'],
    ServiceParam[T]['body'],
    ServiceParam[T]['response']
  >,
) {
  const response = await requestData(key, config)

  return typeof response !== 'string' ? response : null
}
