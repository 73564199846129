import daysjs from 'dayjs'

export function formatDate(ISOString: string, locale: string = 'en') {
  return Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(ISOString))
}

export function parseDate(d: string, format: string) {
  return daysjs(d).format(format)
}

export function formatHours(h: number, m: number, s: number) {
  if (h || m || s) {
    return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${
      s < 10 ? `0${s}` : s
    }`
  }
  return '00:00:00'
}

export function calcTimeDiff(dateA: Date, dateB: string) {
  const expDate = new Date(dateB).getTime()
  const currDate = dateA.getTime()
  const hours = Math.floor((expDate - currDate) / 3600000)
  const minutes = Math.floor(((expDate - currDate) / 60000) % 60)
  const seconds = Math.floor(((expDate - currDate) / 1000) % 60)
  return {hours, minutes, seconds}
}

export function calcAddMinutes(dateA: string, currDate: Date, minAdd: number) {
  const expiryDate = new Date(dateA).getTime() + minAdd * 60000
  const currentDate = currDate.getTime()
  const hours = Math.floor((expiryDate - currentDate) / 3600000)
  const minutes = Math.floor(((expiryDate - currentDate) / 60000) % 60)
  const seconds = Math.floor(((expiryDate - currentDate) / 1000) % 60)
  return {hours, minutes, seconds}
}
