import { AxiosResponse } from 'axios'
import i18n from 'i18next'
import { TranslationKey } from 'i18n'
import {
  ServiceAction,
  ServiceAPIResponse,
  ServiceError,
} from 'types'
import { showSnackbar } from '../components'

export function getServiceResponseMessage(
  error: ServiceError | number,
  action?: ServiceAction,
) {
  const context = typeof error === 'number' ? `code-${error}` : error
  const suffix = action ? `-${action}` : undefined
  const key: TranslationKey = 'global:messageError'
  const keySpecific = `${key}_${context}`
  const keyAction = `${key}${suffix}`
  const keyActionSpecific = `${keyAction}_${context}`

  return i18n.t([keyActionSpecific, keySpecific, keyAction, key])
}

export function handleServiceResponseMessage(
  response: ServiceAPIResponse<AxiosResponse<any>>,
  action?: ServiceAction,
) {
  showSnackbar(
    getServiceResponseMessage(
      typeof response === 'string' ? response : response.status,
      action,
    ),
  )
}
