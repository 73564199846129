import React, {
  CSSProperties,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import styled from 'styled-components'
import {
  isListMasonryHasEmptySpace,
  useListLazyMasonryAction,
} from 'utils'
import { ListMasonryBatchData } from 'types'
import {
  LIST_MASONRY_LOAD_LIMIT,
  LIST_MASONRY_MAX_ITEM,
  LIST_MASONRY_MAX_RATIO,
  LIST_MASONRY_MAX_RATIO_PER_ITEM,
  LIST_MASONRY_MIN_ITEM,
  LIST_MASONRY_MIN_RATIO_PER_ITEM,
} from 'consts'
import { ListLazyBase } from '../ListLazyBase'
import { ListMasonryLazyProps } from './ListMasonryLazyProps'

interface StyledItemProps {
  flex: CSSProperties['flex']
}

interface StyledSectionRowBaseProps {
  aspectRatio: number
}

const StyledItem = styled.div<StyledItemProps>`
  ${({ flex }) => ({ flex })}
  display: flex;
  box-sizing: border-box;
  height: 100%;
`

const StyledSectionRowBase = styled.div<StyledSectionRowBaseProps>`
  ${({ aspectRatio }) => ({
    paddingTop: `${(1 / aspectRatio) * 100}%`,
  })}
  position: relative;
  width: 100%;
`

const StyledSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export default function ListMasonryLazy<ItemT, S>({
  data,
  limitPerPage = LIST_MASONRY_LOAD_LIMIT,
  refreshing,
  refreshDefaultEnabled,
  search,
  loadData,
  keyExtractor,
  onRefresh,
  onEndReached,
  onLayout,
  onContentSizeChange,
  stateData,
  minItem = LIST_MASONRY_MIN_ITEM,
  maxItem = LIST_MASONRY_MAX_ITEM,
  maxRatio = LIST_MASONRY_MAX_RATIO,
  minRatioPerItem = LIST_MASONRY_MIN_RATIO_PER_ITEM,
  maxRatioPerItem = LIST_MASONRY_MAX_RATIO_PER_ITEM,
  renderItem,
  loadLayout,
  ...props
}: ListMasonryLazyProps<ItemT, S>) {
  const emptyData = useRef([]).current
  const {
    batch,
    status,
    handleEndReached,
    handleLayout,
  } = useListLazyMasonryAction<ItemT, S>({
    data,
    loadLayout,
    stateData,
    refreshDefaultEnabled,
    refreshing,
    search,
    limitPerPage,
    minItem,
    maxItem,
    maxRatio,
    minRatioPerItem,
    maxRatioPerItem,
    loadData,
    keyExtractor,
    onRefresh,
    onEndReached,
    onLayout,
    onContentSizeChange,
  })

  const handleRenderContentSpace = useCallback(
    (row: ListMasonryBatchData<ItemT>, index: number) =>
      isListMasonryHasEmptySpace({
        batch,
        index,
        maxItem,
        maxRatio,
      }) && <StyledItem flex={maxRatio - row.ratio} />,
    [batch, maxRatio, maxItem],
  )

  const handleRenderContentItems = useCallback(
    (row: ListMasonryBatchData<ItemT>, index: number) =>
      row.items.map(({ item, ratio }) => (
        <StyledItem key={keyExtractor(item)} flex={ratio}>
          {renderItem(item, index)}
        </StyledItem>
      )),
    [keyExtractor, renderItem],
  )

  const handleRenderContent = useMemo(
    () => (
      <>
        {batch.map((row, index) => (
          <StyledSectionRowBase
            key={index.toString()}
            aspectRatio={
              isListMasonryHasEmptySpace({
                batch,
                index,
                maxItem,
                maxRatio,
              })
                ? maxRatio
                : row.ratio
            }
          >
            <StyledSectionRow>
              {handleRenderContentItems(row, index)}
              {handleRenderContentSpace(row, index)}
            </StyledSectionRow>
          </StyledSectionRowBase>
        ))}
      </>
    ),
    [
      batch,
      maxItem,
      maxRatio,
      handleRenderContentItems,
      handleRenderContentSpace,
    ],
  )

  return (
    <ListLazyBase
      {...props}
      data={emptyData}
      status={status}
      extraContent={handleRenderContent}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      onLayout={handleLayout}
      onEndReached={handleEndReached}
    />
  )
}
