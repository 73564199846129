import React from 'react'
import {translate} from 'i18n'
import styled from 'styled-components'
import {GIFT_SHOP_DOWNLOAD_LINK} from 'consts'
import {Button, Modal, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
`
const StyledHeaderParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
  width: 100%;
`

export default function PaymentErrorModal() {
  return (
    <Modal style={{margin: convertUnit(20)}} visible>
      <StyledContainer>
        <StyledHeaderParagraph fontSize="xl" fontWeight="bold">
          {translate('payment:appUpdate')}
        </StyledHeaderParagraph>
        <StyledParagraph fontSize="l" fontWeight="regular">
          {translate('payment:appUpdateDesc')}
        </StyledParagraph>
        <StyledButton onClick={() => window.open(GIFT_SHOP_DOWNLOAD_LINK)}>
          {translate('global:ok')}
        </StyledButton>
      </StyledContainer>
    </Modal>
  )
}
