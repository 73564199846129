import React, {
  PointerEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { IconType } from 'types'
import styled, { CSSProperties } from 'styled-components'
import convertUnit from 'lib/unit'
import { ListItemSelectProps } from './ListItemSelectProps'
import { Paragraph } from '../Paragraph'
import { Icon } from '../Icon'

const StyledContainer = styled.div`
  ${({ theme }) => ({
    ':hover': {
      color: theme.white_1,
      backgroundColor: theme.primary_5,
    },
  })}
  display: flex;
  flex-direction: row;
  padding: ${convertUnit('13px')} ${convertUnit('24px')};
  align-items: center;
  cursor: default;
`

export default function ListItemSelect({
  title,
  leftElement,
  rightElement,
  onPointerEnter,
  onPointerLeave,
  ...props
}: ListItemSelectProps) {
  const [hover, setHover] = useState(false)

  const handlePointerEnter = useCallback<
    PointerEventHandler<HTMLDivElement>
  >(
    (event) => {
      onPointerEnter && onPointerEnter(event)
      setHover(true)
    },
    [onPointerEnter],
  )

  const handlePointerLeave = useCallback<
    PointerEventHandler<HTMLDivElement>
  >(
    (event) => {
      onPointerLeave && onPointerLeave(event)
      setHover(false)
    },
    [onPointerLeave],
  )

  const handleRenderIcon = useCallback(
    (type: IconType, style?: CSSProperties) => (
      <Icon style={style} type={type} size={16} color="inherit" />
    ),
    [],
  )

  const handleRenderLeftElement = useMemo(
    () =>
      typeof leftElement === 'string'
        ? handleRenderIcon(leftElement, {
            marginRight: convertUnit('8px'),
          })
        : leftElement,
    [leftElement, handleRenderIcon],
  )

  const handleRenderRightElement = useMemo(
    () =>
      typeof rightElement === 'string'
        ? handleRenderIcon(rightElement, {
            marginLeft: convertUnit('8px'),
          })
        : rightElement,
    [rightElement, handleRenderIcon],
  )

  return (
    <StyledContainer
      {...props}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      {handleRenderLeftElement}
      <Paragraph
        fontSize="m"
        fontWeight={hover ? 'bold' : 'medium'}
        color={hover ? 'white_1' : undefined}
      >
        {title}
      </Paragraph>
      {handleRenderRightElement}
    </StyledContainer>
  )
}
