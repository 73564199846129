import React, {Fragment, useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {PaymentCheckoutMethodItem, PaymentCheckoutMethodModalProps} from 'pages'
import {PaymentMethodResponse, WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import convertUnit from 'lib/unit'
import {translate} from 'i18n'
import {Button, Paragraph} from 'common/components'

interface StyledModalOverlayProps {
  visible: boolean
}

interface StyledContainerProps extends React.CSSProperties {
  mode: WindowModeType
}

const StyledModalOverlay = styled.div<StyledModalOverlayProps>`
  ${({visible}) => ({visibility: visible ? 'visible' : 'hidden'})};
  position: fixed;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
`

const StyledModal = styled.div<StyledContainerProps>`
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
  background-color: ${({theme}) => theme.white_1};
  transition: all 0.5s ease-in-out;
  z-index: 10;
`

const StyledBackdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.black}B3;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${convertUnit('15px')};
`

const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledSection = styled.div`
  display: inline-block;
  flex: 1;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
  }
`

const StyledSectionParagraph = styled(Paragraph)`
  ${({theme}) => ({color: theme.gray_5})}
`

const StyledButtonContinue = styled(Button)`
  margin-top: ${convertUnit(25)};
`
export default function PaymentCheckoutMethodModal({
  visible,
  totalPrice,
  onClose,
  selected,
  methods: paymentMethods,
  ...props
}: PaymentCheckoutMethodModalProps) {
  const mode = useWindowMode()
  const [isselected, setIsSelected] = selected

  const getSection = useMemo(
    () =>
      paymentMethods.reduce((prev, curr) => {
        const type = curr.payment_type

        if (prev[type]) {
          prev[type].push(curr)
        } else {
          prev[type] = [curr]
        }

        return prev
      }, {} as Record<string, PaymentMethodResponse[]>),
    [paymentMethods],
  )

  const onSelect = useCallback(
    (item: PaymentMethodResponse) => {
      setIsSelected(item)
    },
    [setIsSelected],
  )

  const handleRenderItem = useCallback(
    (item: PaymentMethodResponse) => (
      <PaymentCheckoutMethodItem
        isSelected={item.payment_method_id === isselected?.payment_method_id}
        totalPrice={totalPrice}
        data={item}
        onSelect={() => onSelect(item)}
        key={item.payment_method_id}
      />
    ),
    [isselected?.payment_method_id, onSelect, totalPrice],
  )

  const renderListSection = useMemo(() => {
    const paymentType = Object.entries(getSection)
    return (
      <StyledSection>
        <StyledSectionContainer>
          {getSection &&
            paymentType.map((value) => (
              <Fragment key={value[0]}>
                <StyledSectionParagraph fontSize="m" fontWeight="medium">
                  {value[0]}
                </StyledSectionParagraph>
                {value[1].map(handleRenderItem)}
              </Fragment>
            ))}
        </StyledSectionContainer>
      </StyledSection>
    )
  }, [getSection, handleRenderItem])

  const renderCustomList = useMemo(
    () => (paymentMethods.length > 0 ? renderListSection : <></>),
    [paymentMethods.length, renderListSection],
  )

  return (
    <StyledModalOverlay visible={visible}>
      <StyledBackdrop />
      <StyledModal mode={mode} {...props}>
        <StyledContainer>
          {renderCustomList}
          <StyledButtonContinue
            label={translate('payment:chooseMethod')}
            onClick={onClose}
          />
        </StyledContainer>
      </StyledModal>
    </StyledModalOverlay>
  )
}
