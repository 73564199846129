import convertUnit from 'lib/unit'
import React from 'react'
import styled from 'styled-components'
import { ModalProps } from './ModalProps'

const StyledModalOverlay = styled.div<React.CSSProperties>`
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => `${theme.black}B3`};
  width: 100%;
  height: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
`

const StyledModal = styled.div<React.CSSProperties>`
  background-color: ${({ theme }) => theme.white_1};
  border-radius: ${({ borderRadius = '16px' }) =>
    convertUnit(borderRadius)};
  position: relative;
  transition: all 0.5s ease-in-out;
  z-index: 10;
`

export default function Modal({
  visible,
  children,
  ...props
}: ModalProps) {
  return visible ? (
    <StyledModalOverlay>
      <StyledModal {...props}>{children}</StyledModal>
    </StyledModalOverlay>
  ) : null
}
