import {TranslationKeyPayment} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_PAYMENT_EN: TranslationResource<TranslationKeyPayment> = {
  translation: {
    'payment:appUpdate': 'New Version Available',
    'payment:appUpdateDesc': 'Please update your app to the latest version.',
    'payment:cancelPayment': 'Cancel Payment',
    'payment:cardErrorMessage':
      'An error occured while fetching data. Please try again.',
    'payment:cardErrorMessage_400':
      'Credit card number is invalid. Please try again.',
    'payment:cardErrorMessage_500':
      'Error connecting to our server. Please try again later.',
    'payment:cardErrorMessage_503':
      'There was a problem with the credit card network. Please try again later.',
    'payment:changePaymentMethod': 'Change Payment Method',
    'payment:checkTransaction': 'Check Transaction',
    'payment:checkoutAccountName': 'Account Name',
    'payment:checkoutCardNumber': 'Card Number',
    'payment:checkoutCardNumberPlaceHolder': '16 digit number',
    'payment:checkoutCardCVV': 'CVV',
    'payment:checkoutCardCVVMessage':
      'CVV is a three-digit number, and it usually appears on the back of the card',
    'payment:checkoutCardExpiredDate': 'Expired Date',
    'payment:checkoutCardExpiredDatePlaceHolder': 'MM / YY',
    'payment:checkoutContentDoNotAutopost': 'Please do not repost',
    'payment:checkoutContentExpand': 'Show All',
    'payment:checkoutContentPrice': 'Price',
    'payment:checkoutContentTotalPayment': 'Total Payment',
    'payment:checkoutContentTotalPrice': 'Total Price',
    'payment:checkoutFreeDescription': 'This content is free!',
    'payment:checkoutMethod': 'Choose Payment Method',
    'payment:checkoutPhoneButtonLabel': 'Pay',
    'payment:checkoutPhoneTitle': '{{method}} Payment',
    'payment:checkoutPhoneDescription':
      'Make sure the number you enter is registered with {{method}}.',
    'payment:checkoutPhoneTotalPrice': 'Total Price:',
    'payment:checkoutPromo': 'Use Special Promo',
    'payment:checkoutPromoDescription': '({{method}}) Min. {{payment}}',
    'payment:checkoutPromoEmpty': 'No Vouchers Available',
    'payment:checkoutPromoList': 'Promo List',
    'payment:checkoutPromoMaxDiscount': 'Discount up to {{amount}}',
    'payment:checkoutPromoQtyLeft': '{{amount}} yusers only',
    'payment:checkoutPromoRemoved':
      "Sorry! It looks like the voucher didn't apply.",
    'payment:checkoutPromoRemovedIncorrectCard':
      'For the promo to be valid, make sure that the payment method you use is "{{method}}".',
    'payment:checkoutPromoTimeLeft': '{{time}} hour(s)',
    'payment:checkoutPromoTimeLeft_minutes': '{{time}} minute(s)',
    'payment:checkoutPromoTimeStart': 'Starting in {{time}} hour(s)',
    'payment:checkoutPromoTimeStart_minutes': 'Starting in {{time}} minute(s)',
    'payment:checkoutPromoTitle': 'Discount {{amount}}%',
    'payment:checkoutPromoTitle_flat': 'Discount {{amount}}',
    'payment:checkoutTotalPriceValidation':
      'Minimum payment for selected method is {{min}} and the maximum is {{max}}',
    'payment:checkoutTransactionDate': 'Transaction Date',
    'payment:checkoutTransactionTitle': 'Transaction Detail',
    'payment:checkoutTransactionType': 'Transaction Type',
    'payment:checkoutWaitForHiresInfoDesc1':
      'The Hi-Res will appears in the “Collections” at “FotoYu” tab.',
    'payment:checkoutWaitForHiresInfoDesc2':
      'You can also chat the creator seller by clicking the message icon below each content you purchased.',
    'payment:checkoutWaitForHiresInfoDesc3':
      'Creators need to resend Hi-Res in 48 hours, or you will get a refund.',
    'payment:checkoutWaitForHiresInfoTitle':
      'Please wait for the Hi-Res version.',
    'payment:chooseMethod': 'Choose This Method',
    'payment:eWalletInstruction':
      'If you have {{app}} app on this phone, you can press the button below to continue the payment on {{app}} app.',
    'payment:eWalletInstruction_QRWeb':
      'Please scan the QR code below using the {{method}} scan feature in the {{app}} application.',
    'payment:eWalletInstruction_QRMobile':
      'Otherwise, please scan the QR code below using the {{method}} scan feature in the {{app}} app on your phone.',
    'payment:eWalletInstruction_complete':
      'When the process is complete, the payment will be processed automatically.',
    'payment:methodFee': 'Fee {{Amount}}',
    'payment:maxPayment': '(Max. purchase {{Amount}})',
    'payment:minPayment': '(Min. purchase {{Amount}})',
    'payment:paymentInstructionTimeLimit':
      'Transaction will be canceled automatically after {{time}} minutes',
    'payment:paymentInstruction': 'Payment Instruction',
    'payment:paymentDurationEnds': 'Payment Duration Ends',
    'payment:paymentTimeLeft': 'Time Left:',
    'payment:paymentVoucherError':
      "Sorry! It looks like the voucher didn't apply. Please go back and try again.",
    'payment:payWith': 'Pay With',
    'payment:retailDescription':
      '1.  Tell the Alfamart/Alfamidi cashier to make payments to merchants PT. Super Giga Generasi (SUPER GIGA).',
    'payment:retailDescription_second':
      '2.  Show the payment code to the cashier and make a payment of {{Amount}}.',
    'payment:retailDescription_third':
      '3. After the transaction is successful, you will get proof of payment. Please keep proof of payment as collateral in case further verification is required.',
    'payment:retailDescription_fourth':
      '4. Your order will be verified automatically in the Fotoyu App after successful payment',
    'payment:statusFailedButtonLabel': 'Try Again',
    'payment:statusFailedDescription':
      'There’s a problem when processing your payment.',
    'payment:statusFailedTitle': 'Failed',
    'payment:statusFailedTitleLabel':
      'Payment cannot be processed this time, please try again later',
    'payment:statusFailedTitleButton': 'OK',
    'payment:statusOfflineButtonLabel': 'Try Again',
    'payment:statusOfflineDescription':
      'Please check your connection, and try again.',
    'payment:statusOfflineTitle': 'Internet Offline',
    'payment:statusSuccessButtonLabel': 'Check Transaction',
    'payment:statusSuccessDescription':
      'Your payment has been processed successfully.',
    'payment:statusSuccessFreeDescription':
      'Your payment of {{amount}} processed sucessfully',
    'payment:statusSuccessFreeDescription_1': ' processed sucessfully',
    'payment:statusSuccessTitle': 'Successful!',
    'payment:statusWaitingDescription': 'Please check and complete the payment',
    'payment:statusWaitingDescriptionDana':
      'Please check and complete the DANA payment.',
    'payment:statusWaitingDescriptionCountdown':
      'Please check your OVO application and make your payment within ({{Countdown}}s)',
    'payment:statusWaitingDescriptionDate':
      'Complete your payment before {{Date}}',
    'payment:statusWaitingReminder1':
      'Check the payment status in the transaction history on: "My Account" page -> wallet icon.',
    'payment:statusWaitingReminder2': 'Please wait 2 minutes for processing.',
    'payment:statusWaitingTitle': 'Waiting for Payment',
    'payment:transactionVANumberReminder':
      'This is a one-time use only VA number. Always use the matching bank service for the payment.',
    'payment:virtualiBankingDescription': '',
    'payment:virtualiBankingDescription_BCA':
      '1. Select Fund Transfer > Transfer to BCA Virtual Account. \n2. Check the Virtual Account number and enter {{Account}} and select Continue. \n3. Check the information shown on the screen. Make sure the Merchant is PT. Super Giga Generasi (SUPER GIGA) and the total bill is correct. If correct, select Continue. \n4. Enter your KeyBCA response and click Submit.',
    'payment:virtualiBankingDescription_BNI':
      '1. Login to your i-Banking. Select Transfer, then select Virtual Account Billing. \n2. Enter Virtual Account number {{Account}}, then select Continue. \n3. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If correct, enter your PIN and select OK.',
    'payment:virtualiBankingDescription_BRI':
      '1. Select the Payment menu. \n2. In the left navigation column, in the Payments menu, select BRIVA. \n3. Select the original account, then select Fill in the Pay Code and enter the Virtual Account number {{Account}} then select Send. \n4. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If correct, enter your iBanking and mToken passwords, then click Submit.',
    'payment:virtualiBankingDescription_PERMATA':
      '1. Select Pay Bill Payment. \n2. Select Virtual Accounts. \n3. Enter the pay code {{Account}}, then select OK. \n4. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If so, select Next. \n5. Enter your Response Code, then select Continue.',
    'payment:virtualiBankingDescription_MANDIRI':
      '1. Select Pay > Multi Payment \n2. Select From Account: Your Account and Service Provider: XENDIT, then click Continue. \n3. Enter Virtual Account number {{Account}} and select Continue. \n4. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If correct, tick the bill and click Continue. \n5. Enter your token PIN and click Submit.',
    'payment:virtualmBankingDescription': '',
    'payment:virtualmBankingDescription_BCA':
      '1. Select m-Transfer > BCA Virtual Account. \n2. Enter Virtual Account number {{Account}} and select Send. \n3. Check the information shown on the screen. Make sure the Merchant is PT. Super Giga Generasi (SUPER GIGA) and the total bill is correct. If it’s already correct, select yes. \n4. Enter your m-BCA PIN and select OK.',
    'payment:virtualmBankingDescription_BNI':
      '1. Login to your m-Banking. Select Transfer, then select Virtual Account Billing. \n2. Enter Virtual Account number {{Account}}, then select Continue. \n3. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If correct, enter your PIN and select OK.',
    'payment:virtualmBankingDescription_BRI':
      '1. Enter the BRI Mobile Banking menu. Then, select Payments > BRIVA. \n2. Enter BRIVA Number {{Account}} \n3. Enter your PIN then select Send. When a confirmation message for transactions using SMS appears, select OK. Transaction status will be sent via SMS and can be used as proof of payment.',
    'payment:virtualmBankingDescription_PERMATA':
      '1. Select Payment Pay Bill \n2. Select Virtual Accounts. \n3. Enter the pay code {{Account}}, then select OK. \n4. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If so, select Next. \n5. Select the account number you will use. \n6. Enter your PIN, then select Next.',
    'payment:virtualmBankingDescription_MANDIRI': `1. Login to your m-Banking. Select IDR Transfer, then select Transfer to new recipient.
      \n2. Enter your Virtual Account number {{Account}}, then select Continue.
      \n3.  Check the information shown on the screen. Make sure the total bill is correct. If correct, enter your PIN and select OK.`,
    'payment:virtualAtmDescription': '',
    'payment:virtualAtmDescription_BCA': `1. Select Other Transactions > Transfer > To BCA Virtual Account. \n2. Enter company code 7007015 / 7007115 / 7007215 and select Correct. \n3. Enter Virtual Account number {{Account}} and select True. \n4. Check the information shown on the screen. Make sure the Merchant is PT. Super Giga Generasi (SUPER GIGA), the total bill is correct. If correct, select Yes.`,
    'payment:virtualAtmDescription_BNI':
      '1. Select Transfer. \n2. Select Current/Savings. \n3. Select Virtual Account Billing. \n4. Enter Virtual Account number {{Account}} and select True. \n5. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If so, press the number 1 and select Yes. \n6. Check the confirmation screen and select Yes.',
    'payment:virtualAtmDescription_BRI':
      '1. Select Other Transactions > Payments > Others > BRIVA. \n2. Enter BRIVA Number {{Account}} then select True. \n3. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If so, select Yes.',
    'payment:virtualAtmDescription_PERMATA':
      '1. Select Other Transactions. \n2. Select Payments > Other Payments. \n3. Select Virtual Accounts. \n4. Enter the pay code {{Account}}, then select Correct. \n5. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If true, select True.',
    'payment:virtualAtmDescription_MANDIRI':
      '1. Select Pay/Buy. \n2. Select More > More > Multi Payment. \n3. Enter company code 88608 / 88908 and select Correct. \n4. Enter Virtual Account number {{Account}} and select True. \n5. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct. If so, press the number 1 and select Yes. \n6. Check the confirmation screen and select Yes.',
    'payment:virtualMiniAtmDescription_BRI':
      '1. Select Mini ATM > Payments > BRIVA. \n2. Swipe your BRI debit card. \n3. Enter BRIVA Number {{Account}} then press OK. \n4. Enter your PIN then press OK. \n5. Check the information shown on the screen. Make sure the Merchant is XENDIT, the total bill is correct, then select Continue. If the transaction is successful, a proof of transfer will be printed.',
  },
}
