export function getCount(num: number) {
  const PREFIX_SYMBOL = ['K', 'M', 'B']
  const exponent =
    num >= 1000
      ? Math.min(
          PREFIX_SYMBOL.length,
          Math.floor(Math.log(num) / Math.log(1000)),
        )
      : 0
  const multiplier = 1000 ** exponent
  const relativeValue = num / multiplier

  const count = parseFloat(
    relativeValue % 1 === 0
      ? relativeValue.toFixed(0)
      : relativeValue.toFixed(1),
  )
  const symbol = exponent > 0 ? PREFIX_SYMBOL[exponent - 1] : ''

  return `${count}${symbol}`
}
