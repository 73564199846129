export function debounce(func: Function, wait: number) {
  let timeout: any

  return () => {
    const debounced = () => {
      clearTimeout(timeout)
      func()
    }

    clearTimeout(timeout)
    timeout = setTimeout(debounced, wait)
  }
}
