import {combineReducers, Action, Reducer} from 'redux'
import {PersistConfig} from 'redux-persist'
import {
  LastPaymentMethodState,
  PaymentErrorState,
  UserData,
  VersionState,
} from 'types'

export interface ReduxReducerState {
  userState: UserData | null
  lastPaymentMethodState: LastPaymentMethodState | null
  versionState: VersionState
  paymentErrorState: PaymentErrorState
}

export type ReduxStateKey = keyof ReduxReducerState

export interface ReduxReducerPersistConfig
  extends PersistConfig<ReduxReducerState> {
  whitelist?: ReduxStateKey[]
}

export type ReduxDispatchType = 'update' | 'reset'

export interface ReduxActionBase<K extends ReduxStateKey>
  extends Action<ReduxDispatchType> {
  key?: K
  payload?: Partial<ReduxReducerState[K]>
}

export type ReduxReducerMap = {
  [K in ReduxStateKey]: Reducer<ReduxReducerState[K], ReduxActionBase<K>>
}

export function generateReducer<K extends ReduxStateKey>(
  key: K,
  initialState: ReduxReducerState[K],
): Reducer<ReduxReducerState[K], ReduxActionBase<K>> {
  return (state = initialState, action) => {
    if (action.key !== key) {
      return state
    }

    switch (action.type) {
      case 'update':
        return {...state, ...action.payload}
      case 'reset':
        return initialState
      default:
        return state
    }
  }
}

export const mapReducer: ReduxReducerMap = {
  userState: generateReducer('userState', null),
  lastPaymentMethodState: generateReducer('lastPaymentMethodState', null),
  versionState: generateReducer('versionState', {version: undefined}),
  paymentErrorState: generateReducer('paymentErrorState', {
    voucherError: false,
  }),
}

export const REDUX_REDUCER = combineReducers(mapReducer)
