import convertUnit from 'lib/unit'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Icon } from '../Icon'
import { ActivityIndicatorProps } from './ActivityIndicatorProps'

const keyFrameSpin = keyframes`
  0% { -webkit-transform: rotate(0deg)}
  100% { -webkit-transform: rotate(360deg); }
`

const StyledContainer = styled.div`
  animation-name: ${keyFrameSpin};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`

export default function ActivityIndicator({
  iconType = 'load',
  iconColor,
  iconSize = convertUnit('16px'),
  iconPrefix,
  ...props
}: ActivityIndicatorProps) {
  return (
    <StyledContainer {...props}>
      <Icon
        prefix={iconPrefix}
        type={iconType}
        color={iconColor}
        size={iconSize}
      />
    </StyledContainer>
  )
}
