import React from 'react'
import styled from 'styled-components'
import { AVATAR_SIZE } from 'consts'
import { CSSHtmlProperties } from 'types'
import convertUnit from 'lib/unit'
import { AvatarProps } from './AvatarProps'

interface StyledContainerProps
  extends CSSHtmlProperties<HTMLDivElement> {
  size?: string | number
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({ size = convertUnit(AVATAR_SIZE) }) => ({
    width: size,
    height: size,
  })};
  box-sizing: border-box;
  border-radius: 50%;
  overflow: hidden;
`

const StyledAvatar = styled.img<AvatarProps>`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`

export default function Avatar({
  containerStyle,
  size,
  ...props
}: AvatarProps) {
  return (
    <StyledContainer size={size} style={containerStyle}>
      <StyledAvatar data-testid="avatar" {...props} />
    </StyledContainer>
  )
}
