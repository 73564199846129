import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import { Icon } from '../Icon'
import { Paragraph } from '../Paragraph'
import { PriceTagProps } from './PriceTagProps'

const StyledContainer = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
`

export default function PriceTag({
  price,
  iconSize = convertUnit('21px'),
  fontSize = 'xl',
  fontWeight = 'bold',
  iconMarginTop,
  iconMarginRight,
  iconMarginBottom,
  iconMarginLeft,
  ...props
}: PriceTagProps) {
  return (
    <StyledContainer {...props}>
      <Icon
        type="price-tags"
        size={iconSize}
        marginTop={iconMarginTop}
        marginRight={iconMarginRight}
        marginBottom={iconMarginBottom}
        marginLeft={iconMarginLeft}
      />
      <Paragraph fontSize={fontSize} fontWeight={fontWeight}>
        {price}
      </Paragraph>
    </StyledContainer>
  )
}
