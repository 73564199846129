import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import { ActivityIndicator } from '../ActivityIndicator'
import { ListIndicatorProps } from './ListIndicatorProps'

const StyledLoadingContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${convertUnit('10px')} 0;
  align-items: center;
  justify-content: center;
`

export default function ListIndicator({
  status,
  listInitializeElement,
  listEmptyElement,
  listLoadingElement,
  listLoadingElementStyle,
  listEndPageElement,
}: ListIndicatorProps) {
  if (status === 'initialize' && listInitializeElement) {
    return listInitializeElement
  }
  if (status === 'empty' && listEmptyElement) {
    return listEmptyElement
  }
  if (status === 'end-page' && listEndPageElement) {
    return listEndPageElement
  }
  if (status === 'loading') {
    return (
      <StyledLoadingContainer style={listLoadingElementStyle}>
        {listLoadingElement || <ActivityIndicator />}
      </StyledLoadingContainer>
    )
  }

  return null
}
