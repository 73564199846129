import {PaymentContentData} from 'types'

export const MOCK_PAYMENT_CONTENT_DATA: PaymentContentData[] = [
  {
    code: '1',
    content_id: '1',
    creator_name: 'Jane Doe',
    title: 'God’s Gaze',
    price: 50000,
    is_free_purchase: true,
    url: 'https://cdn.forky.id/mocks/images/image-1.jpeg',
  },
  {
    code: '2',
    content_id: '2',
    creator_name: 'John Doe',
    title: 'Minimalist',
    price: 20000,
    is_free_purchase: true,
    url: 'https://cdn.forky.id/mocks/images/image-2.jpeg',
  },
  {
    code: '3',
    content_id: '3',
    creator_name: 'Diamante',
    title: 'Holy Lake',
    price: 15250,
    is_free_purchase: true,
    url: 'https://cdn.forky.id/mocks/images/image-3.jpeg',
  },
]
