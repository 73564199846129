import {TranslationKeyGlobal} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_GLOBAL_EN: TranslationResource<TranslationKeyGlobal> = {
  translation: {
    'global:back': 'Back',
    'global:backToFotoyu': 'Back to FotoYu',
    'global:cancel': 'Cancel',
    'global:cardPayment': 'Cards Payment',
    'global:checkoutPayment': 'Payment',
    'global:close': 'Close',
    'global:continue': 'Continue',
    'global:copy': 'Copy',
    'global:copied': 'Number successfully copied',
    'global:creditCard': 'Credit Card',
    'global:day': 'Day',
    'global:day_0': 'Sunday',
    'global:day_1': 'Monday',
    'global:day_2': 'Tuesday',
    'global:day_3': 'Wednesday',
    'global:day_4': 'Thursday',
    'global:day_5': 'Friday',
    'global:day_6': 'Saturday',
    'global:discount': 'Discount',
    'global:eBanking': 'e-Banking',
    'global:eWallet': 'eWallets',
    'global:everyday': 'Everyday',
    'global:formatPhone': '+{{phone_code}}{{phone_number}}',
    'global:fotoyuSupportTeam': 'FotoYu support team:',
    'global:mBanking': 'm-Banking',
    'global:mBanking_MANDIRI': 'm-Banking (Livin by Mandiri)',
    'global:mBanking_BNI': 'm-Banking (BNI Mobile)',
    'global:messageError': 'Oops... something unexpected happened',
    'global:messageError-execute':
      'Error occured while executing action. Please try again.',
    'global:messageError-fetch':
      'Error occured while fetching data. Please try again.',
    'global:messageError_code-400': 'Format data is not valid',
    'global:messageError_code-401':
      'The access token that is currently used is no longer valid',
    'global:messageError_code-403':
      'You are prohibited from accessing this feature',
    'global:messageError_code-404': 'Page not found',
    'global:messageError_code-408': '$t(global:messageError_timeout)',
    'global:messageError_code-409': 'Resource Conflict: ID has been used',
    'global:messageError_no-connection':
      'Turn on the internet connection to continue',
    'global:messageError_timeout': 'Connection Timeout',
    'global:no': 'No',
    'global:ok': 'OK',
    'global:paymentType': 'Payment Type',
    'global:retail': 'Retail',
    'global:seeAll': 'See All',
    'global:serverUnderMaintenance': 'Server Under Maintenance',
    'global:serverUnderMaintenanceDescFirst':
      'Service will be restored in a short time.',
    'global:serverUnderMaintenanceDescSecond': 'Sorry for the inconvenience.',
    'global:transactionHistory': 'Transaction History',
    'global:transferATM': 'Transfer ATM',
    'global:transferMiniATM': 'Transfer Mini ATM',
    'global:uploadAnImage': 'Upload an image',
    'global:uploadCriteria': 'JPG, JPEG, PNG Image and no larger than 30MB',
    'global:use': 'Use',
    'global:validationExampleEmail': 'Example validation message for email',
    'global:validationExampleMaxLength':
      'Example validation max length message',
    'global:validationExampleMinLength':
      'Example validation min length message',
    'global:validationExamplePhone':
      'Example validation message for phone number',
    'global:validationExampleRequired': 'Example validation required message',
    'global:validationInputRequired': 'Required input',
    'global:validationInputExpiredNotValid': 'Input not Valid',
    'global:validationCVVMinLength': 'Invalid cvv number',
    'global:virtualAccount': 'Virtual Account',
    'global:virtualAccountMethod': '{{method}} Virtual Account',
    'global:yes': 'Yes',
  },
}
