import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import { Paragraph } from '../Paragraph'
import { LabelProps } from './LabelProps'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledDanger = styled.small`
  color: ${({ theme }) => theme.danger_5};
`

const StyledParagraph = styled(Paragraph)`
  ${({ children }) =>
    children ? { marginBottom: convertUnit('5px') } : undefined}
`

export default function Label({
  label,
  required,
  containerStyle,
  children,
}: LabelProps) {
  return (
    <StyledContainer style={containerStyle}>
      <StyledParagraph fontSize="m" fontWeight="medium">
        {label}
        {required && <StyledDanger>*</StyledDanger>}
      </StyledParagraph>
      {children}
    </StyledContainer>
  )
}
