import {PaymentData, UserData} from 'types'

export const GIFT_SHOP_HEADER_HEIGHT = '64px'
export const GIFT_SHOP_HEADER_TITLE_WIDTH = '250px'
export const GIFT_SHOP_HEADER_PROFILE_ICON = '32px'
export const GIFT_SHOP_NAVBAR_ICON_SIZE = 32
export const GIFT_SHOP_NAVBAR_ITEM_PADDING_HORIZONTAL = 12
export const GIFT_SHOP_NAVBAR_ITEM_LABEL_WIDTH = 150

export const GIFT_SHOP_ROOT_MENU_WIDTH = 80
export const GIFT_SHOP_ROOT_HEADER_PADDING = 16
export const GIFT_SHOP_ROOT_CONTENT_EMPTY_PADDING = '25px'
export const GIFT_SHOP_ROOT_CONTENT_EMPTY_SPACE = '25px'
export const GIFT_SHOP_ROOT_CONTENT_EMPTY_IMAGE_HEIGHT = 280
export const GIFT_SHOP_ROOT_CONTENT_PADDING = '1px'
export const GIFT_SHOP_ROOT_CONTENT_ITEM_PADDING = '1px'
export const GIFT_SHOP_DOWNLOAD_LINK = 'http://onelink.to/4g3zhc'

export const GIFT_SHOP_EXPLORE_EMPTY_MAX_WIDTH = '485px'
export const GIFT_SHOP_EXPLORE_EMPTY_CONTAINER_PADDING = '20px'

export const GIFT_SHOP_COLLECTION_DOWNLOAD_BUNDLE_NAME = 'collection'

export const GIFT_SHOP_MAX_UPLOAD_CONTENT = 10

export const GIFT_SHOP_UPLOAD_ITEM_IMAGE_SIZE = 260

export const GIFT_SHOP_ROBOPET_SETTINGS_OPTIONS = [1, 3, 6]

export const PAYMENT_CONTENT_DATA_INTITIAL: PaymentData = {
  data: [
    {
      code: '',
      content_id: '',
      creator_name: '',
      price: 0,
      title: '',
      url: '',
      is_free_purchase: false,
    },
  ],
  autopostDisabled: false,
  userInfo: {
    access_token: '',
    biodata: '',
    country: '',
    created_at: '',
    date_of_birth: '',
    email: '',
    follower_count: 0,
    following_count: 0,
    full_name: '',
    gender: '',
    has_face_verification: false,
    has_pin: false,
    id: '',
    is_followed: false,
    is_verified: false,
    lang: '',
    phone_code: '',
    phone_number: '',
    photo: '',
    refresh_token: '',
    signin_method: '',
    signup_method: '',
    status_banned: '',
    username: '',
    website: '',
  },
  paymentMethodId: 'MR9Xdjv70WpeODE8DEPLAyb8xVBQzq31',
  platform: 'web',
}

export const USER_DATA_INITIAL: UserData = {
  access_token: '',
  biodata: '',
  country: '',
  created_at: '',
  date_of_birth: '',
  email: '',
  follower_count: 0,
  following_count: 0,
  full_name: '',
  gender: '',
  has_face_verification: false,
  has_pin: false,
  id: '',
  is_followed: false,
  is_verified: false,
  lang: '',
  phone_code: '',
  phone_number: '',
  photo: '',
  refresh_token: '',
  signin_method: '',
  signup_method: '',
  status_banned: '',
  username: '',
  website: '',
}

export const GIFTSHOP_MIN_VERSION = '1.1'
