import {
  API_GET_SELF,
  API_GET_TOKEN,
  API_GET_VOUCHERS,
  API_GOPAY_CANCEL,
  API_PAYMENT_CHECKOUT_UPDATE,
  API_PAYMENT_METHODS,
} from 'consts'
import {ServiceMap} from 'types'
import {getAPIBaseUrl} from 'utils'
import {ServiceParamPayment} from './ServiceParamPayment'

export const SERVICE_MAP_PAYMENT: ServiceMap<ServiceParamPayment> = {
  payment_checkout_update: {
    url: getAPIBaseUrl() + API_PAYMENT_CHECKOUT_UPDATE,
    method: 'POST',
  },
  payment_methods: {
    url: getAPIBaseUrl() + API_PAYMENT_METHODS,
    method: 'GET',
  },
  payment_get_token: {
    url: getAPIBaseUrl() + API_GET_TOKEN,
    method: 'GET',
  },
  payment_get_self_info: {
    url: getAPIBaseUrl() + API_GET_SELF,
    method: 'GET',
  },
  payment_gopay_cancel: {
    url: getAPIBaseUrl() + API_GOPAY_CANCEL,
    method: 'POST',
  },
  payment_get_vouchers: {
    url: getAPIBaseUrl() + API_GET_VOUCHERS,
    method: 'GET',
  },
}
