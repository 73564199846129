import { useCallback, useEffect, useMemo, useState } from 'react'
import { Timer, TimerParams } from 'types'

export function useTimer({
  duration = 30,
  period = 250,
  countOnStart = false,
}: TimerParams = {}): Timer {
  const [start, setStart] = useState(new Date().getTime())
  const [expire, setExpire] = useState(
    countOnStart ? start + duration * 1000 : start,
  )

  const countdown = useMemo(
    () => Math.floor(Math.max(0, (expire - start) / 1000)),
    [expire, start],
  )

  const reset = useCallback(
    (value = duration) => {
      const now = new Date().getTime()
      setStart(now)
      setExpire(now + value * 1000)
    },
    [duration],
  )

  useEffect(() => {
    if (countdown > 0) {
      const timeout = setTimeout(() => {
        setStart(new Date().getTime())
      }, period)

      return () => clearTimeout(timeout)
    }

    return undefined
  }, [countdown, period, start])

  return useMemo<Timer>(() => ({ countdown, reset }), [
    countdown,
    reset,
  ])
}
