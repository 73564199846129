import {useCallback, useMemo} from 'react'
import {
  useHistory as useHistoryOrigin,
  useLocation as useLocationOrigin,
} from 'react-router'
import {Routes} from 'types'
import {RouteParam} from '../../routes/RouteParam'
import {getRoutePath} from './RouteHelper'

export function useHistory<K extends Routes>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _route?: K,
) {
  const history = useHistoryOrigin<RouteParam[K]>()

  const handlePush = useCallback(
    <P extends Routes>(path: P, state: RouteParam[P]) =>
      // @ts-ignore
      history.push(getRoutePath(path), state),
    [history],
  )

  const handleReplace = useCallback(
    <P extends Routes>(path: P, state: RouteParam[P]) =>
      // @ts-ignore
      history.replace(getRoutePath(path), state),
    [history],
  )

  return useMemo(
    () => ({...history, push: handlePush, replace: handleReplace}),
    [history, handlePush, handleReplace],
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useLocation<K extends Routes>(_route: K) {
  return useLocationOrigin<RouteParam[K]>()
}

export function useQueryParam() {
  const {search} = useLocationOrigin()

  return useMemo(() => new URLSearchParams(search), [search])
}

export function useQueryParamValue<T extends string>(keys: T[]) {
  const query = useQueryParam()

  return useMemo(() => {
    const result: {[K in T]?: string} = {}

    for (const key of keys) {
      const value = query.get(key)

      result[key] = value !== null ? value : undefined
    }

    return result
  }, [keys, query])
}
