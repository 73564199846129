import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styled from 'styled-components'
import { ImageProps } from './ImageProps'

const StyledLazyLoad = styled(LazyLoadImage)`
  overflow: hidden;
  font-size: 0;
  object-fit: cover;
`

const StyledImg = styled.img`
  overflow: hidden;
  font-size: 0;
  object-fit: cover;
`

export default function Image({
  useLazyLoad = false,
  placeholder,
  ...props
}: ImageProps) {
  return useLazyLoad ? (
    <StyledLazyLoad {...props} placeholderSrc={placeholder} />
  ) : (
    <StyledImg {...props} placeholder={placeholder} />
  )
}
