import React from 'react'
import styled from 'styled-components'
import convertUnit from 'lib/unit'
import { InformationModalProps } from './InformationModalProps'
import { Icon } from '../Icon'
import { Modal } from '../Modal'
import { Paragraph } from '../Paragraph'

const StyledClosedButton = styled.a`
  cursor: pointer;
  position: absolute;
  top: auto;
  bottom: auto;
  right: 0;
  transition: all 200ms;
  font-size: ${convertUnit('30px')};
  font-weight: bold;
`

const StyledModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${convertUnit('40px')} ${convertUnit('52px')} ${convertUnit('25px')};
`

const StyledModalBody = styled.div`
  padding: 0 ${convertUnit('52px')} ${convertUnit('52px')};
`

export default function InformationModal({
  title,
  visible,
  toggleModal,
  children,
}: InformationModalProps) {
  return (
    <Modal visible={visible}>
      <StyledModalHeader>
        <StyledClosedButton onClick={toggleModal}>
          <Icon type="close" />
        </StyledClosedButton>
        <Paragraph fontSize="xl" fontWeight="medium">
          {title}
        </Paragraph>
      </StyledModalHeader>
      <StyledModalBody>{children}</StyledModalBody>
    </Modal>
  )
}
