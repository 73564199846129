import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {calcTimeDiff, getCount, getCurrencyValue, parseDate} from 'utils'
import {Icon, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {PaymentCheckoutPromoModalItemProps} from './PaymentCheckoutPromoModalItemProps'

interface StyledDisabled {
  disabled: boolean
}

const StyledContainer = styled.div<StyledDisabled>`
  ${({theme, disabled}) => ({
    backgroundColor: disabled ? theme.white_1 : theme.white_3,
  })}
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: ${convertUnit(20)};
  border: ${convertUnit(1)} solid ${({theme}) => theme.gray_1};
  border-radius: ${convertUnit(8)};
  align-items: center;
  cursor: pointer;
  margin-bottom: ${convertUnit(15)};
  justify-content: space-between;
  user-select: none;
`

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${convertUnit(6)};
`
const StyledHeaderContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${convertUnit(4)};
`
const StyledCheckContainer = styled.div`
  display: flex;
  height: ${convertUnit(20)};
  width: ${convertUnit(20)};
`

const StyledMinMaxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default function PaymentCheckoutPromoItem({
  data: {
    active_days,
    discount,
    discount_type,
    end_at,
    max_daily_usage,
    method_variant_name,
    start_at,
    min_transaction_amount,
    payment_method_name,
    max_discount_amount,
    max_daily_usage_per_user,
    max_usage,
    max_usage_per_user,
    usage_count,
    daily_usage_count,
    usage_per_user_count,
    daily_usage_per_user_count,
  },
  isSelected,
  onSelect,
  totalPrice,
  paymentMethod,
}: PaymentCheckoutPromoModalItemProps) {
  const {translate} = useTranslation()

  const startingIn = useMemo(
    () => calcTimeDiff(new Date(), parseDate(start_at, 'YYYY-MM-DD HH:mm:ss')),
    [start_at],
  )
  const startingInLessThanOneDay = useMemo(
    () => startingIn.hours >= 0 && startingIn.hours < 24,
    [startingIn.hours],
  )
  const endingIn = useMemo(
    () => calcTimeDiff(new Date(), parseDate(end_at, 'YYYY-MM-DD HH:mm:ss')),
    [end_at],
  )
  const endingInLessThanOneDay = useMemo(
    () => endingIn.hours >= 0 && endingIn.hours < 24,
    [endingIn.hours],
  )
  const validDay = useMemo(
    () => active_days.map((x) => x === new Date().getDay()).includes(true),
    [active_days],
  )
  const isMaxUsageLimit = useMemo(() => max_usage - usage_count === 0, [
    max_usage,
    usage_count,
  ])
  const isMaxUsagePerUserLimit = useMemo(
    () => max_usage_per_user - usage_per_user_count === 0,
    [max_usage_per_user, usage_per_user_count],
  )
  const isMaxDailyUsageLimit = useMemo(
    () => max_daily_usage - daily_usage_count === 0,
    [daily_usage_count, max_daily_usage],
  )
  const isMaxDailyUsagePerUserLimit = useMemo(
    () => max_daily_usage_per_user - daily_usage_per_user_count === 0,
    [daily_usage_per_user_count, max_daily_usage_per_user],
  )
  const disabled = useMemo(
    () =>
      totalPrice < min_transaction_amount ||
      !validDay ||
      calcTimeDiff(new Date(), parseDate(start_at, 'YYYY-MM-DD HH:mm:ss'))
        .hours >= 0 ||
      calcTimeDiff(new Date(), parseDate(end_at, 'YYYY-MM-DD HH:mm:ss')).hours <
        0 ||
      isMaxUsageLimit ||
      isMaxUsagePerUserLimit ||
      isMaxDailyUsageLimit ||
      isMaxDailyUsagePerUserLimit ||
      (paymentMethod
        ? paymentMethod.payment_method.toLowerCase() !==
          payment_method_name.toLowerCase()
        : false),
    [
      end_at,
      isMaxDailyUsageLimit,
      isMaxDailyUsagePerUserLimit,
      isMaxUsageLimit,
      isMaxUsagePerUserLimit,
      min_transaction_amount,
      paymentMethod,
      payment_method_name,
      start_at,
      totalPrice,
      validDay,
    ],
  )

  const handleRenderCheck = useMemo(
    () =>
      isSelected && (
        <StyledCheckContainer>
          <Icon type="check" color="primary_5" />
        </StyledCheckContainer>
      ),
    [isSelected],
  )

  const activeDays = useMemo(
    () =>
      active_days
        .map((x) => translate('global:day', {context: x.toString()}))
        .join(', '),
    [active_days, translate],
  )

  return (
    <StyledContainer
      disabled={disabled}
      onClick={!disabled ? onSelect : undefined}>
      <StyledInfoContainer>
        <StyledHeaderContainer>
          <StyledHeaderContentContainer>
            <Icon type="clock" color="success_5" size={12} />
            <Paragraph color="success_5" fontWeight="bold">
              {(endingInLessThanOneDay &&
                translate('payment:checkoutPromoTimeLeft', {
                  context: endingIn.hours ? 'hours' : 'minutes',
                  time: endingIn.hours || endingIn.minutes || 1,
                })) ||
                (startingInLessThanOneDay &&
                  translate('payment:checkoutPromoTimeStart', {
                    context: startingIn.hours ? 'hours' : 'minutes',
                    time: startingIn.hours || startingIn.minutes || 1,
                  })) ||
                (active_days.length === 7
                  ? translate('global:everyday')
                  : activeDays)}
            </Paragraph>
          </StyledHeaderContentContainer>
          <Paragraph color="gray_3" fontWeight="bold">
            •
          </Paragraph>
          <StyledHeaderContentContainer>
            <Icon type="account" size={12} color="gray_5" />
            <Paragraph color="gray_5" fontWeight="bold">
              {translate('payment:checkoutPromoQtyLeft', {
                amount: getCount(
                  isMaxUsagePerUserLimit
                    ? 0
                    : isMaxDailyUsageLimit
                    ? 0
                    : max_daily_usage - daily_usage_count,
                ),
              })}
            </Paragraph>
          </StyledHeaderContentContainer>
        </StyledHeaderContainer>
        <StyledMinMaxContainer>
          <Paragraph
            fontSize="m"
            fontWeight="bold"
            color={disabled ? 'gray_3' : 'black'}>
            {translate('payment:checkoutPromoTitle', {
              context: discount_type,
              amount:
                discount_type === 'flat'
                  ? getCurrencyValue(discount)
                  : discount,
            })}
          </Paragraph>
          <Paragraph fontWeight="medium" color="gray_5">
            {translate('payment:checkoutPromoDescription', {
              method: method_variant_name || payment_method_name,
              payment: getCurrencyValue(min_transaction_amount),
            })}
          </Paragraph>
          {discount_type === 'rate' && max_discount_amount && (
            <Paragraph fontWeight="medium" color="gray_5">
              {translate('payment:checkoutPromoMaxDiscount', {
                amount: getCurrencyValue(max_discount_amount),
              })}
            </Paragraph>
          )}
        </StyledMinMaxContainer>
      </StyledInfoContainer>
      {handleRenderCheck}
    </StyledContainer>
  )
}
