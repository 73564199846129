import {
  API_SERVICE_EXAMPLE,
} from 'consts'
import { ServiceMap } from 'types'
import { getAPIBaseUrl } from 'utils'
import { ServiceParamExample } from './ServiceParamExample'

export const SERVICE_MAP_EXAMPLE: ServiceMap<ServiceParamExample> = {
  example_get: {
    url: getAPIBaseUrl() + API_SERVICE_EXAMPLE,
    method: 'GET',
  },
  example_patch: {
    url: getAPIBaseUrl() + API_SERVICE_EXAMPLE,
    method: 'POST',
  },
  example_post: {
    url: getAPIBaseUrl() + API_SERVICE_EXAMPLE,
    method: 'POST',
  },
}
